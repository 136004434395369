import { LockClosedIcon } from '@heroicons/react/20/solid'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from "../components/Footer";


export default function Register({ user, setUser }) {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [buttonStatus, setButtonStatus] = useState(true);
  const navigate = useNavigate();

  const invited = new URLSearchParams(window.location.search).get('invite') || null;


  const onRegister = async (e) => {
    setButtonStatus(false);
    e.preventDefault();
    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match");
      setButtonStatus(true);
      return;
    }
    if (password.length < 8) {
      setErrorMessage("Password must be at least 8 characters");
      setButtonStatus(true);
      return;
    }
    if(!name || !email || !password || !confirmPassword) {
      setErrorMessage("Please fill out all fields");
      setButtonStatus(true);
      return;
    }
    if (name.length < 3) {
      setErrorMessage("Name must be at least 3 characters");
      setButtonStatus(true);
      return;
    }
    if (email.length < 3) {
      setErrorMessage("Email must be at least 3 characters");
      setButtonStatus(true);
      return;
    }
    if(!email.includes('@') || !email.includes('.')) {
      setErrorMessage("Please enter a valid email");
      setButtonStatus(true);
      return;
    }
    if (password.length > 30) {
      setErrorMessage("Password must be less than 30 characters");
      setButtonStatus(true);
      return;
    }
    if (password.includes('\'')) {
      setErrorMessage("Password cannot contain apostrophes");
      setButtonStatus(true);
      return;
    }
    if (password.includes('\"')) {
      setErrorMessage("Password cannot contain quotation marks");
      setButtonStatus(true);
      return;
    }
    if (password.includes('\\')) {
      setErrorMessage("Password cannot contain backslashes");
      setButtonStatus(true);
      return;
    }
    if (password.includes('`')) {
      setErrorMessage("Password cannot contain backticks");
      setButtonStatus(true);
      return;
    }
    
    try {
      const response = await fetch("https://frozen-springs-61240.herokuapp.com/register", {
        method: "POST",
        mode: 'cors',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ 
          name,
          email,
          password,
          invited,
         }),
      });
      if (response.ok){
        const data = await response.json();
        setErrorMessage('');
        setUser(data);
        navigate('/app');
      } else {
        if (response.status === 409) {
          setErrorMessage("User already exists, please login");
        } else {
          setErrorMessage("An error has occurred, please report this in our chatbox")
        }
      }
    } catch(error) {
      console.log(error);
      setErrorMessage(error);
    }
    finally {
      setButtonStatus(true);
    }
  }


  return (
    <>
      <div className="pt-4 min-h-screen bg-[#101010]">
        <main className="mt-20">
          <div className="w-screen">
          <div className="grid justify-items-center">
            <div className="w-96 bg-white/5 border border-white/10 rounded-lg shadow p-8">
                <form className="space-y-6" action="#" onSubmit={onRegister} method="POST">
                    <h5 className="text-xl font-medium text-gray-900 dark:text-white">Register for an account</h5>
                    <div>
                        <label for="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your Name</label>
                        <input onChange={(e) => {setName(e.target.value)}} type="name" name="name" id="name" placeholder="Your Name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-white/5 dark:border-white/10 dark:placeholder-gray-400 dark:text-white" required/>
                    </div>
                    <div>
                        <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
                        <input onChange={(e) => {setEmail(e.target.value);}} type="email" name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-white/5 dark:border-white/10 dark:placeholder-gray-400 dark:text-white" placeholder="name@company.com" required/>
                    </div>
                    <div>
                        <label for="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your password</label>
                        <input onChange={(e) => {setPassword(e.target.value);}} type="password" name="password" id="password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-white/5 dark:border-white/10 dark:placeholder-gray-400 dark:text-white" required/>
                    </div>
                    <div>
                        <label for="confirmPassword" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Confirm your password</label>
                        <input onChange={(e) => {setConfirmPassword(e.target.value);}} type="password" name="confirmPassword" id="confirmPassword" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-white/5 dark:border-white/10 dark:placeholder-gray-400 dark:text-white" required/>
                    </div>
                    
                    <button type="submit" disabled={!buttonStatus} className="w-full text-white bg-indigo-700 hover:bg-indigo-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Register</button>
                    {errorMessage ? <div className='text-red-500 mt-3'>{errorMessage}</div> : <div></div>}
                    <div className="text-sm font-medium text-gray-300">
                        Already have an account? <a href="signin" className="text-indigo-700 hover:underline">Sign in</a>
                    </div>
                </form>
            </div>
          </div>    

          </div>


        </main>
      </div>
      <Footer/>
    </>
  )
}

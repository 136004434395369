import React from 'react';

// Mock data
const articlesData = [
    {id: 1, title: 'Pages on Pages', link: 'https://pagesonpages.com'},
];

const Partners = () => {
    return (
        <div className="container">
            <h1 className="">Partners</h1>
            <ul className="list-group">
                {articlesData.map((article) => (
                    <li className="list-group-item" key={article.id}>
                        <h2><a href={article.link}>{article.title}</a></h2>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default Partners;
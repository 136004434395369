import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function WritingSongs() {
const title = "Writing Songs";
const keywords = "writing songs, lyric generator, music generator, song creation, music creation, songwriting assistant, songwriting app, music composition tool, songwriting platform, music writing software, music writing tool, how to write songs";
const description = "Master the art of writing songs with our AI-powered tools";
const longDescription = "Lyrical Labs offers a unique songwriting platform equipped with an advanced lyric generator and music generator, making it easier than ever to write songs. Tap into AI for a seamless blend of lyrics and music, tailored to your personal style and needs!";
const h1 = "Master the Art of Writing Songs with AI";
const h2 = "Elevate Your Music and Lyrics through Revolutionary AI Technology";
const sectionHeader = "Your Ultimate Songwriting Partner";
const featureTitle1 = "Unleash endless creativity";
const featureTitle2 = "AI-powered lyric and music creation";
const featureTitle3 = "Advanced lyrical intelligence";
const featureTitle4 = "Compose music seamlessly";
const featureParagraph1 = "Experience a limitless fountain of creative song ideas, melodies, and lyrics. Whether you're focused on a particular story or exploring various genres, our AI has you covered.";
const featureParagraph2 = "Our platform’s unique capability to generate both music and lyrics in harmony means you have a holistic songwriting assistant at your fingertips, ready to transform your ideas into songs.";
const featureParagraph3 = "With our sophisticated AI-driven lyric generator, find the perfect words to express your thoughts and emotions, enhancing your songwriting journey with rich vocabulary and poetic inspiration.";
const featureParagraph4 = "Our music generator empowers you to craft the perfect melody for your lyrics, regardless of genre. Making music becomes effortless, opening a world of possibilities for songwriters.";
const faq1 = "Will I retain copyright over the songs I create?";
const faq2 = "Does the platform solely compose the music and lyrics for me?";
const faq3 = "How can this tool help me with writing songs reliably?";
const faq4 = "In what ways does the platform enrich my songwriting and composition process?";
const faqa1 = "Absolutely! With Lyrical Labs, you hold 100% ownership over the songs you create, maintaining all rights to your music and lyrics.";
const faqa2 = "While our AI provides a comprehensive foundation by generating music and lyrics, the essence of our platform is in aiding your creativity. It’s designed to inspire and augment your songwriting process, not to replace it.";
const faqa3 = "Facing a creative block in writing songs is common, but our tool provides a continuous stream of ideas and inspirations to break through. Input your theme or musical style, and the AI instantly delivers tailored songwriting suggestions.";
const faqa4a = "Lyrical Labs stimulates your creativity by offering boundless ideas and musical suggestions that align with your preferred genres and themes, encouraging innovative exploration in your songwriting endeavor.";
const faqa4b = "Beyond just ideation, the platform supports the songwriting process with features like the 'Related Words' dictionary, aiding in lyric formulation and ensuring your songs remain unique and deeply personal.";
const quote1 = "Transformed my songwriting process, making music creation more intuitive";
const quote2 = "A vital tool for anyone into writing songs— it's like having a co-writer by your side 24/7";
const quote3 = "Outstanding support and incredible technology. It's revolutionized how I approach writing songs";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}

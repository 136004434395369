import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import { Select, MenuItem } from '@mui/material';

import Footer from '../components/Footer';
import Countdown from 'react-countdown';
import './PricingTable.css';


function PricingContent({ user, setUser, setSubscriptionData, subscriptionActive, setSubscriptionActive }) {

  const url = "https://frozen-springs-61240.herokuapp.com";
  const navigate = useNavigate();
  const [pricingOption, setPricingOption] = useState('oneoff');
  const hobbyistPaymentLink = 'https://buy.stripe.com/5kA03q5mB0tJ5oc5kk';
  const songwriterPaymentLink = 'https://buy.stripe.com/14k03qdT71xN17WeUV';
  const professionalPaymentLink = 'https://buy.stripe.com/eVa03qaGV3FV03S7su';


  const theme = createTheme({
    palette: {
      primary: {
        main: 'rgba(255, 255, 255, 0.3)',
      },
      secondary: {
        main: '#121212',
      },
    },
  });

  useEffect(() => {
    if (user && user.customer && user.customer.stripeSubscriptionStatus === 'active') {
      setSubscriptionActive(true);
    }

  }, [])

  const tiers = [
    {
      title: 'Hobbyist',
      subtitle: <br></br>,
      price: '10',
      description: [
        '- 200 credits/month',
        '- Chat support',
        ' '
      ],
      buttonText: 'Subscribe',
      buttonVariant: 'contained',
      buttonOnClick: () => {createSubscription('price_1N0Kc9DsTL5MPVicgAc3gDlU')}
    },
    {
      title: 'Songwriter',
      subtitle: 'Most popular',
      price: '30',
      description: [
        '- 1000 credits/month',
        '- Priority email support',
        '- Access to the latest AI models'
      ],
      buttonText: 'Subscribe',
      buttonVariant: 'contained',
      buttonOnClick: () => {createSubscription('price_1N0Kd0DsTL5MPVicz5iXxPe6')}
    },
    {
      title: 'Professional',
      subtitle: <br></br>,
      price: '100',
      description: [
        '- 5000 credits/month',
        '- 24/7 phone & email support',
        '- Access to the latest AI models'
      ],
      buttonText: 'Subscribe',
      buttonVariant: 'contained',
      buttonOnClick: () => {createSubscription('price_1N0KdgDsTL5MPVicAia1scom')}
    },
  ];

  const oneOffTiers = [
    {
      title: 'Hobbyist',
      subtitle: <br></br>,
      price: '15',
      description: [
        '- 200 credits',
        '- Chat support',
        ' ',
        ' ',
      ],
      buttonText: 'Buy 200',
      buttonVariant: 'contained',
      buttonOnClick: () => {oneOffPayment('hobbyist')}
    },
    {
      title: 'Songwriter',
      subtitle: 'Most popular',
      price: '40',
      description: [
        '- 1000 credits',
        '- Priority email support',
        '- Access to the latest AI models'
      ],
      buttonText: 'Buy 1000',
      buttonVariant: 'contained',
      buttonOnClick: () => {oneOffPayment('songwriter')}
    },
    {
      title: 'Professional',
      subtitle: <br></br>,
      price: '150',
      description: [
        '- 5000 credits',
        '- 24/7 phone & email support',
        '- Access to the latest AI models'
      ],
      buttonText: 'Buy 5000',
      buttonVariant: 'contained',
      buttonOnClick: () => {oneOffPayment('professional')}
    },
  ];

  const oneOffPayment = async (priceId) => {
    if(!user) {
      navigate('/signin');
      return;
    }
    if (!user.customer) {
      const userCustomer = await fetch(`${url}/create-customer`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': user.token,
        },
        body: JSON.stringify({
          user
        }),
      }).then(r => {
        let json = r.json();
        if (r.status === 400) {
          if (json.error) {
            navigate('/signin');
          } else {
            let updatedUser = user;
            updatedUser.customer = json;
            setUser(updatedUser)
          }
        } else if (r.status === 500){
          navigate('/signin');
        } else {
          const token = user.token;
          let customer = json;
          customer.token = token;
          setUser(customer);
        }
        return json});

    }
    if (subscriptionActive) {
      navigate('/account');
      return;
    }
    if (!subscriptionActive) {
      const subscriptionStatus = await fetch(`${url}/check-subscription`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': user.token,
        },
        body: JSON.stringify({
          user
        }),
      }).then(r => {
        if (r.ok){
          return r.json();
        } else {
          return false;
        }
      });
      if (subscriptionStatus) {
        setSubscriptionActive(true);
        let updatedUser = user;
        updatedUser.customer = subscriptionStatus;
        setUser(updatedUser);
        navigate('/account');
        return;
      }
    }
    let paymentUrl;
    if (priceId === 'hobbyist'){
      paymentUrl = hobbyistPaymentLink;
    } else if (priceId === 'songwriter'){
      paymentUrl = songwriterPaymentLink;
    }
    else if (priceId === 'professional'){
      paymentUrl = professionalPaymentLink;
    }
    const userEmail = user.email;
    const encodedEmail = encodeURIComponent(userEmail);
    const userCustomerId = user.customer.stripeId;
    const encodedCustomerId = encodeURIComponent(userCustomerId);
    const fullUrl = `${paymentUrl}?client_reference_id=${encodedCustomerId}&prefilled_email=${encodedEmail}`;
    window.open(fullUrl, '_blank');
  }



  const createSubscription = async (priceId) => {
    if(!user) {
      navigate('/signin');
      return;
    }
    if (!user.customer) {
      const userCustomer = await fetch(`${url}/create-customer`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': user.token,
        },
        body: JSON.stringify({
          user
        }),
      }).then(r => {
        let json = r.json();
        if (r.status === 400) {
          if (json.error) {
            navigate('/signin');
          } else {
            let updatedUser = user;
            updatedUser.customer = json;
            setUser(updatedUser)
          }
        } else if (r.status === 500){
          navigate('/signin');
        } else {
          const token = user.token;
          let customer = json;
          customer.token = token;
          setUser(customer);
        }
        return json});

    }
    if (subscriptionActive) {
      navigate('/account');
      return;
    }
    if (!subscriptionActive) {
      const subscriptionStatus = await fetch(`${url}/check-subscription`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': user.token,
        },
        body: JSON.stringify({
          user
        }),
      }).then(r => {
        if (r.ok){
          return r.json();
        } else {
          return false;
        }
      });
      if (subscriptionStatus) {
        setSubscriptionActive(true);
        let updatedUser = user;
        updatedUser.customer = subscriptionStatus;
        setUser(updatedUser);
        navigate('/account');
        return;
      }
    }
    const {subscriptionId, clientSecret} = await fetch(`${url}/create-subscription`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': user.token,
      },
      body: JSON.stringify({
        priceId,
        user
      }),
    }).then(r => r.json());

    setSubscriptionData({ subscriptionId, clientSecret });
    navigate('/subscribe')
  }

  function getEndOfMonth() {
    const now = new Date();
    const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1);
    return endOfMonth;
  }
  
  function getSaleLabel() {
    const now = new Date();
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    return `${monthNames[now.getMonth()]} Sale`;
  }

  function SaleBanner() {
    return (
      <div className="sale-banner">
        <span>
          {getSaleLabel()} - Save 50%! Hurry up! Ends in{" "}
          <Countdown
            date={getEndOfMonth()}
            renderer={({ days, hours, minutes, seconds, completed }) => {
              if (completed) {
                return <span>0d 0h 0m 0s</span>;
              } else {
                return (
                  <span>
                    {days}d {hours}h {minutes}m {seconds}s
                  </span>
                );
              }
            }}
          />
        </span>
      </div>
    );
  }
  

  return (
    <>
      <div className="min-h-screen pt-12 w-screen bg-[#101010]">
      <SaleBanner />
        <section id="FAQ" className = "flex flex-row lg:flex-col px-8 lg:px-36 pb-16">
          <div className="rounded-md bg-white/5 mt-10 lg:px-10 lg:mx-24 mb-12">
          <h1 className="text-left text-3xl mx-6 mt-6 font-bold tracking-tight text-white">
            Select your plan
          </h1>
          <Select   sx={{ 
            color: "white", 
            "&:before": { // underline color when not focused
              borderColor: "white",
            },
            "&:after": { // underline color when focused
              borderColor: "white",
            },
            "& .MuiSvgIcon-root": { // arrow icon color
              color: "white",
            }
          }} value={pricingOption} onChange={(event) => setPricingOption(event.target.value)}>
            <MenuItem value='subscriptions'>Subscriptions</MenuItem>
            <MenuItem value='oneoff'>One-Off Payments</MenuItem>
          </Select>
          <p className="text-left text-sm mx-6 mt-4 font-bold tracking-tight text-white/80">
            Charged monthly. Cancel any time. All prices in USD, your currency will be converted automatically.
          </p>
          <Countdown
            date={getEndOfMonth()}
            renderer={({ days, hours, minutes, seconds, completed }) => {
              if (completed) {
                return <span>{getSaleLabel()} has ended.</span>;
              } else {
                return (
                  <span className="text-left text-sm mx-6 mt-4 font-bold tracking-tight text-white/80">
                    {getSaleLabel()} ends in {days}d {hours}h {minutes}m {seconds}s
                  </span>
                );
              }
            }}
          />

          <Container component="div">
            <Grid container spacing={3} alignItems="flex-start">
              { pricingOption === 'subscriptions' ? tiers.map((tier) => (
                // Enterprise card is full width at sm breakpoint
                <Grid
                  item
                  key={tier.title}
                  xs={12}
                  sm={tier.title === 'Enterprise' ? 12 : 6}
                  md={4}
                >
                  <div className="hover:bg-neutral-900">
                    <Card sx={{backgroundColor: 'rgb(255,255,255,0.00)', border: 1, borderColor: 'rgb(255,255,255,0.1)', px: 1, pb: 4, my: 4}}>
                      <h1 className="text-left text-3xl mx-3 mt-6 font-bold tracking-tight text-white/80 hover"> {tier.title} </h1>
                      <h1 className="text-left text-sm mx-3 font-bold tracking-tight text-white/50"> {tier.subtitle} </h1>

                      <CardContent>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'left',
                            alignItems: 'baseline',
                            mb: 10,
                          }}
                        >
                          <Typography className="line-through" component="h4" variant="h4" color="rgb(255,255,255,0.8)">
                            ${tier.price}
                          </Typography>
                          <Typography component="h2" variant="h3" color="rgb(255,255,255,0.8)">
                            ${((tier.price / 2))}
                          </Typography>
                          <Typography variant="h6" color="rgb(255,255,255,0.5)">
                            /mo
                          </Typography>
                        </Box>
                        <ul>
                          {tier.description.map((line) => (
                            <Typography
                              component="li"
                              variant="subtitle1"
                              align="left"
                              key={line}
                              color="rgb(255,255,255,0.5)"
                            >
                              {line}
                            </Typography>
                          ))}
                        </ul>
                      </CardContent>
                      <CardActions>
                        <Button fullWidth onClick={tier.buttonOnClick} variant={tier.buttonVariant}
                        style={{
                          borderRadius: 5,
                          backgroundColor: "#6466e9",
                        }}>
                          {tier.buttonText}
                        </Button>
                      </CardActions>
                    </Card>
                  </div>

                </Grid>
              ))
            : oneOffTiers.map((tier) => (
              <Grid
              item
              key={tier.title}
              xs={12}
              sm={tier.title === 'Enterprise' ? 12 : 6}
              md={4}
            >
              <div className="hover:bg-neutral-900">
                <Card sx={{backgroundColor: 'rgb(255,255,255,0.00)', border: 1, borderColor: 'rgb(255,255,255,0.1)', px: 1, pb: 4, my: 4}}>
                  <h1 className="text-left text-3xl mx-3 mt-6 font-bold tracking-tight text-white/80 hover"> {tier.title} </h1>
                  <h1 className="text-left text-sm mx-3 font-bold tracking-tight text-white/50"> {tier.subtitle} </h1>

                  <CardContent>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'left',
                        alignItems: 'baseline',
                        mb: 10,
                      }}
                    >
                      <Typography className="line-through" component="h4" variant="h4" color="rgb(255,255,255,0.8)">
                        ${tier.price}
                      </Typography>
                      <Typography component="h2" variant="h3" color="rgb(255,255,255,0.8)">
                        ${(tier.price / 2)}
                      </Typography>
                    </Box>
                    <ul>
                      {tier.description.map((line) => (
                        <Typography
                          component="li"
                          variant="subtitle1"
                          align="left"
                          key={line}
                          color="rgb(255,255,255,0.5)"
                        >
                          {line}
                        </Typography>
                      ))}
                    </ul>
                  </CardContent>
                  <CardActions>
                    <Button fullWidth onClick={tier.buttonOnClick} variant={tier.buttonVariant}
                    style={{
                      borderRadius: 5,
                      backgroundColor: "#6466e9",
                    }}>
                      {tier.buttonText}
                    </Button>
                  </CardActions>
                </Card>
              </div>

            </Grid>
            ))
            }
            </Grid>
          </Container>
          </div>
        </section>
      </div>
      <Footer/>
    </>
  );
}

export default function Pricing({ user, setUser, setSubscriptionData, subscriptionActive, setSubscriptionActive }) {
  return <PricingContent user={user} setUser={setUser} setSubscriptionData={setSubscriptionData} subscriptionActive={subscriptionActive} setSubscriptionActive={setSubscriptionActive} />;
}
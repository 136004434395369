import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function ChromeMusicLab() {
const title = "Chrome Music Lab";
const keywords = "chrome music lab, music generator, lyrics generator, music composition, online music lab, music creation tool, music creation app, song creation platform, music lab website, create music online, how to create music";
const description = "Explore the future of music creation with our Chrome Music Lab alternative";
const longDescription = "Lyrical Labs is an innovative platform designed to simplify music and lyrics creation. Leveraging cutting-edge AI, it serves as both your music composer and lyricist, catering to all your song creation needs!";
const h1 = "Create Music and Lyrics effortlessly with our Chrome Music Lab alternative";
const h2 = "Unleash your musical creativity with our AI-powered Music Lab";
const sectionHeader = "Your One-stop Music Creation Studio";
const featureTitle1 = "Streamline music composition";
const featureTitle2 = "Innovative lyrics and music fusion";
const featureTitle3 = "AI-driven melody creation";
const featureTitle4 = "Diverse genre exploration";
const featureParagraph1 = "Lyrical Music Labs simplifies the music creation process, offering a plethora of tools and features to effortlessly compose music. Dive into an ocean of sounds and create captivating melodies with just a few clicks.";
const featureParagraph2 = "Our platform is unique in its ability to conjoin lyrics and music seamlessly. Start by generating lyrics tailored to your theme, then let the AI compose a matching melody, creating a perfect harmony.";
const featureParagraph3 = "With our advanced AI algorithms, crafting beautiful melodies has never been easier. Whether you're a beginner or a seasoned musician, Lyrical Music Lab caters to all levels, inspiring you to explore your musical boundaries.";
const featureParagraph4 = "Jazz, Pop, Rock, or Classical - Lyrical Music Labs supports a wide range of genres, allowing you to experiment and craft songs in your preferred style. The possibilities are endless!";
const faq1 = "Can Lyrical Labs help me if I’m not a musician?";
const faq2 = "Is Lyrical Labs suitable for professional music production?";
const faq3 = "How can Lyrical Labs improve my songwriting skills?";
const faq4 = "What makes Lyrical Labs different from other music creation tools?";
const faqa1 = "Absolutely! Lyrical Labs is designed to be accessible to everyone, regardless of musical skill. Our intuitive AI works as your personal assistant, guiding you through every step of the music and lyric creation process.";
const faqa2 = "Yes, many professionals find Lyrical Labs a valuable tool for drafting ideas and exploring new musical concepts quickly. Its AI-driven tools can enhance any production process.";
const faqa3 = "By providing endless inspiration and allowing for effortless experimentation with music and lyrics, Lyrical Labs helps hone your songwriting skills, making the process more enjoyable and less challenging.";
const faqa4a = "Lyrical Labs stands out by offering a comprehensive suite of tools that cater to both music and lyrics creation, driven by AI technology. Its user-friendly interface makes it a go-to platform for creators of all levels.";
const faqa4b = "Moreover, its unique approach to combining music and lyrics generation in one platform sets it apart, providing a holistic and creative song creation experience.";
const quote1 = "Lyrical Labs made the impossible possible - I created my first song in an hour!";
const quote2 = "An invaluable tool for anyone interested in music creation, regardless of their experience level.";
const quote3 = "Never thought making music could be this easy and fun. Lyrical Labs is a real innovation.";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}

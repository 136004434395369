import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function SongwritingAppIphone() {
const title = "Songwriting App Iphone";
const keywords = "songwriting app iPhone, lyrics generator, music generator, songwriting, songwriting assistant, songwriting platform, songwriting software, songwriting tool, songwriting tools, songwriting website, iPhone songwriting app, how to write a song iPhone";
const description = "Craft songs on your iPhone with AI";
const longDescription = "Lyrical Labs brings its revolutionary songwriting platform to your iPhone, making it easier than ever to generate lyrics and music on the go. It's your pocket-sized songwriting assistant, tailored to your unique tastes and needs!";
const h1 = "Craft songs on your iPhone with the power of AI";
const h2 = "Elevate your songwriting with our AI-powered lyrics and music generator for iPhone.";
const sectionHeader = "Your Portable Songwriting Studio";
const featureTitle1 = "Create anywhere, anytime";
const featureTitle2 = "Tailored songwriting assistant in your pocket";
const featureTitle3 = "Intelligent lyric and music generation";
const featureTitle4 = "Multilingual songwriting made easy";
const featureParagraph1 = "Unleash continuous songwriting inspiration with an endless stream of original ideas and melodies, right from your iPhone. No matter the topic or genre, the AI adapts to your songwriting needs.";
const featureParagraph2 = "Collaborate with Lyrical Labs directly from your iPhone to perfectly tailor songs to your style. Provide feedback and watch as your musical vision comes to life with ease.";
const featureParagraph3 = "Equipped with an advanced AI, our platform on iPhone helps you discover a plethora of related words, ideas, and melodies, making writing and composing simpler than ever.";
const featureParagraph4 = "With support for over 100+ languages, your songwriting potentials are limitless on your iPhone, allowing you to express your musical creativity in any language.";
const faq1 = "Can I use this app on my iPhone?";
const faq2 = "Does this app write the whole song for me?";
const faq3 = "How does this app help when I’m stuck on songwriting?";
const faq4 = "What makes this songwriting app unique for iPhone users?";
const faqa1 = "Absolutely! Lyrical Labs is designed for the iPhone, providing a seamless and user-friendly songwriting experience on your device.";
const faqa2 = "While the app can generate complete lyrics and melodies, we encourage you to infuse them with your personal touch. Consider it your digital songwriting collaborator that sparks your creativity.";
const faqa3 = "Facing writer's block is common, but our iPhone app is here to ensure that you’re never out of ideas. Input your theme and style, and receive an endless stream of creative prompts to inspire your next hit.";
const faqa4a = "Designed specifically for iPhone users, this platform offers unparalleled convenience and innovation. Experiment freely with styles, and avail yourself of unique features like the 'Related Words' dictionary.";
const faqa4b = "Moreover, having the ability to craft songs on your iPhone means songwriting inspiration is always at your fingertips, ready whenever creativity strikes.";
const quote1 = "Transformed my songwriting routine. Must-have for any musician with an iPhone!";
const quote2 = "Incredible app for generating song ideas. A true companion for my musical journey.";
const quote3 = "Such an innovative approach to songwriting. Makes creating music on my iPhone effortless and fun!";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}

import React from 'react';
import Footer from '../components/Footer';

const PrivacyPolicy = () => {
    return (
      <>
      <div className="pt-24 p-10" style={{color: 'white'}}>
    <h1>Privacy Policy for Lyrical Labs</h1>
    <p><strong>Last Updated:</strong> 21-Aug-2024</p>

    <p>Lyrical Labs ("we," "our," or "us") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, and share information about you when you visit our website, use our services, or interact with us.</p>

    <br/>

    <h2>1. Information We Collect</h2>

    <h3>1.1. Information You Provide to Us</h3>
    <ul>
      <li><strong>Account Registration:</strong> When you create an account on Lyrical Labs, we collect your email address, name, and other registration details.</li>
      <li><strong>User Prompts and Generated Content:</strong> We may collect and save the prompts you use to generate lyrics. Lyrics generated by free users may be saved, while lyrics generated by paying users will never be saved.</li>
    </ul>

    <h3>1.2. Information Collected Automatically</h3>
    <ul>
      <li><strong>IP Address:</strong> We collect and save your IP address to monitor usage for individuals without accounts.</li>
      <li><strong>Cookies and Local Storage:</strong> We use cookies and local storage to maintain data and state between sessions and to save links to your generated music files.</li>
      <li><strong>Analytics Information:</strong> We use Google Analytics to collect basic information about your activity on our website, including page views, location, and other user interactions. --you can read more about how Google uses your Personal Information here: <a href="https://policies.google.com/privacy?hl=en-US">https://policies.google.com/privacy?hl=en-US.</a></li>
    </ul>

    <h3>1.3. Information from Third Parties</h3>
    <ul>
      <li><strong>Music Generation:</strong> We currently use Replicate to host the music generator API. The prompts and generated music files are stored with Replicate. We may switch to another service provider in the future.</li>
      <li><strong>Payment Processing:</strong> We use Stripe to process payments for paying customers. Stripe collects and stores basic customer and payment information. --you can read more about how Stripe uses your Personal Information here: <a href="https://stripe.com/au/privacy">https://stripe.com/au/privacy</a> </li>
      <li><strong>Chat Service:</strong> We use Tawk to provide chat support. Tawk stores basic information of users who visit our website, including page views and conversation history.</li>
      <li><strong>Advertising:</strong> We use different ad networks to display advertisements on our site. These networks may collect user data and cookies to provide targeted ads.</li>
    </ul>

    <br/>

    <h2>2. How We Use Your Information</h2>
    <p>We use the information we collect to:</p>
    <ul>
      <li>Provide and improve our services.</li>
      <li>Monitor and analyze trends, usage, and activities.</li>
      <li>Process payments and fulfill transactions.</li>
      <li>Communicate with you regarding your account, prompts, and generated content.</li>
      <li>Provide customer support and respond to inquiries.</li>
      <li>Display relevant advertisements.</li>
    </ul>

    <br/>
    <h2>3. How We Share Your Information</h2>
    <p>We may share your information in the following ways:</p>
    <ul>
      <li><strong>Service Providers:</strong> We share information with third-party service providers like Replicate, Stripe, Tawk, and ad networks that help us operate our website and provide services.</li>
      <li><strong>Legal Requirements:</strong> We may disclose your information if required to do so by law or in response to legal requests.</li>
    </ul>

    <br/>
    <h2>4. Your Choices and Rights</h2>
    <ul>
      <li><strong>Access and Correction:</strong> You can access and update your account information at any time through your account settings.</li>
      <li><strong>Data Retention:</strong> We retain your information as long as necessary to provide our services and comply with legal obligations.</li>
      <li><strong>Opt-Out:</strong> You can opt-out of certain data collection practices, such as targeted advertising, by adjusting your browser settings or through the opt-out mechanisms provided by ad networks.</li>
    </ul>

    <br/>
    <h2>5. Security</h2>
    <p>We implement appropriate security measures to protect your information from unauthorized access, disclosure, alteration, or destruction.</p>

    <br/>

    <h2>6. Changes to This Privacy Policy</h2>
    <p>We may update this Privacy Policy from time to time. When we make changes, we will update the "Last Updated" date at the top of the policy.</p>

    <br/>
    <h2>7. Contact Us</h2>
    <p>If you have any questions about this Privacy Policy, please contact us at lyricallabsio@gmail.com .</p>
  </div>
        <Footer/>
        </>
    );
};

export default PrivacyPolicy;

import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function CommentÉcrireUneChanson() {
const title = "Comment Écrire Une Chanson";
const keywords = "comment écrire une chanson, générateur de musique, générateur de paroles, aide à l'écriture de chansons, application d'écriture de chansons, plateforme d'écriture de chansons, logiciel d'écriture de chansons, outil d'écriture de chansons, outils d'écriture de chansons, site web d'écriture de chansons";
const description = "Apprenez comment écrire une chanson avec l'aide de l'IA";
const longDescription = "Lyrical Labs est une plateforme d'écriture de chansons qui utilise l'IA pour vous aider à créer des paroles et des mélodies plus rapidement. C'est votre assistant personnel d'écriture de chansons, adapté à vos goûts et à vos besoins en matière de composition !";
const h1 = "Apprenez comment écrire une chanson grâce à la puissance de l'IA.";
const h2 = "Révolutionnez votre écriture de chansons et obtenez une inspiration infinie avec nos paroles générées par IA.";
const sectionHeader = "Votre Compagnon Ultime en Écriture de Chansons";
const featureTitle1 = "Créativité sans limites";
const featureTitle2 = "Votre assistant personnel pour l'écriture de chansons";
const featureTitle3 = "Dictionnaire intelligent intégré";
const featureTitle4 = "Écrivez dans n'importe quelle langue";
const featureParagraph1 = "Une inspiration sans fin pour l'écriture de chansons vous attend avec une source inépuisable d'idées originales et de mélodies. L'IA peut écrire sur n'importe quel sujet et générer de la musique de n'importe quel genre.";
const featureParagraph2 = "Collaborez avec notre partenaire de composition IA pour créer la chanson parfaite. Une fois que vous avez les paroles et la musique, vous pouvez dialoguer avec l'IA pour donner vie à votre vision musicale.";
const featureParagraph3 = "La plateforme est équipée d'un dictionnaire intelligent intégré qui vous aide à trouver toutes sortes de mots associés pour inspirer et vous aider à finaliser vos paroles.";
const featureParagraph4 = "Avec plus de 100+ langues disponibles, vous pouvez apprendre à écrire des paroles de chansons dans n'importe quelle langue !";
const faq1 = "Conserverai-je les droits d'auteur ?";
const faq2 = "L'objectif de la plateforme est-il de composer mes chansons pour moi ?";
const faq3 = "Comment la plateforme m'aide-t-elle à surmonter le blocage de l'écrivain ?";
const faq4 = "Comment la plateforme stimule-t-elle mon processus d'écriture de chansons ?";
const faqa1 = "Oui ! Lyrical Labs est 100% sans royalties, donc vous conservez tous les droits sur la musique et les paroles que vous créez sur cette plateforme.";
const faqa2 = "Non, le but de notre plateforme est de vous fournir une source d'inspiration sans fin et de vous aider à surmonter le blocage d'écrivain. Bien que l'IA soit capable de générer des ensembles complets de paroles et des mélodies musicales, nous vous encourageons à les utiliser comme point de départ et à ajouter votre touche personnelle. L'IA est là pour assister et inspirer, et non pour remplacer votre créativité.";
const faqa3 = "Le blocage de l'écrivain peut être une expérience frustrante et démoralisante pour tout auteur-compositeur. Mais avec notre plateforme, vous n'aurez plus jamais à vous soucier de manquer d'idées. Il suffit de saisir le thème et le style souhaités, et l'IA vous fournira un flux continu d'idées créatives et originales à utiliser. Cela peut aider à démarrer votre processus de composition et à vous remettre sur les rails.";
const faqa4a = "Notre plateforme est conçue pour vous fournir un flux continu d'idées et de suggestions basées sur vos préférences stylistiques choisies, vous donnant la liberté d'expérimenter et d'essayer quelque chose de nouveau.";
const faqa4b = "De plus, la plateforme vous permet d'écrire et de modifier les paroles à votre guise, avec un dico 'Mots Reliés' très unique à vos côtés pour vous assister tout au long de votre processus d'écriture. C'est comme votre propre assistant créatif, adapté à vos propres goûts et besoins en matière de composition !";
const quote1 = "C'est un changement de jeu, vraiment aidé à écrire ma première chanson";
const quote2 = "Produit génial, je recommanderais à quiconque veut apprendre comment écrire une chanson";
const quote3 = "Service client excellent, vraiment cool pour apprendre à écrire une chanson";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}

import React from 'react';
import Footer from '../components/Footer';

const TermsAndConditions = () => {
    return (
      <>
        <div className="pt-24 p-10" style={{color: 'white'}}>
            <h1>Terms and Conditions for Lyrical Labs</h1>
            <p>Last Updated: 25-Jan-2024</p>

            <h2>1. Introduction</h2>
            <p>Welcome to Lyrical Labs! These terms and conditions outline the rules and regulations for the use of Lyrical Labs' Website, located at <a href="https://lyricallabs.io">https://lyricallabs.io</a>.</p>
            <p>By accessing this website, we assume you accept these terms and conditions. Do not continue to use Lyrical Labs if you do not agree to take all of the terms and conditions stated on this page.</p>
            <br/>
            <h3>2. Intellectual Property Rights</h3>
            <p>Other than the content you own, and the lyrics and music you generate, under these Terms, Lyrical Labs and/or its licensors own all the intellectual property rights and materials contained in this Website.</p>
            <p>You are granted limited license only for purposes of viewing the material contained on this Website.</p>

            <br/>
            <h3>3. Restrictions</h3>
            <ul>
                <li>- Publishing any Website material in any other media, excepting the lyrics and music you generate.</li>
                <li>- Selling, sublicensing and/or otherwise commercializing any Website material, excepting the lyrics and music you generate</li>
                <li>- Publicly performing and/or showing any Website material, excepting the lyrics and music you generate</li>
                <li>- Using this Website in any way that is or may be damaging to this Website</li>
                <li>- Using this Website in any way that impacts user access to this Website</li>
            </ul>

            <br/>

            <h3>4. Your Content</h3>
            <p>In these Website Standard Terms and Conditions, "Your Content" shall mean any audio, video text, images or other material you choose to display on this Website. By displaying Your Content, you grant Lyrical Labs a non-exclusive, worldwide irrevocable, sub licensable license to use, reproduce, adapt, publish, translate and distribute it in any and all media.</p>

            <br/>

            <h3>5. No warranties</h3>
            <p>This Website is provided "as is," with all faults, and Lyrical Labs express no representations or warranties, of any kind related to this Website or the materials contained on this Website.</p>

            <br/>

            <h3>6. Limitation of liability</h3>
            <p>In no event shall Lyrical Labs, nor any of its officers, directors and employees, be held liable for anything arising out of or in any way connected with your use of this Website whether such liability is under contract.</p>

            <br/>

            <h3>7. Indemnification</h3>
            <p>You hereby indemnify to the fullest extent Lyrical Labs from and against any and/or all liabilities, costs, demands, causes of action, damages and expenses arising in any way related to your breach of any of the provisions of these Terms.</p>

            <br/>

            <h3>8. Severability</h3>
            <p>If any provision of these Terms is found to be invalid under any applicable law, such provisions shall be deleted without affecting the remaining provisions herein.</p>

            <br/>

            <h3>9. Variation of Terms</h3>
            <p>Lyrical Labs is permitted to revise these Terms at any time as it sees fit, and by using this Website you are expected to review these Terms on a regular basis.</p>

            <br/>

            <h3>10. Assignment</h3>
            <p>The Lyrical Labs is allowed to assign, transfer, and subcontract its rights and/or obligations under these Terms without any notification. However, you are not allowed to assign, transfer, or subcontract any of your rights and/or obligations under these Terms.</p>

            <br/>

            <h3>11. Entire Agreement</h3>
            <p>These Terms constitute the entire agreement between Lyrical Labs and you in relation to your use of this Website, and supersede all prior agreements and understandings.</p>

            <br/>

            <h3>12. Governing Law & Jurisdiction</h3>
            <p>These Terms will be governed by and interpreted in accordance with the laws of New South Wales, Australia, and you submit to the non-exclusive jurisdiction of the state and federal courts located in New South Wales, Australia for the resolution of any disputes.</p>
        </div>
        <Footer />
        </>
    );
};

export default TermsAndConditions;

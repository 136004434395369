import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function CopyrightFreeMusic() {
const title = "Copyright Free Music";
const keywords = "copyright free music, AI music generator, lyrics generator, copyright free, music composition, royalty free, music creation app, music creation platform, music creation software, music creation tool, music creation tools, music creation website, free copyright music";
const description = "Create copyright free music with AI";
const longDescription = "Lyrical Labs is a music creation platform that uses AI to help you generate lyrics and copyright free music easily. It's your ultimate music creation assistant, designed to cater to your unique needs and preferences in music and lyrics!";
const h1 = "Generate Copyright Free Music and Lyrics with AI.";
const h2 = "Unlock the potential of copyright free compositions with our AI-powered lyrics and music generator.";
const sectionHeader = "Your Ultimate Music Creation Ally";
const featureTitle1 = "Royalty-free music at your fingertips";
const featureTitle2 = "Seamless lyrics and music generation";
const featureTitle3 = "Customizable to your creative style";
const featureTitle4 = "Expand your musical genres";
const featureParagraph1 = "Experience the freedom to create music without worrying about copyright restrictions. Our AI offers an unlimited supply of copyright free melodies and lyrics, ready for commercial use or personal projects.";
const featureParagraph2 = "Our intuitive platform enables you to generate both lyrics and music that complement each other perfectly. With Lyrical Labs, you’re just a few clicks away from your next great composition.";
const featureParagraph3 = "The AI tailors its suggestions based on your input, whether you’re crafting a ballad or an upbeat track. Dive into customization options to refine lyrics and melodies that speak to your soul.";
const featureParagraph4 = "From pop to classical, our AI understands various music genres, allowing you to experiment and create music that stands out. Explore new territories in your musical journey with ease.";
const faq1 = "What rights do I have over the music created?";
const faq2 = "Can I use this music commercially?";
const faq3 = "How does AI contribute to making copyright free music?";
const faq4 = "Can the AI adapt to my preferred music style?";
const faqa1 = "All music and lyrics created with Lyrical Labs are 100% copyright free. You own the full rights, enabling you to use them however you see fit—commercially or otherwise.";
const faqa2 = "Absolutely! The music generated is designed to be used freely in your projects or commercial endeavours without worrying about copyright infringements.";
const faqa3 = "Our AI is trained with a vast library of royalty-free music and understands the importance of creating unique, copyright free compositions. It generates music that’s both original and free to use.";
const faqa4a = "Yes, our platform is incredibly versatile and can adjust to your musical preferences. Whether you're into jazz, rock, or electronic, the AI is equipped to generate music that aligns with your style.";
const faqa4b = "Moreover, the platform’s 'smart dictionary' and genre understanding aid in honing lyrics and music that resonate with your personal taste, encouraging creative exploration.";
const quote1 = "Creating copyright free music has never been easier. A revolutionary tool for all musicians.";
const quote2 = "Lyrical Labs has transformed the way I approach music production. It’s an invaluable resource for copyright free tunes.";
const quote3 = "Amazing platform that respects creative freedom and encourages originality in music creation.";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}

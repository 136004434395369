// App.js
import React, { useState } from 'react';
import Option1 from '../MusicPlayer/MusicPlayer';
import Option2 from '../Homescreen/Homescreen';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';



const MainApp = () => {
    const [currentPage, setCurrentPage] = useState('Option1');

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    return (
        <div className="app-container">
            <div className="page-container">
            <Sidebar>
                    <Menu menuItemStyles={{
                        button: ({ level, active, disabled }) => {
                            // only apply styles on first level elements of the tree
                            if (level === 0)
                                return {
                                    color: disabled ? '#f5d9ff' : '#d359ff',
                                    backgroundColor: active ? '#eecef9' : undefined,
                                };
                        },
                    }}>
                    
                    <SubMenu label="Charts">
                        <MenuItem> Pie charts </MenuItem>
                        <MenuItem> Line charts </MenuItem>
                    </SubMenu>
                    <MenuItem> Documentation </MenuItem>
                    <MenuItem> Calendar </MenuItem>
                </Menu>
            </Sidebar>;
                {currentPage === 'Option1' ? <Option1 /> : <Option2 />}
            </div>
        </div>
    );
};

export default MainApp;

import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function AiGenerator() {
const title = "Ai Generator";
const keywords = "AI generator, lyric generator, music generator, song creation, AI music, AI lyrics, music creation tool, AI songwriting assistant, music production software, AI composition tool, AI songwriting, AI music platform";
const description = "Harness the power of AI to generate music and lyrics";
const longDescription = "Lyrical Labs is an innovative platform that leverages AI technology to accelerate the music and lyric generation process. It's the ultimate assistant for crafting both lyrics and melodies, tailored to your unique style and needs.";
const h1 = "Create music and lyrics effortlessly with AI Generator.";
const h2 = "Transform your songwriting with our advanced AI music and lyric generator.";
const sectionHeader = "Your AI-Powered Creation Hub";
const featureTitle1 = "Boundless musical creativity";
const featureTitle2 = "AI-driven lyric mastery";
const featureTitle3 = "Adaptive music composition";
const featureTitle4 = "Cross-genre flexibility";
const featureParagraph1 = "Imagine accessing an infinite well of musical inspiration. Our AI generator weaves unique melodies and beats, allowing you to explore uncharted sonic territories.";
const featureParagraph2 = "With a keen understanding of language and emotion, our AI lyric generator crafts compelling lyrics that resonate. It's your creative partner, ready to translate thoughts into words.";
const featureParagraph3 = "The platform's sophisticated AI adapts to your preferred musical styles, crafting compositions that feel personal and professional. From symphonies to beats, it's all at your fingertips.";
const featureParagraph4 = "Whether you're penning a country ballad or producing a techno track, our AI generator is genre-agnostic, offering a wide variety of musical styles to ignite your creativity.";
const faq1 = "Can the AI generator work in any genre?";
const faq2 = "How personalized can the generated music and lyrics get?";
const faq3 = "What if I only need music or lyrics, not both?";
const faq4 = "Can I adjust the complexity of the music or lyrics?";
const faqa1 = "Absolutely! Our AI generator is designed to function across all musical genres, providing you with a vast landscape of creative possibilities.";
const faqa2 = "The AI is capable of tailoring the generated content based on your inputs, ensuring that both music and lyrics align with your vision and style.";
const faqa3 = "Whether you're looking for just the right lyrics or the perfect melody, our platform allows you to customize your creative journey. You have the freedom to use either component independently.";
const faqa4a = "Yes, you can control the complexity level of the generated content. From simple tunes and verses to complex compositions and poetic lyrics, the AI adapts to your creative demands.";
const faqa4b = "Additionally, intuitive controls and feedback loops ensure that the AI's output aligns with what you envision, making it a responsive and powerful tool in your songwriting process.";
const quote1 = "The AI generator has revolutionized how I approach songwriting. It's like having a co-writer who never runs out of ideas.";
const quote2 = "Using the AI to generate lyrics and music has been an incredible boost to my creativity. Highly recommend to any artist!";
const quote3 = "The flexibility and quality of the AI-generated content are astounding. It's an indispensable tool for modern musicians.";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}

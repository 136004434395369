import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function MusicLab() {
const title = "Music Lab";
const keywords = "music lab, AI music generator, lyric generator, music creation, music production, music making software, music composition tool, music lab website, music and lyrics, create music, generate music, song crafting";
const description = "Create music and lyrics effortlessly with our AI-powered Music Lab";
const longDescription = "Music Lab is an innovative platform designed to fuse the capabilities of AI in generating both music and lyrics. It serves as your all-in-one music and lyric creation assistant, tailored to meet your diverse songwriting demands and preferences!";
const h1 = "Unleash Your Music and Lyric Creativity with Music Lab’s AI";
const h2 = "Transform your music creation journey with our AI-driven music and lyric generator.";
const sectionHeader = "Your Premier Music Creation Studio";
const featureTitle1 = "AI-Powered Music Generation";
const featureTitle2 = "Effortless Lyric Composition";
const featureTitle3 = "Dynamic Music and Lyric Editing";
const featureTitle4 = "Versatility in Music Genres and Languages";
const featureParagraph1 = "Dive into an ocean of musical possibilities with our AI which crafts unique melodies and beats, offering endless inspiration for your music creation.";
const featureParagraph2 = "Whether you’re penning your next hit or exploring songwriting, our lyric generator provides the words to match your melody or kickstarts your process with verse ideas.";
const featureParagraph3 = "Refine your music and lyrics with dynamic editing features. Music Lab allows for seamless alterations, ensuring your final piece perfectly aligns with your vision.";
const featureParagraph4 = "Our platform supports a multitude of genres and over 100 languages, enabling you to explore global music styles and write lyrics in your language of choice.";
const faq1 = "Can I use the generated music commercially?";
const faq2 = "Is Music Lab suitable for beginners?";
const faq3 = "How does Music Lab support my music creation process?";
const faq4 = "Can I collaborate with others using Music Lab?";
const faqa1 = "Absolutely! The content created on Music Lab is for you to use as you please, including for commercial purposes, all royalty-free.";
const faqa2 = "Yes, Music Lab is designed to cater to all levels, from beginners to seasoned musicians, offering an intuitive interface and supportive tools to unleash your creativity.";
const faqa3 = "Music Lab provides an endless stream of music and lyric suggestions that adapt to your style and preference, effectively combating creative blocks and enhancing your songwriting sessions.";
const faqa4a = "With a wealth of creation tools at your disposal, Music Lab allows you to experiment freely, offering a vast library of instruments, genres, and effects to inspire and enhance your music.";
const faqa4b = "Indeed, Music Lab encourages collaboration, allowing multiple creators to work on the same project in real-time, perfect for bands, songwriting duos, or collaborative projects.";
const quote1 = "Music Lab has revolutionized the way I approach songwriting—I can't imagine going back.";
const quote2 = "The perfect tool for anyone in the music industry looking to streamline their creative process.";
const quote3 = "From lyrics to full compositions, Music Lab has provided an unparalleled level of support and inspiration.";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}

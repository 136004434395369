import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function CréateurDeMusique() {
const title = "Créateur De Musique";
const keywords = "créateur de musique, générer des paroles, générer de la musique, écriture de chansons, assistant d'écriture de chansons, application d'écriture de chansons, plateforme d'écriture de chansons, logiciel d'écriture de chansons, outil d'écriture de chansons, outils d'écriture de chansons, site web d'écriture de chansons, comment écrire une chanson";
const description = "Apprenez à créer des chansons avec un créateur de musique AI";
const longDescription = "Lyrical Labs est une plateforme de création de chansons qui utilise l'IA pour vous aider à écrire des paroles et à composer de la musique plus rapidement. C'est votre assistant personnel pour la création de chansons, adapté à vos goûts et besoins personnels en matière d'écriture de chansons!";
const h1 = "Apprenez à créer des chansons avec le pouvoir de l'IA.";
const h2 = "Révolutionnez votre écriture de chansons et obtenez une inspiration infinie avec notre créateur de musique alimenté par l'IA.";
const sectionHeader = "Le compagnon ultime pour l'écriture de chansons";
const featureTitle1 = "Créativité sans limites";
const featureTitle2 = "Votre assistant personnel pour l'écriture de chansons";
const featureTitle3 = "Dictionnaire intelligent intégré";
const featureTitle4 = "Écrire dans n'importe quelle langue";
const featureParagraph1 = "Une inspiration infinie pour l'écriture de chansons vous attend avec un approvisionnement incessant d'idées originales et de mélodies. L'IA peut écrire sur n'importe quel sujet et générer de la musique de n'importe quel genre";
const featureParagraph2 = "Collaborez avec notre partenaire de création de chansons IA pour composer la chanson parfaite. Une fois que vous avez les paroles et la musique, vous pouvez donner votre avis en parlant à l'IA pour concrétiser votre vision musicale.";
const featureParagraph3 = "La plateforme est équipée d'un dictionnaire intelligent intégré qui vous aide à trouver toutes sortes de mots associés pour vous inspirer et vous aider à finaliser vos paroles.";
const featureParagraph4 = "Avec plus de 100+ langues au choix, vous pouvez apprendre à écrire des paroles de chanson dans n'importe quelle langue!";
const faq1 = "Est-ce que je garde les droits d'auteur?";
const faq2 = "Le but de la plateforme est-il d'écrire complètement mes chansons pour moi?";
const faq3 = "Comment la plateforme m'aide-t-elle à surmonter le blocage de l'écrivain?";
const faq4 = "Comment la plateforme aide-t-elle à stimuler mon processus d'écriture de chansons?";
const faqa1 = "Oui! Lyrical Labs est 100% libre de droits, vous conservez donc tous les droits sur la musique et les paroles que vous créez sur cette plateforme.";
const faqa2 = "Non, le but de notre plateforme est de vous fournir une source d'inspiration infinie et de vous aider à surmonter le blocage de l'écrivain. Bien que l'IA soit capable de générer des ensembles complets de paroles et de mélodies musicales, nous vous encourageons à les utiliser comme point de départ et à ajouter votre touche personnelle. L'IA est là pour aider et inspirer, et non pour remplacer votre propre créativité.";
const faqa3 = "Le blocage de l'écrivain peut être une expérience frustrante et démoralisante pour tout créateur de chansons. Mais avec notre plateforme, vous n'aurez plus à vous soucier de manquer d'idées. Entrez simplement votre thème et votre style souhaités, et l'IA vous fournira un flux continu d'idées créatives et originales avec lesquelles travailler. Cela peut aider à démarrer votre processus de création de chansons et vous remettre sur la bonne voie.";
const faqa4a = "Notre plateforme est conçue pour vous fournir un approvisionnement sans fin d'idées et de suggestions basées sur vos préférences stylistiques choisies, vous donnant la liberté d'expérimenter et d'essayer quelque chose de nouveau.";
const faqa4b = "De plus, la plateforme vous permet d'écrire et de modifier les paroles à votre guise, avec un dictionnaire 'Mots Associés' très unique à vos côtés pour vous aider tout au long de votre processus de création de chansons. C'est comme avoir votre propre assistant créatif personnel, adapté à vos propres goûts et besoins en matière de création de chansons!";
const quote1 = "C'est un changement de jeu, cela m'a vraiment aidé à écrire ma première chanson";
const quote2 = "Produit brillant, je le recommanderais à quiconque veut apprendre à écrire une chanson";
const quote3 = "Excellent service client, vraiment cool pour apprendre à écrire une chanson";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}

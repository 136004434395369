import React from 'react';

const testimonials = [
  {
    id: 1,
    name: "Jane Akbulut",
    quote: "This product changed my life! Highly recommended to anyone looking for a similar solution.",
    rating: 5,
    avatar: "/SocialProof-woman.webp",
  },
  {
    id: 2,
    name: "Gavin Beins",
    quote: "Excellent service and amazing quality. Will definitely use again.",
    rating: 4,
    avatar: "/SocialProof-man.webp",
  },
  {
    id: 3,
    name: "Josh Watts",
    quote: "Excellent service and amazing quality. Will definitely use again.",
    rating: 5,
    avatar: "/SocialProof-man2.webp",
  },
  // Add more testimonials as needed
];

const StarRating = ({ rating }) => {
  return (
    <div className="flex">
      {Array.from({ length: 5 }, (_, index) => (
        <span key={index} className={index < rating ? "text-yellow-500" : "text-gray-300"}>
          ★
        </span>
      ))}
    </div>
  );
};

const Testimonial = ({ testimonial }) => {
  return (
    <div className="max-w-sm bg-dark rounded-lg shadow-md lg:max-w-full">
      <div className="p-4">
        <div className="flex-col flex items-center align-middle justify-center">
          <img className="w-10 h-10 rounded-full align-middle justify-center flex items-center" src={testimonial.avatar} alt={`Avatar of ${testimonial.name}`} />
          <div className="text-sm text-center">{testimonial.name}</div>
        </div>
        <div className="mt-2 flex justify-center">
          <StarRating rating={testimonial.rating} />
        </div>
        <p className="mt-2 text-white text-center ">{testimonial.quote}</p>
      </div>
    </div>
  );
};

const SocialProof = ({ quote1, quote2, quote3 }) => {
  testimonials[0].quote = quote1;
  testimonials[1].quote = quote2;
  testimonials[2].quote = quote3;
  return (
    <div className="flex flex-col space-y-4 lg:flex-row lg:space-y-0 lg:space-x-4">
      {testimonials.map(testimonial => (
        <Testimonial key={testimonial.id} testimonial={testimonial} />
      ))}
    </div>
  );
};


export default SocialProof;

import { LockClosedIcon } from '@heroicons/react/20/solid'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import Footer from "../components/Footer";

import useLocalStorage from '../hooks/useLocalStorage';


export default function Signin({ savedEmail, setSavedEmail, user, setUser }) {
  const [email, setEmail] = useState(savedEmail ? savedEmail : '');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isChecked, setIsChecked] = useLocalStorage('isChecked', false);
  const [buttonStatus, setButtonStatus] = useState(true);
  const navigate = useNavigate();

  
  const onSignin = async (e) => {
    e.preventDefault();
    if(!email || !password) {
      setErrorMessage("Please fill out all fields");
      setButtonStatus(true);
      return;
    }
    if (email.length < 3) {
      setErrorMessage("Email must be at least 3 characters");
      setButtonStatus(true);
      return;
    }
    if(!email.includes('@') || !email.includes('.')) {
      setErrorMessage("Please enter a valid email");
      setButtonStatus(true);
      return;
    }
    if (password.length < 8) {
      setErrorMessage("Password must be at least 8 characters");
      setButtonStatus(true);
      return;
    }
    if (password.length > 30) {
      setErrorMessage("Password must be less than 30 characters");
      setButtonStatus(true);
      return;
    }
    if (password.includes('\'')) {
      setErrorMessage("Password cannot contain apostrophes");
      setButtonStatus(true);
      return;
    }
    if (password.includes('\"')) {
      setErrorMessage("Password cannot contain quotation marks");
      setButtonStatus(true);
      return;
    }
    if (password.includes('\\')) {
      setErrorMessage("Password cannot contain backslashes");
      setButtonStatus(true);
      return;
    }
    if (password.includes('`')) {
      setErrorMessage("Password cannot contain backticks");
      setButtonStatus(true);
      return;
    }

    try {
      setButtonStatus(false);
      const response = await fetch("https://frozen-springs-61240.herokuapp.com/login", {
        method: "POST",
        mode: 'cors',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });
      if (response.ok){
        const data = await response.json();
        setErrorMessage('');
        setUser(data);
        navigate('/app');
      } else {
        if (response.status === 400) {
          setErrorMessage("Invalid Credentials");
        } else {
          setErrorMessage("An Error has occurred, please report this in our chatbox");
        }
      }
      } catch (error) {
        console.log(error);
        setErrorMessage(error);
      }
      finally {
        setButtonStatus(true);
        if(isChecked){
          setSavedEmail(email);
        } else{
          setSavedEmail("");
        }
      }
  }

  return (
    <>
      <div className="pt-4 w-full min-h-screen bg-[#101010]">
        <main className="mt-20">
          <div className="w-screen">
          <div className="grid justify-items-center">
            <div className= "w-96 bg-white/5 border border-white/10 rounded-lg shadow p-8">
                <form className="space-y-6" action="#" onSubmit={onSignin} method="POST">
                    <h5 className="text-xl font-medium text-gray-900 dark:text-white">Sign in to your account</h5>
                    <div>
                        <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
                        <input type="email" name="email" id="email" onChange={(e) => {setEmail(e.target.value)}} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-white/5 dark:border-white/10 dark:placeholder-gray-400 dark:text-white" placeholder="name@company.com" value={email} required/>
                    </div>
                    <div>
                        <label for="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your password</label>
                        <input type="password" name="password" id="password" placeholder="••••••••" onChange={(e) => {setPassword(e.target.value)}} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-white/5 dark:border-white/10 dark:placeholder-gray-400 dark:text-white" required/>
                    </div>

                    <div className="flex items-start">
                      <div className="flex items-start">
                        <div className="flex items-center h-5">
                          {isChecked ? <input id="remember" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800" onChange={(e) => {setIsChecked(e.target.checked)}} checked/> :
                          <input id="remember" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800" onChange={(e) => {setIsChecked(e.target.checked)}}/>
                          }
                        </div>
                        <label for="remember" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Remember me</label>
                      </div>
                      <a href="/forgot-password-email" className="ml-auto text-sm text-indigo-700 hover:underline dark:text-indigo-500">Lost Password?</a>
                    </div>
                    <button type="submit" disabled={!buttonStatus} className="w-full text-white bg-indigo-700 hover:bg-indigo-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Sign In</button>
                    { errorMessage ? <div><span style={{color: "white"}}>{errorMessage}</span></div> : <div></div>}
                    <div className="text-sm font-medium text-gray-300">
                        Don't have an account? <a href="register" className="text-indigo-500 hover:underline">Register here</a>
                    </div>
                </form>
            </div>
          </div>    

          </div>


        </main>
      </div>
      <Footer/> 
    </>
  )
}

import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function CréateurDeChansonsEnLigne() {
const title = "Créateur De Chansons En Ligne";
const keywords = "créateur de chansons en ligne, générateur de musique, écriture de chansons, assistant d'écriture de chansons, plateforme d'écriture de chansons, logiciel d'écriture de chansons, outil d'écriture de chansons, outils d'écriture de chansons, site web d'écriture de chansons, comment écrire une chanson";
const description = "Apprenez à écrire des chansons avec une IA";
const longDescription = "Lyrical Labs est une plateforme de création de chansons qui utilise l'IA pour vous aider à composer des paroles et des mélodies de manière plus rapide. C'est votre assistant personnel en écriture de chansons, adapté à vos propres goûts et besoins de création !";
const h1 = "Apprenez à composer une chanson en utilisant le pouvoir de l'IA";
const h2 = "Révolutionnez votre écriture de chansons et obtenez une inspiration infinie avec nos paroles générées par IA.";
const sectionHeader = "Votre compagnon ultime dans l'écriture de chansons";
const featureTitle1 = "Créativité illimitée";
const featureTitle2 = "Votre assistant personnel d'écriture de chansons";
const featureTitle3 = "Dictionnaire intelligent intégré";
const featureTitle4 = "Écrivez dans n'importe quelle langue";
const featureParagraph1 = "Une inspiration sans fin pour l'écriture de chansons vous attend, avec un approvisionnement continu d'idées originales et de mélodies. L'IA peut écrire sur n'importe quel sujet et générer de la musique de tout genre.";
const featureParagraph2 = "Collaborez avec notre partenaire de création de chansons IA pour composer la chanson parfaite. Une fois que vous avez les paroles et la musique, vous pouvez donner votre avis en parlant avec l'IA pour concrétiser votre vision musicale.";
const featureParagraph3 = "La plateforme est équipée d'un dictionnaire intelligent intégré qui vous aide à trouver toutes sortes de mots associés pour vous inspirer et vous aider à terminer vos paroles.";
const featureParagraph4 = "Avec plus de 100 langues au choix, vous pouvez apprendre à écrire des paroles de chansons dans n'importe quelle langue !";
const faq1 = "Est-ce que je conserve les droits d'auteur ?";
const faq2 = "Le but de la plateforme est-il de rédiger mes chansons pour moi ?";
const faq3 = "Comment la plateforme m'aide-t-elle à surmonter le blocage de l'écrivain ?";
const faq4 = "Comment la plateforme contribue-t-elle à améliorer mon processus d'écriture de chansons ?";
const faqa1 = "Oui ! Lyrical Labs est 100% libre de droits, vous conservez tous les droits sur la musique et les paroles que vous créez sur cette plateforme.";
const faqa2 = "Non, l'objectif de notre plateforme est de vous fournir un flux inépuisable d'inspiration et de vous aider à surmonter le blocage de l'écrivain. Bien que l'IA soit capable de générer des ensembles complets de paroles et de mélodies musicales, nous vous encourageons à les utiliser comme point de départ et à y ajouter votre touche personnelle. L'IA est ici pour assister et inspirer, et non pour remplacer votre propre créativité.";
const faqa3 = "Le blocage de l'écrivain peut être une expérience frustrante et démoralisante pour tout auteur de chansons. Mais avec notre plateforme, vous n'aurez jamais à vous soucier de manquer d'idées. Il suffit d'entrer votre thème et style désirés, et l'IA vous fournira un flux continu d'idées créatives et originales avec lesquelles travailler. Cela peut aider à lancer votre processus de création de chansons et vous remettre sur les rails.";
const faqa4a = "Notre plateforme est conçue pour vous fournir un flux inépuisable d'idées et de suggestions basées sur vos préférences stylistiques choisies, vous donnant la liberté d'expérimenter et d'essayer quelque chose de nouveau.";
const faqa4b = "De plus, la plateforme vous permet d'écrire et de modifier les paroles à votre guise, avec un dictionnaire 'Mots Associés' très unique à vos côtés pour vous assister tout au long de votre processus d'écriture de chansons. C'est comme avoir votre propre assistant créatif personnel, adapté à vos propres goûts et besoins en écriture de chansons !";
const quote1 = "C'est un véritable changement de jeu, m'a vraiment aidé à écrire ma première chanson";
const quote2 = "Produit brillant, je le recommanderais à quiconque souhaite apprendre à écrire une chanson";
const quote3 = "Service client excellent, une façon vraiment cool d'apprendre à écrire une chanson";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}

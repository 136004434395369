import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function GénérateurDeChansonsAléatoires() {
const title = "Générateur De Chansons Aléatoires";
const keywords = "générateur de chansons aléatoires, générateur de paroles, générateur de musique, composition de chansons, assistant de composition, application de composition, plateforme de composition, logiciel de composition, outil de composition, outils de composition, site web de composition, comment composer une chanson";
const description = "Apprenez à composer une chanson avec l'IA";
const longDescription = "Lyrical Labs est une plateforme de composition qui utilise l'IA pour vous aider à écrire des paroles et des mélodies musicales plus rapidement. C'est votre assistant personnel de composition, adapté à vos goûts personnels et à vos besoins de composition!";
const h1 = "Apprenez à composer des chansons en utilisant le pouvoir de l'IA.";
const h2 = "Révolutionnez votre processus de composition et obtenez une inspiration infinie avec nos paroles générées par l'IA.";
const sectionHeader = "Votre compagnon ultime de composition";
const featureTitle1 = "Créativité sans limites";
const featureTitle2 = "Votre assistant personnel de composition";
const featureTitle3 = "Dictionnaire intelligent intégré";
const featureTitle4 = "Écrivez dans n'importe quelle langue";
const featureParagraph1 = "Une inspiration infinie pour la composition vous attend avec un approvisionnement continu d'idées originales et de mélodies. L'IA peut écrire sur n'importe quel sujet et générer de la musique de tous genres";
const featureParagraph2 = "Collaborez avec notre partenaire de composition IA pour créer la chanson parfaite. Une fois que vous avez les paroles et la musique, vous pouvez fournir des retours en parlant à l'IA pour concrétiser votre vision musicale.";
const featureParagraph3 = "La plateforme est équipée d'un dictionnaire intelligent intégré qui vous aide à trouver toutes sortes de mots associés pour fournir de l'inspiration et vous aider à compléter vos paroles.";
const featureParagraph4 = "Avec plus de 100+ langues au choix, vous pouvez apprendre à écrire des paroles de chanson dans n'importe quelle langue!";
const faq1 = "Est-ce que je garde les droits d'auteur?";
const faq2 = "Est-ce que l'objectif de la plateforme est de composer complètement mes chansons pour moi?";
const faq3 = "Comment la plateforme m'aide-t-elle à surmonter le blocage de l'écrivain?";
const faq4 = "Comment la plateforme augmente-t-elle mon processus de composition?";
const faqa1 = "Oui! Lyrical Labs est 100% libre de droits, vous conservez donc tous les droits sur la musique et les paroles que vous créez sur cette plateforme.";
const faqa2 = "Non, l'objectif de notre plateforme est de vous fournir une source inépuisable d'inspiration et de vous aider à surmonter le blocage de l'écrivain. Bien que l'IA soit capable de générer des ensembles complets de paroles et de mélodies musicales, nous vous encourageons à les utiliser comme point de départ et à y ajouter votre touche personnelle. L'IA est ici pour assister et inspirer, pas pour remplacer votre propre créativité.";
const faqa3 = "Le blocage de l'écrivain peut être une expérience frustrante et démoralisante pour tout compositeur. Mais avec notre plateforme, vous n'aurez plus jamais à vous soucier de manquer d'idées. Entrez simplement votre thème et style désirés, et l'IA vous fournira un flux continu d'idées créatives et originales avec lesquelles travailler. Cela peut aider à démarrer votre processus de composition et vous remettre sur les rails.";
const faqa4a = "Notre plateforme est conçue pour vous fournir un flux inépuisable d'idées et de suggestions basées sur vos préférences stylistiques choisies, vous donnant la liberté d'expérimenter et d'essayer quelque chose de nouveau.";
const faqa4b = "De plus, la plateforme vous permet d'écrire et d'éditer les paroles à votre guise, avec un dictionnaire 'Mots Associés' hautement unique à vos côtés pour vous assister tout au long de votre processus de composition. C'est comme avoir votre propre assistant créatif personnel, adapté à vos goûts et besoins de composition!";
const quote1 = "C'est un changement de jeu, cela m'a vraiment aidé à écrire ma première chanson";
const quote2 = "Produit brillant, je recommanderais à quiconque veut apprendre à composer une chanson";
const quote3 = "Service client excellent, manière vraiment cool d'apprendre à écrire une chanson";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}

import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function MusiqueTechno() {
const title = "Musique Techno";
const keywords = "musique techno, générateur de musique, générateur de paroles, composition de chansons, assistant d'écriture de chansons, application d'écriture de chansons, plateforme d'écriture de chansons, logiciel d'écriture de chansons, outil d'écriture de chansons, site web d'écriture de chansons, comment écrire une musique techno";
const description = "Apprenez à créer des paroles et mélodies de musique techno avec l'IA";
const longDescription = "Lyrical Labs est une plateforme de composition de chansons qui utilise l'IA pour vous aider à écrire rapidement des paroles et des mélodies de musique techno. C'est votre assistant personnel de composition, adapté à vos goûts personnels et à vos besoins en matière de création musicale !";
const h1 = "Apprenez à créer de la musique techno en utilisant la puissance de l'IA.";
const h2 = "Révolutionnez votre écriture de musique techno et obtenez une inspiration infinie avec nos paroles générées par l'IA.";
const sectionHeader = "Votre Compagnon Ultime de Composition Musique Techno";
const featureTitle1 = "Créativité sans limites";
const featureTitle2 = "Votre assistant personnel de composition";
const featureTitle3 = "Dictionnaire intelligent intégré";
const featureTitle4 = "Composez dans n'importe quelle langue";
const featureParagraph1 = "Une inspiration sans fin pour la composition de musique techno vous attend avec un approvisionnement continu en idées originales et mélodies. L'IA peut écrire sur n'importe quel thème et générer de la musique de tous genres.";
const featureParagraph2 = "Collaborez avec notre partenaire de composition IA pour créer la musique techno parfaite. Une fois que vous avez les paroles et la musique, vous pouvez donner votre avis en parlant à l'IA pour concrétiser votre vision musicale.";
const featureParagraph3 = "La plateforme est équipée d'un dictionnaire intelligent intégré qui vous aide à trouver toutes sortes de mots associés pour vous inspirer et vous aider à finaliser vos paroles.";
const featureParagraph4 = "Avec plus de 100+ langues au choix, vous pouvez apprendre à écrire des paroles de musique techno dans n'importe quelle langue !";
const faq1 = "Garde-je les droits d'auteur ?";
const faq2 = "L'objectif de la plateforme est-il d'écrire totalement mes chansons pour moi ?";
const faq3 = "Comment la plateforme m'aide-t-elle à surmonter le blocage de l'écrivain ?";
const faq4 = "Comment la plateforme améliore-t-elle mon processus de composition ?";
const faqa1 = "Oui ! Lyrical Labs est 100% libre de droits, vous conservez donc tous les droits sur la musique et les paroles que vous créez sur cette plateforme.";
const faqa2 = "Non, l'objectif de notre plateforme est de vous fournir une source inépuisable d'inspiration et d'aider à surmonter le blocage de l'écrivain. Bien que l'IA soit capable de générer des ensembles complets de paroles et des mélodies musicales, nous vous encourageons à les utiliser comme point de départ et à ajouter votre touche personnelle. L'IA est là pour aider et inspirer, et non pour remplacer votre créativité propre.";
const faqa3 = "Le blocage de l'écrivain peut être une expérience frustrante et démoralisante pour tout compositeur. Mais avec notre plateforme, vous n'aurez jamais à vous inquiéter de manquer d'idées. Il suffit d'entrer votre thème et style désirés, et l'IA vous fournira un flux continu d'idées créatives et originales à utiliser. Cela peut aider à relancer votre processus de composition et vous remettre sur les rails.";
const faqa4a = "Notre plateforme est conçue pour vous fournir une source inépuisable d'idées et de suggestions basées sur vos préférences stylistiques choisies, vous donnant la liberté d'expérimenter et d'essayer quelque chose de nouveau.";
const faqa4b = "De plus, la plateforme vous permet d'écrire et d'éditer les paroles à votre guise, avec un dictionnaire 'Mots Associés' très unique à vos côtés pour vous assister tout au long de votre processus de composition. C'est comme avoir votre propre assistant créatif personnel, adapté à vos goûts et besoins en matière de création musicale !";
const quote1 = "C'est révolutionnaire, ça m'a vraiment aidé à écrire ma première chanson techno";
const quote2 = "Produit brillant, je le recommanderais à quiconque veut apprendre à créer de la musique techno";
const quote3 = "Excellent service client, vraiment cool pour apprendre à créer de la musique techno";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}

import React from 'react';
import { Sidebar, Menu, MenuItem, sidebarClasses } from 'react-pro-sidebar';
import CreateIcon from '@mui/icons-material/Create';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import CallIcon from '@mui/icons-material/Call';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link, useLocation } from 'react-router-dom';
import { Popover, Transition } from '@headlessui/react'
import { Tooltip } from "@mui/material";

const theme = createTheme({
    palette: {
        primary: {
            main: 'rgba(255, 255, 255, 0.8)',
        },
    },
});

const CustomSidebar = ({ user }) => {
    const location = useLocation();
    const { pathname } = location;
    const isExcludedPage = pathname === '/app' || pathname === '/musicplayer' || pathname === '/account';

    if (!isExcludedPage) {
        return null;
    }
    return (
        <Popover className="fixed min-h-screen left-0 bg-[#1e1e1e] font-assistant on-scroll z-10 hidden md:block">
            <Sidebar collapsed='true' backgroundColor='rgb(30, 30, 30)' style={{ height: "100vh", backgroundColor: 'transparent' }}>
                <div className="flex justify-start mb-20">
                </div>
                <Menu
                    menuItemStyles={{
                        button: ({ level, active }) => {
                            if (level === 0 || level === 1) {
                                return {
                                    backgroundColor: active ? undefined : undefined,
                                    color: active ? '#5d55e7' : '#404040',
                                    "&:hover": {
                                        backgroundColor: undefined,
                                        color: undefined,
                                    }
                                }
                            }
                        },
                    }}
                >
                    <div>
                        <div className='flex flex-col space-y-4'>
                            <Tooltip
                                title={<span style={{ whiteSpace: 'pre-line', fontSize: '18px' }}>{"Lyric Generator"}</span>}
                                placement="right">
                                <MenuItem icon={<CreateIcon />} href='/app' active={location.pathname === '/app'}></MenuItem>

                            </Tooltip>

                            <Tooltip
                                title={<span style={{ whiteSpace: 'pre-line', fontSize: '18px' }}>{"Music Generator (New)"}</span>}
                                placement="right">
                                <MenuItem icon={<LibraryMusicIcon />} href='/musicplayer' active={location.pathname === '/musicplayer'}></MenuItem>

                            </Tooltip>

                        </div>
                        <div className='flex flex-col space-y-4 mt-24'>
                    { user ?
                        <Tooltip
                            title={<span style={{ whiteSpace: 'pre-line', fontSize: '18px' }}>{"Account"}</span>}
                            placement="right">
                            <MenuItem icon={<AccountBoxIcon />} href='/account' active={location.pathname === '/account'}></MenuItem>

                        </Tooltip>
                        :
                        <Tooltip
                        title={<span style={{ whiteSpace: 'pre-line', fontSize: '18px' }}>{"Register"}</span>}
                        placement="right">
                        <MenuItem icon={<AccountBoxIcon />} href='/register' active={location.pathname === '/register'}></MenuItem>

                    </Tooltip>

                    }

                        <Tooltip
                            title={<span style={{ whiteSpace: 'pre-line', fontSize: '18px' }}>{"Contact Us"}</span>}
                            placement="right">
                            <MenuItem icon={<CallIcon />} href='https://tawk.to/chat/63e832a5c2f1ac1e2032c4c4/1gp1gbmar' active={location.pathname === '/contact'}></MenuItem>

                        </Tooltip>

                        <Tooltip
                            title={<span style={{ whiteSpace: 'pre-line', fontSize: '18px' }}>{"Upgrade"}</span>}
                            placement="right">
                            { user ? 
                            <MenuItem icon={<ShoppingCartIcon />} href='/prices' active={location.pathname === '/prices'}></MenuItem>
                            :
                            <MenuItem icon={<ShoppingCartIcon />} href='/prices' active={location.pathname === '/prices'}></MenuItem>
                            }
                        </Tooltip>
                        </div>
                    </div>
                        



                </Menu>
            </Sidebar>
        </Popover>


    );
};

export default CustomSidebar;

import { Fragment, useState } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { LockClosedIcon } from '@heroicons/react/20/solid'


import {
  Bars3Icon,
  ChartBarIcon,
  PlayIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import useLocalStorage from '../hooks/useLocalStorage';

const solutions = [
  {
    name: 'Lyric Assistant',
    description: 'Write better lyrics with our AI-powered lyric assistant.',
    href: '/',
    icon: ChartBarIcon,
  },
]
const callsToAction = [
  { name: 'Watch Demo', href: '#', icon: PlayIcon },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Navbar({ savedEmail, setSavedEmail, setUser, user }) {
  const logout = () => {
    localStorage.removeItem("user");
  };
  const navigate = useNavigate();
  const [email, setEmail] = useState(savedEmail ? savedEmail : '');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isChecked, setIsChecked] = useLocalStorage('isCheckedNavbar', false);
  const [buttonStatus, setButtonStatus] = useState(true);
  const onSignin = async (e) => {
    e.preventDefault();
    try {
      setButtonStatus(false);
      const response = await fetch("https://frozen-springs-61240.herokuapp.com/login", {
        method: "POST",
        mode: 'cors',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });
      if (response.ok){
        const data = await response.json();
        setErrorMessage('');
        setUser(data);
        navigate('/app');
      } else {
        if (response.status === 400) {
          setErrorMessage("Invalid Credentials");
        } else {
          setErrorMessage("An Error has occurred, please report this in our chatbox");
        }
      }
      } catch (error) {
        console.log(error);
        setErrorMessage(error);
      }
      finally {
        setButtonStatus(true);
        if(isChecked){
          setSavedEmail(email);
        } else{
          setSavedEmail("")
        }
      }
  }

  return (
    <Popover className="fixed left-0 right-0 bg-[#121212] font-assistant on-scroll z-20 border border-white/5 border-x-0 border-t-0 border-b-1">
      <Helmet>
        {/* <link rel="preconnect" href="https://fonts.googleapis.com"/>
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
        <link href="https://fonts.googleapis.com/css2?family=Assistant:wght@300;600;700&family=Press+Start+2P&display=swap" rel="stylesheet"/>       */}
      </Helmet>
      <div className="">
        <div className="flex items-center justify-between md:justify-start md:space-x-10">
          <div className="flex justify-start">
            <a href="/">
              <span className="sr-only">Lyrical Labs</span>
              <img
                className="h-14 pl-3 py-1"
                src="lyrical labs logo 2.png"
                alt=""
              />
            </a>
          </div>
          <div className="-my-2 -mr-2 md:hidden">
            <Popover.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset">
              <span className="sr-only">Open menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          
          <div className="hidden items-center pr-3 space-x-8 justify-end md:flex md:flex-1 lg:w-0">

            <a href={user ? "/prices" : "/preloginprices"} className="text-base font-semibold text-white hover:text-indigo-300">
              Plans
            </a>

            <a href="https://tawk.to/chat/63e832a5c2f1ac1e2032c4c4/1gp1gbmar" target="_blank" className="text-base font-semibold text-white hover:text-indigo-300">
              Contact
            </a>

            <a href="/app" className="text-base font-semibold text-white hover:text-indigo-300">
              Music Lab
            </a>

          { user ?
            <><a href="/account" className="text-base font-semibold text-white hover:text-indigo-300">
              Account
            </a> <a
              href="/"
              onClick={logout}
              className="inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-white/30 px-2 py-1 text-base font-semibold text-white shadow-sm hover:bg-white/10 hover:text-indigo-300"
              > 
              Logout
            </a>
            </>
            :
            <>
              <a href="/register" className="text-base font-semibold text-white hover:text-indigo-300">
                Sign Up
              </a>
              <Popover className="relative justify-center md:flex">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open ? 'bg-white/10 text-indigo-300' : 'text-white bg-white/20',
                      'inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-white/10 px-2 py-1 text-base font-semibold text-indigo-300 shadow-sm'
                    )}
                  >
                    <span>Sign In</span>
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute right-0 mt-10 w-72 transform sm:px-0">
                    <div className="relative bg-[#292929] px-5 py-2 w-100 overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1">
                      <div className="content-between py-1">
                        <div className="w-full max-w-md space-y-2">
                          <div>
                            <h2 className="mt-1 text-left text-white font-bold tracking-tight">
                              Sign in to your account
                            </h2>
                          </div>
                          <form className="mt-3 space-y-6" action="#" method="POST" onSubmit={onSignin}>
                            <input type="hidden" name="remember" defaultValue="true" />
                            <div className="-space-y-px rounded-md shadow-sm">
                            <div>
                        <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                        <input type="email" name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-white/5 dark:border-white/10 dark:placeholder-gray-400 dark:text-white" placeholder="name@company.com" value={email} onChange={(e) => {setEmail(e.target.value);}} required/>
                    </div>
                    <div>
                        <label for="password" className="block mb-2 mt-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                        <input type="password" name="password" id="password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-white/5 dark:border-white/10 dark:placeholder-gray-400 dark:text-white" onChange={(e) => {setPassword(e.target.value);}} required/>
                    </div>
                            </div>

                            <div className="flex items-center justify-between">
                              <div className="flex items-center">
                                {isChecked ? 
                                <input
                                  id="remember-me"
                                  name="remember-me"
                                  type="checkbox"
                                  className="h-3 w-3 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                  onChange={(e) => {setIsChecked(e.target.checked)}}
                                  checked
                                />
                                :
                                <input
                                  id="remember-me"
                                  name="remember-me"
                                  type="checkbox"
                                  className="h-3 w-3 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                  onChange={(e) => {setIsChecked(e.target.checked)}}
                                />
                                }
                                <label htmlFor="remember-me" className="ml-2 block text-xs text-white/60">
                                  Remember me
                                </label>
                              </div>

                              <div className="text-xs">
                                <a href="/forgot-password-email" className="text-indigo-400 hover:text-indigo-500">
                                  Forgot your password?
                                </a>
                              </div>
                            </div>

                            <div>
                              <button
                                disabled={!buttonStatus}
                                type="submit"
                                className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-bold text-white/80 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              >
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                  <LockClosedIcon className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true" />
                                </span>
                                Sign In
                              </button>
                            </div>
                          <div style={{color: 'red'}}>{errorMessage}</div>
                          </form>
                          <div className="text-sm font-medium text-gray-300">
                            Don't have an account? <a href="register" className="text-indigo-400 hover:underline hover:text-indigo-500">Register here</a>
                         </div>
                        </div>
                      </div>
                    </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover> 
            </>
            
          }

          </div>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel focus className="absolute bg-neutral-900 inset-x-0 top-0 origin-top-right transform transition md:hidden">
          <div className="">
            <div className="">
              <div className="flex items-center justify-between bg-[#121212] border border-white/5 border-x-0 border-t-0 border-b-1">
                <div>
                  <img
                    className="h-14 pl-3 py-1"
                    src="lyrical labs logo 2.png"
                    alt=""
                  />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset">
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="">
              </div>
              <div className="grid grid-cols-1 gap-y-0 gap-x-8">
              <a href="/app" className="text-base text-center py-2 font-bold text-white hover:text-indigo-300 border border-white/5 border-x-0 border-t-0 border-b-1">
                  Lyric Generator
                </a>
                <a href="/musicplayer" className="text-base text-center py-2 font-bold text-white hover:text-indigo-300 border border-white/5 border-x-0 border-t-0 border-b-1">
                  Music Generator
                </a>
                { user ? 
                <a href="/prices" className="text-base text-center py-2 font-bold text-white hover:text-indigo-300 border border-white/5 border-x-0 border-t-0 border-b-1">
                  Plans
                </a>
                :
                <a href="/preloginprices" className="text-base text-center py-2 font-bold text-white hover:text-indigo-300 border border-white/5 border-x-0 border-t-0 border-b-1">
                Plans
                </a>
                }
                <a href="https://tawk.to/chat/63e832a5c2f1ac1e2032c4c4/1gp1gbmar" target="_blank" className="text-base text-center py-2 font-bold text-white hover:text-indigo-300 border border-white/5 border-x-0 border-t-0 border-b-1">
                  Contact
                </a>

                <a href="/register" className="text-base text-center py-2 font-bold text-white hover:text-indigo-300 border border-white/5 border-x-0 border-t-0 border-b-1">
                  Register
                </a>
              </div>
            </div>
            <div className="py-4 pb-6 px-5 bg-neutral-900/80">
              <div className="grid grid-cols-1 gap-y-4 gap-x-8">
                { user ?
            <><a href="/account" className="text-base text-center font-bold text-white hover:text-indigo-300">
              Account
              </a>
              <a
              href="/"
              onClick={logout}
              className="inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-white/30 px-2 py-1 text-base font-semibold text-white shadow-sm hover:bg-white/10 hover:text-indigo-300"
              > 
              Logout
            </a>
            </>
            :
            <div className="relative bg-white/5 px-5 py-2 w-100 overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1">
              <div className="content-between py-1">
                <div className="w-full max-w-md space-y-2">
                  <div>
                    <h2 className="mt-1 text-left text-white font-bold tracking-tight">
                      Sign in to your account
                    </h2>
                  </div>
                  <form className="mt-3 space-y-6" action="#" method="POST" onSubmit={onSignin}>
                    <input type="hidden" name="remember" defaultValue="true" />
                    <div className="-space-y-px rounded-md shadow-sm">
                      <div>
                        <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                        <input type="email" name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-white/5 dark:border-white/10 dark:placeholder-gray-400 dark:text-white" placeholder="name@company.com" onChange={(e) => {setEmail(e.target.value);}} required/>
                      </div>
                      <div>
                          <label for="password" className="block mb-2 mt-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                          <input type="password" name="password" id="password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-white/5 dark:border-white/10 dark:placeholder-gray-400 dark:text-white" onChange={(e) => {setPassword(e.target.value);}} required/>
                      </div>
                    </div>
                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <input
                          id="remember-me"
                          name="remember-me"
                          type="checkbox"
                          className="h-3 w-3 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        />
                        <label htmlFor="remember-me" className="ml-2 block text-xs text-white/60">
                          Remember me
                        </label>
                      </div>
                      <div className="text-xs">
                        <a href="/forgot-password-email" className="text-indigo-400 hover:text-indigo-500">
                          Forgot your password?
                        </a>
                      </div>
                    </div>

                  <div>
                    <button
                      disabled={!buttonStatus}
                      type="submit"
                      className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-bold text-white/80 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                        <LockClosedIcon className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true" />
                      </span>
                      Sign In
                    </button>
                  </div>
                  {errorMessage && <div style={{color: 'red'}}>{errorMessage}</div>}
                </form>
                <div className="text-sm font-medium text-gray-300">
                  Don't have an account? <a href="register" className="text-indigo-400 hover:underline hover:text-indigo-500">Register here</a>
               </div>
              </div>
            </div>
          </div> }
              </div>


            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}

import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function ComposeAi() {
const title = "Compose Ai";
const keywords = "compose AI, AI music composer, AI lyric generator, music creation, lyric creation, AI songwriter, music composition software, lyric writing app, music generator platform, AI composition tool, song creation website, compose music with AI";
const description = "Discover the future of music and lyric composition with AI";
const longDescription = "Lyrical Labs brings the revolution in song creation to your fingertips through its state-of-the-art Compose AI technology. It's not just an AI songwriter; it's a complete music and lyric composition powerhouse designed to cater to your unique creative process.";
const h1 = "Master the Art of Music with Compose AI.";
const h2 = "Transform your musical ideas into reality with our innovative AI composer.";
const sectionHeader = "Your Ultimate AI Music and Lyric Creation Studio";
const featureTitle1 = "Dynamic composition capabilities";
const featureTitle2 = "Compose lyrics and music seamlessly";
const featureTitle3 = "Intelligent feedback system";
const featureTitle4 = "Multi-genre music AI composer";
const featureParagraph1 = "With Compose AI, embark on an extraordinary journey of musical discovery. Experience limitless possibilities in music and lyric composition, tailored to the nuances of your creative vision.";
const featureParagraph2 = "Dive into an immersive songwriting experience where lyrics and melodies flow together in perfect harmony. Compose AI serves as your collaborative partner, enabling a seamless blend of words and music.";
const featureParagraph3 = "The platform listens and learns from your input, offering intelligent suggestions and modifications to refine your music and lyrics, ensuring every composition is perfectly aligned with your aspirations.";
const featureParagraph4 = "Whether you're crafting the next pop anthem or exploring the depths of ambient soundscapes, Compose AI's versatile technology adapts to any genre, offering endless inspiration and creativity.";
const faq1 = "Can I create entire songs with Compose AI?";
const faq2 = "How does Compose AI tailor compositions to my style?";
const faq3 = "What if I need inspiration for my compositions?";
const faq4 = "Can Compose AI also help with arranging my music?";
const faqa1 = "Absolutely! Compose AI is designed to support both lyric and music creation, allowing you to produce complete, polished songs ready for the global stage.";
const faqa2 = "Through advanced AI algorithms, Compose AI analyzes your input and past creations to tailor suggestions and compositions that match your unique style and preferences.";
const faqa3 = "Never face a creative block again! Input your theme or mood, and let Compose AI generate a stream of original lyrics and melodies to kickstart or complement your compositions.";
const faqa4a = "Yes, in addition to composition, Compose AI offers guidance on arranging your music, providing suggestions for instrumentals and structure to elevate your song to professional standards.";
const faqa4b = "With Compose AI, you're also equipped with a versatile 'Ideas' feature, acting as your brainstorming partner. It suggests chords, rhythms, and lyrical themes, making sure you're never out of creative ideas.";
const quote1 = "Compose AI has revolutionized how I interact with music. It's like having a co-composer by my side at all times.";
const quote2 = "The seamless integration of lyric and music composition is phenomenal. It's everything a songwriter could wish for!";
const quote3 = "Never thought AI could be this good at understanding my musical needs. Compose AI has proven me wrong.";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}

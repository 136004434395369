import Footer from "../../components/Footer";
import styles from "../Homescreen/Homescreen.module.css";
import { Helmet } from 'react-helmet';
import LandingPageTemplate from "./LandingPageTemplate";


export default function RapLyricGenerator() {

  const title = "Rap Lyric Generator";
  const keywords = "rap lyric generator, rap lyrics generator, songwriting, songwriting assistant, songwriting app, songwriting platform, songwriting software, songwriting tool, songwriting tools, songwriting website, how to write a song, lyric generator";
  const description = "Lyrical Labs Lyric Generator uses AI to generate the perfect lyrics for your rap song.";
  const longDescription = "Lyrical Labs Lyric Generator uses AI to generate the perfect lyrics for your rap song.";
  const h1 = "Learn How to write a rap song using an AI rap Lyric Generator.";
  const h2 = "Revolutionize your songwriting and get infinite inspiration with our AI-powered rap lyric generator.";
  const sectionHeader = "The Ultimate Rappers Companion";
  const featureTitle1 = "Limitless creativity";
  const featureTitle2 = "Your personal rapping assistant";
  const featureTitle3 = "In-built smart dictionary";
  const featureTitle4 = "Write in any language";
  const featureParagraph1 = "Endless songwriting inspiration awaits with a never-ending supply of original ideas. The AI rap lyric generator can write about any topic, and can teach you how to write rap songs like a pro.";
  const featureParagraph2 = "Collaborate with our AI songwriting partner to craft the perfect raps. Once you have lyrics, you can provide feedback by talking to the AI to bring your musical vision to life.";
  const featureParagraph3 = "The platform comes equipped with an in-built smart dictionary that helps you find all sorts of related words to provide inspiration and help you finish your lyrics.";
  const featureParagraph4 = "With over 100+ languages to choose from, you can generate songs in any language!";
  const faq1 = "Do I retain copyright to my creations?";
  const faq2 = "Is the purpose of this platform to fully generate my rap for me?";
  const faq3 = "How can this service help me break through creative blocks?";
  const faq4 = "How does this platform help with the songwriting process?";
  const faqa1 = "Absolutely! Lyrical Labs ensures that you maintain 100% ownership of the copyright for both lyrics and beats produced on this platform.";
  const faqa2 = "While our AI is capable of generating comprehensive rap lyrics and beats, the essence of our platform is to spark creativity and act as a catalyst. We encourage you to inject your individuality into the AI-generated content for a truly unique creation.";
  const faqa3 = "Facing a creative roadblock is common in rap writing. BeatCraft provides an endless flow of ideas and styles, enabling you to easily find inspiration and direction for your next rap masterpiece.";
  const faqa4a = "Our platform is designed to provide you with an endless supply of ideas and suggestions based on your chosen stylistic preferences, giving you the freedom to experiment and try something new.";
  const faqa4b = "Additionally, the platform allows you to write and edit the lyrics at your own will, with a highly unique 'Related Words' dictionary by your side to assist you throughout your songwriting process. It's like your own personal creative assistant, fit to your own personal tastes and songwriting needs!";
  const quote1 = "The rap generator transformed my writing process; I've never been more productive.";
  const quote2 = "Outstanding tool for anyone aiming to boost their songwriting creativity";
  const quote3 = "Top-notch support from the team, a truly revolutionary way to compose music";


  return (
    <LandingPageTemplate
    title={title}
    keywords={keywords}
    description={description}
    longDescription={longDescription}
    h1={h1}
    h2={h2}
    sectionHeader={sectionHeader}
    featureTitle1={featureTitle1}
    featureTitle2={featureTitle2}
    featureTitle3={featureTitle3}
    featureTitle4={featureTitle4}
    featureParagraph1={featureParagraph1}
    featureParagraph2={featureParagraph2}
    featureParagraph3={featureParagraph3}
    featureParagraph4={featureParagraph4}
    faq1={faq1}
    faq2={faq2}
    faq3={faq3}
    faq4={faq4}
    faqa1={faqa1}
    faqa2={faqa2}
    faqa3={faqa3}
    faqa4a={faqa4a}
    faqa4b={faqa4b}
    quote1={quote1}
    quote2={quote2}
    quote3={quote3}
    />
  )
}

import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function WhatIsSongwriting() {
const title = "What Is Songwriting";
const keywords = "what is songwriting, lyric generator, music generator, song creation, music composition, songwriting guide, understanding songwriting, songwriting assistant, songwriting app, songwriting platform, songwriting software, songwriting tool, songwriting tools, songwriting website";
const description = "Unveil the art of songwriting with AI";
const longDescription = "Lyrical Labs is a pioneering songwriting platform that employs AI to expedite the process of writing both lyrics and music. Discover what songwriting truly involves—crafting emotions into words and melodies, all while having an AI as your co-pilot.";
const h1 = "Discover What Songwriting Is With AI Innovation";
const h2 = "Transform your understanding of songwriting with our AI-driven lyric and music generator.";
const sectionHeader = "Your Pathway to Mastering Songwriting";
const featureTitle1 = "Seamless Creation Process";
const featureTitle2 = "AI: Your Creative Partner in Songwriting";
const featureTitle3 = "Lyric and Melody Synergy";
const featureTitle4 = "Multi-genre Music Composition";
const featureParagraph1 = "Dive deep into the essence of songwriting with tools designed to seamlessly meld lyrics with melodies. Experience the simplicity of bringing your musical ideas to life, regardless of the theme or emotion.";
const featureParagraph2 = "Our AI isn't just a tool; it's your collaborative partner. It understands the nuances of songwriting, offering suggestions that resonate with your creative vision, helping you craft the song you've always wanted to write.";
const featureParagraph3 = "Achieve a harmonious balance between words and music. With our platform, you're not just writing lyrics or composing melodies—you're creating an experience that captures the soul of songwriting.";
const featureParagraph4 = "Whether you're inspired by jazz, rock, pop, or any other genre, our AI is equipped to guide you through the music composition process, enabling you to express your creativity without boundaries.";
const faq1 = "Can this platform teach me what songwriting is?";
const faq2 = "How does the AI understand songwriting?";
const faq3 = "Is this platform suitable for beginners in songwriting?";
const faq4 = "How can I personalize my songwriting journey?";
const faqa1 = "Absolutely! Lyrical Labs is designed to demystify songwriting for everyone—from beginners to seasoned musicians—through hands-on experience and guidance from our AI.";
const faqa2 = "Our AI has been trained on a vast dataset encompassing a wide array of genres and styles, allowing it to understand and suggest elements that make a song resonate, whether it’s through lyrics, rhythm, or melodies.";
const faqa3 = "Yes, the platform caters to all skill levels by providing easy-to-follow suggestions for lyrics and melodies, making the understanding of songwriting accessible to everyone.";
const faqa4a = "Personalize your songwriting by specifying your genre preferences, themes, and emotional tone. The AI will tailor its suggestions, creating a truly unique songwriting experience that reflects your individuality.";
const faqa4b = "Interact directly with our AI, offering feedback and refining your creations. This iterative process ensures your final song showcases your personal touch, embodying what songwriting means to you.";
const quote1 = "Lyrical Labs clarified what songwriting really is—an art form that’s now accessible thanks to AI.";
const quote2 = "Never knew the depth of songwriting until I used Lyrical Labs. Eye-opening!";
const quote3 = "A unique blend of technology and creativity, making songwriting understandable and fun.";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}

import React from "react";
import ReactDOM from "react-dom";
import backImg from "./lyrical labs share.jpg";
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, InstapaperShareButton, InstapaperIcon, LinkedinShareButton, LinkedinIcon, RedditShareButton, RedditIcon, EmailShareButton, EmailIcon } from 'react-share';


class Canvas extends React.Component {

  constructor(props) {
    super(props);
    this.handleDownload = this.handleDownload.bind(this);
  }

  componentDidMount() {
    const canvas = this.refs.canvas;
    const ctx = canvas.getContext("2d");
    const img = this.refs.image;
    ctx.canvas.width  = 1125;
    ctx.canvas.height = 2436;

    ctx.fillStyle = "white"; 
    var lineheight = 50;
    var x = 110;
    var y = 260;

    img.onload = () => {
      ctx.drawImage(img, 0, 0);
      ctx.font = "40px Helvetica";
      // ctx.fillText(this.props.text, 110, 260);
      var input = this.props.text.split('\n');
      
      var lines = [];

      for (let i = 0; i < input.length; i++) {
        const inputString = input[i];
      
        if (inputString.length <= 50) {
          // If string is 50 characters or less, add it to the output array
          lines.push(inputString);
        } else {
          // If string is over 50 characters, split it at the most recent space character before the 50th character
          const index = inputString.lastIndexOf(" ", 49);
          const substrings = [
            inputString.substring(0, index),
            inputString.substring(index + 1),
          ];
          lines.push(...substrings); // Add the substrings to the output array
        }
      }

      for (var i = 0; i<Math.min(lines.length, 42); i++)
        ctx.fillText(lines[i], x, y + (i*lineheight) );
    };

  }

  handleDownload() {
    const canvas = this.refs.canvas;
    const link = document.createElement('a');
    link.download = 'lyrical-labs.png';
    link.href = canvas.toDataURL();
    link.click();
  }

  render() {
    return (
      <div className="grid justify-items-center">
        <button type="button" onClick={this.handleDownload} className="text-xs bg-indigo-700 mb-6 p-3 rounded-md">
        ⬇ Download As Image
        </button>
        <canvas ref="canvas" />
        <img
          ref="image"
          src={backImg}
          hidden
        />
      </div>

      
    );
  }
}
export default Canvas;

import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function BeatMakingSoftware() {
const title = "Beat Making Software";
const keywords = "beat making software, music generator, beat creator, beat maker, beat making app, beat making platform, beat making tools, music production software, drum machine, music production, making beats, how to make beats";
const description = "Discover how to create beats with AI";
const longDescription = "Lyrical Labs is an innovative beat making platform that utilizes AI to assist you in producing beats and music efficiently. It's not only your beat-making companion but also aids in lyric creation, catering to your unique music production needs!";
const h1 = "Create professional beats using the power of AI.";
const h2 = "Elevate your music production with our AI-powered beat making software.";
const sectionHeader = "The Ultimate Beat Making Companion";
const featureTitle1 = "Limitless beat possibilities";
const featureTitle2 = "Your personal beat making assistant";
const featureTitle3 = "Advanced rhythm and beat generation";
const featureTitle4 = "Craft beats in any genre";
const featureParagraph1 = "Unlock endless music production potential with a continuous stream of unique beats and rhythms. Whether you're into hip hop, EDM, or any genre, the AI can generate beats that fit your style.";
const featureParagraph2 = "Work alongside our AI beat maker to produce the perfect beat. Begin with a base rhythm and mold it with your creativity, utilizing AI feedback to refine your beats to perfection.";
const featureParagraph3 = "Equipped with an advanced algorithm, our platform provides intricate beat patterns and rhythms, making it easier to craft complex and captivating music compositions.";
const featureParagraph4 = "With versatility at its core, our beat making software allows you to explore and create beats in over 100+ musical genres, giving you the tools to express your musical vision without limits.";
const faq1 = "Do I retain ownership over the beats I create?";
const faq2 = "Is this platform only for making beats or can it help with lyrics too?";
const faq3 = "How can this software help me enhance my beat making skills?";
const faq4 = "What makes this beat making platform stand out?";
const faqa1 = "Absolutely! Lyrical Labs is 100% royalty free, ensuring you maintain all rights to the beats and lyrics created using our software.";
const faqa2 = "Our platform is a comprehensive music production tool, designed for both beat making and lyric generation. It serves as a dual-purpose assistant to nurture your creativity in music and lyrics alike.";
const faqa3 = "Our beat making software is built to inspire and educate. By offering a wide range of rhythms and styles to experiment with, it provides a platform to practice and refine your beat making skills, enhancing your creativity and understanding of music production.";
const faqa4a = "Lyrical Labs distinguishes itself by offering an integrated approach to music production. Not only does it provide advanced beat making capabilities, but it also includes lyric generation tools to help bring your musical ideas to life fully.";
const faqa4b = "Moreover, the innovative AI technology behind our platform ensures a tailored experience, adapting to your style and preferences to help you create unique and original beats and songs.";
const quote1 = "Transformed my music production journey, an absolute must-have tool";
const quote2 = "Incredible software, it's like having a studio and writing partner in one";
const quote3 = "Beyond impressed with the beats I've been able to create, highly recommend";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}

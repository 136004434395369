import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function SongMakerLyrics() {
const title = "Song Maker Lyrics";
const keywords = "song maker lyrics, AI lyric generator, music generator, lyric creation, music creation platform, song maker software, song maker app, song maker tool, lyric and music generator, how to create songs, AI music composer";
const description = "Unlock your music potential with AI";
const longDescription = "Lyrical Labs is an innovative platform that combines the power of AI to assist in both lyric and music creation. It’s designed to be your go-to song maker, adapting to your style and creative needs!";
const h1 = "Create songs effortlessly with song maker lyrics and music AI.";
const h2 = "Elevate your music creation with our dual-powered lyric and music AI.";
const sectionHeader = "Your Ultimate Lyric and Music Creation Studio";
const featureTitle1 = "Dual creativity engine";
const featureTitle2 = "Tailored song making experience";
const featureTitle3 = "Smart linguistic and musical insights";
const featureTitle4 = "Multilingual song composition";
const featureParagraph1 = "With our AI, unleash a powerhouse of creativity for both lyrics and melodies. Whether it’s crafting catchy hooks or composing harmonious melodies, the possibilities are endless.";
const featureParagraph2 = "Personalize your song creation journey. Our AI learns from your inputs, blending perfectly crafted lyrics with matching tunes, giving you a seamless song-making process.";
const featureParagraph3 = "Armed with advanced linguistic analysis and musical theory, our platform offers insights to enrich your lyrics and harmonies, making every song you create a potential hit.";
const featureParagraph4 = "Compose songs in over 100+ languages, breaking geographical boundaries in your musical journey. Whether it’s Spanish guitar ballads or K-pop hits, song maker lyrics got you covered!";
const faq1 = "Can I use these songs professionally?";
const faq2 = "Does the AI do all the work in song creation?";
const faq3 = "How can this platform refine my musical creativity?";
const faq4 = "What sets this song maker apart from others?";
const faqa1 = "Absolutely! All music and lyrics generated are 100% yours. Lyrical Labs ensures your creations are royalty free, giving you full ownership to hit the studio or the charts.";
const faqa2 = "While our AI provides a solid foundation and endless inspiration, the magic really happens when you add your own flare. It’s all about collaboration between your creativity and our AI’s capabilities.";
const faqa3 = "Our platform offers not just a creation tool but a companion in your musical journey. By presenting unique ideas and compositions, it challenges you to explore new horizons, enhancing your skills.";
const faqa4a = "The integration of both a lyric and music generator, tailored to work in harmony, creates a unique song-making experience that’s both efficient and deeply creative.";
const faqa4b = "Additionally, our platform's smart suite of tools, including semantic analysis and melodic suggestions, ensures you have everything you need to bring your musical visions to life - a true testament to our commitment to your artistry.";
const quote1 = "Transformed my songwriting process, remarkable tool!";
const quote2 = "From lyrics to full songs, this platform has it all. A total lifesaver for any musician.";
const quote3 = "Lyrical Labs isn’t just a tool, it’s my song-making partner. Love it!";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}

import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom/client';
import { LockClosedIcon } from '@heroicons/react/20/solid'
import Footer from '../components/Footer';
// import '../App.css';

function ForgotPasswordEmail({ user, setUser }) {

  //This page is for the user to enter their email address to receive a password reset link
  //This page is NOT for the user to change their email address
  
  useEffect(() => {
    // 👇 add class to body element
    document.body.classList.add('fullscreen');
  }, []);


  const [buttonStatus, setButtonStatus] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [email, setEmail] = useState('');
  const url = "https://frozen-springs-61240.herokuapp.com";



  const forgotPassword = async (e) => {
    e.preventDefault();
    setButtonStatus(false);
    if(!email) {
      setErrorMessage("Please fill out all fields");
      setButtonStatus(true);
      return;
    }
    if (!email.includes('@') || !email.includes('.')) {
      setErrorMessage("Please enter a valid email address");
      setButtonStatus(true);
      return;
    }

    const response = await fetch(`${url}/forgot-password`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: email,
      })
    });
    if (!response.ok) {
      setErrorMessage('Email not found');
      setButtonStatus(true);
    } else {
      setErrorMessage('');
      setButtonStatus(true);
      window.location.href = '/signin';
    }
  }

  return (
    <>
    <div className="pt-4 w-full min-h-screen bg-[#121212]">
      <main className="mt-20">
        <div className="w-screen">
        <div className="grid justify-items-center">
          <div className= "w-96 bg-white/5 border border-white/10 rounded-lg shadow p-8">
              <form className="space-y-6" action="#" onSubmit={forgotPassword} method="POST">
                  <h5 className="text-xl font-medium text-gray-900 dark:text-white">Forgot your password?</h5>
                  <div>
                      <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter your email below:</label>
                      <input type="email" name="email" id="email" onChange={(e) => {setEmail(e.target.value)}} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-white/5 dark:border-white/10 dark:placeholder-gray-400 dark:text-white" placeholder="name@company.com" required/>
                  </div>
                  <button type="submit" disabled={!buttonStatus} className="w-full text-white bg-indigo-700 hover:bg-indigo-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Submit</button>
                  { errorMessage ? <div><span style={{color: "white"}}>{errorMessage}</span></div> : <div></div>}
                  <div className="text-sm font-medium text-gray-300">
                      Don't have an account? <a href="register" className="text-indigo-500 hover:underline">Register here</a>
                  </div>
              </form>
          </div>
        </div>    

        </div>
      </main>
    </div>
    <Footer/> 
  </>
    )
}

export default ForgotPasswordEmail;
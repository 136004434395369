import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function FreeMusic() {
const title = "Free Music";
const keywords = "free music, music generator, free songwriting, free songwriting assistant, free songwriting app, free songwriting platform, free songwriting software, free songwriting tool, free songwriting tools, free songwriting website, how to create music for free";
const description = "Create music and lyrics at no cost with AI";
const longDescription = "Lyrical Labs is a free songwriting platform that employs AI to help you craft both lyrics and music without any charges. It's your ideal songwriting assistant, tailored to your unique musical tastes and songwriting demands, all available for free!";
const h1 = "Craft Free Music and Lyrics Using AI";
const h2 = "Elevate your songwriting with our AI-powered lyrics and music, all for free.";
const sectionHeader = "Your Ultimate Free Music Creation Tool";
const featureTitle1 = "Unleash your creativity without costs";
const featureTitle2 = "Your free songwriting partner";
const featureTitle3 = "Comprehensive linguistic resources";
const featureTitle4 = "Explore music in any language for free";
const featureParagraph1 = "Discover endless songwriting possibilities with unlimited access to original ideas and tunes without spending a dime. The AI is versatile, covering various topics and producing music across all genres.";
const featureParagraph2 = "Engage with our free AI songwriting collaborator to develop the perfect track. Begin with lyrics and melodies, then fine-tune them interactively with your AI partner to realize your musical dream for free.";
const featureParagraph3 = "Our platform features an expansive smart dictionary for free, facilitating your search for the perfect words. It’s designed to inspire and aid in completing your lyrics effortlessly.";
const featureParagraph4 = "Dive into the world of music without linguistic limits! With support for over 100 languages, you’re empowered to craft song lyrics in your preferred language, all without any cost.";
const faq1 = "Can I use the generated music commercially?";
const faq2 = "Is the aim of this platform merely to replace musicians with AI?";
const faq3 = "In what ways can the platform help me beat creative blocks?";
const faq4 = "How does this platform enhance my music creation process for free?";
const faqa1 = "Yes, all music and lyrics created through Lyrical Labs are free for commercial use - you maintain full copyright of your creations.";
const faqa2 = "Certainly not. Our platform aims to spark your creativity by offering endless inspiration for free. Though our AI can generate complete songs, we encourage adding your personal touch. The AI serves as a catalyst, not a replacement for your creativity.";
const faqa3 = "Facing a creative block can halt any musician's flow. Lyrical Labs provides a vast array of creative ideas and themes for free, ensuring you’re never out of inspiration. Just input your preferences, and let the AI take care of the drought.";
const faqa4a = "Lyrical Labs fuels your music creation by offering limitless ideas and music suggestions for free, tailored to your style, encouraging you to explore and innovate.";
const faqa4b = "Moreover, our platform supports free, flexible lyric and music editing, aided by an extensive 'Related Words' dictionary. It's like having a no-cost, creative companion by your side throughout your music journey.";
const quote1 = "An absolute lifesaver, made creating my debut track possible without spending anything";
const quote2 = "Incredible tool for anyone looking to dive into music creation without financial barriers";
const quote3 = "Outstanding service, an exceptional way to embark on music creation for free";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}

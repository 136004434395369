import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function GénérateurDeTexteEnChanson() {
const title = "Générateur De Texte En Chanson";
const keywords = "générateur de texte en chanson, générateur de musique, générateur de paroles, outil de composition de chansons, application de composition de chansons, plateforme de composition de chansons, logiciel de composition de chansons, aide à la rédaction de chansons, comment écrire une chanson";
const description = "Apprenez à générer des textes pour vos chansons avec une IA";
const longDescription = "Lyrical Labs est une plateforme de composition qui utilise l'IA pour vous aider à créer des paroles et des mélodies musicales plus rapidement. C'est votre assistant personnel de composition de chansons, adapté à vos goûts et à vos besoins en matière de création musicale !";
const h1 = "Apprenez à créer des textes de chanson avec le pouvoir de l'IA.";
const h2 = "Révolutionnez votre façon de composer et obtenez une inspiration infinie avec nos paroles générées par IA.";
const sectionHeader = "Votre compagnon ultime en composition de chansons";
const featureTitle1 = "Créativité sans limites";
const featureTitle2 = "Votre assistant personnel pour écrire des chansons";
const featureTitle3 = "Dictionnaire intelligent intégré";
const featureTitle4 = "Écrivez dans n'importe quelle langue";
const featureParagraph1 = "Une inspiration sans fin pour la composition de chansons vous attend avec un approvisionnement inépuisable d'idées originales et de mélodies. L'IA peut écrire sur n'importe quel sujet et générer de la musique de tout genre.";
const featureParagraph2 = "Collaborez avec notre partenaire IA pour composer la chanson parfaite. Une fois que vous avez les paroles et la musique, vous pouvez donner votre avis en parlant à l'IA pour concrétiser votre vision musicale.";
const featureParagraph3 = "La plateforme est équipée d'un dictionnaire intelligent intégré qui vous aide à trouver toutes sortes de mots associés pour vous inspirer et vous aider à terminer vos paroles.";
const featureParagraph4 = "Avec plus de 100+ langues disponibles, vous pouvez apprendre à écrire des paroles de chansons dans n'importe quelle langue !";
const faq1 = "Est-ce que je conserve les droits d'auteur ?";
const faq2 = "Le but de la plateforme est-il de composer mes chansons pour moi ?";
const faq3 = "Comment la plateforme m'aide-t-elle à surmonter le blocage de l'écrivain ?";
const faq4 = "Comment la plateforme améliore-t-elle mon processus de composition ?";
const faqa1 = "Oui ! Lyrical Labs est 100% libre de droits, donc vous conservez tous les droits sur la musique et les paroles que vous créez sur cette plateforme.";
const faqa2 = "Non, le but de notre plateforme est de vous fournir une source infinie d'inspiration et de vous aider à surmonter le blocage de l'écrivain. Bien que l'IA soit capable de générer des ensembles complets de paroles et de mélodies musicales, nous vous encourageons à les utiliser comme point de départ et à y ajouter votre touche personnelle. L'IA est là pour assister et inspirer, et non pour remplacer votre créativité propre.";
const faqa3 = "Le blocage de l'écrivain peut être une expérience frustrante et démoralisante pour tout compositeur. Mais avec notre plateforme, vous n'aurez jamais à vous soucier de manquer d'idées. Entrez simplement votre thème et style désirés, et l'IA vous fournira un flux continu d'idées créatives et originales avec lesquelles travailler. Cela peut aider à lancer votre processus de composition et vous remettre sur les rails.";
const faqa4a = "Notre plateforme est conçue pour vous fournir un flux incessant d'idées et de suggestions basées sur vos préférences stylistiques choisies, vous donnant la liberté d'expérimenter et d'essayer quelque chose de nouveau.";
const faqa4b = "De plus, la plateforme vous permet d'écrire et d'éditer les paroles à votre guise, avec un dictionnaire 'Mots associés' très unique à vos côtés pour vous aider tout au long de votre processus de composition. C'est comme avoir votre propre assistant créatif personnel, adapté à vos propres goûts et besoins en matière de composition !";
const quote1 = "C'est un véritable changement de jeu, cela m'a vraiment aidé à écrire ma première chanson";
const quote2 = "Produit brillant, je le recommanderais à quiconque veut apprendre à écrire une chanson";
const quote3 = "Service client excellent, manière vraiment cool d'apprendre à écrire une chanson";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}

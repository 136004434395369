import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { retrievePaymentIntent } from '../../api';
import Alert from 'react-popup-alert';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

function StripeIntermediate({ user, setUser, subscriptionData, setSubscriptionActive }) {


  const [refresh, setRefresh] = useState(0);
  const [open, setOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogText, setDialogText] = useState('');
  const [alertType, setAlertType] = useState('');

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    if (alertType === 'success') {
      navigate('/app');
    } else if (alertType === 'error') {
      navigate('/prices');
    } else if (alertType === 'info') {
      setRefresh(refresh + 1);
    }
  };

  const navigate = useNavigate();
  const clientSecret = new URLSearchParams(window.location.search).get(
    'payment_intent_client_secret'
  );
  const paymentIntentId = new URLSearchParams(window.location.search).get(
    'payment_intent'
  );

  useEffect(() => {
    const retrieveIntent = async () => {
    const paymentIntent = await retrievePaymentIntent(paymentIntentId);
    switch (paymentIntent.status) {
      case 'succeeded':
        //TDDO: Make these console logs alerts or popups
        console.log("Payment successful")
        setDialogTitle('Payment successful!');
        setDialogText('Your payment was successful. Close this message and you will be redirected to the app.');
        handleOpen();
        setAlertType('success');
        break;
      case 'processing':
        console.log("Payment processing. We'll update you when payment is received.");
        setDialogTitle('Payment processing');
        setDialogText('Your payment is processing. We will update you when payment is received. Close this message to check again.');
        handleOpen();
        setAlertType('info');
        break;
  
      case 'requires_payment_method':
        console.log('Payment failed. Please try another payment method.');
        setDialogTitle('Payment failed');
        setDialogText('Payment failed. Please try another payment method. Close this message to try again.');
        handleOpen();
        setAlertType('error');
        // Redirect your user back to your payment page to attempt collecting
        // payment again 
        break;
  
      default:
        console.log('Something went wrong.');
        setDialogTitle('Error');
        setDialogText('Something went wrong. Close this message to try again.');
        handleOpen();
        setAlertType('error');
        break;
    }
  }
  try{
    retrieveIntent();
  } catch (err) {
    console.log(err);
    try{
      retrieveIntent();
    } catch (err) {
      console.log(err);
      navigate('/app');
    }
  }
  }, [refresh])


  
  return (
    <>
      <div className="message">Processing...</div>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {dialogTitle}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
  </>
  )
}

export default StripeIntermediate;
import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function MakeMusicCloud() {
const title = "Make Music Cloud";
const keywords = "make music cloud, generate music online, music cloud generator, cloud-based music creation, AI music creation, lyric generator, music composer online, cloud music composer, cloud songwriting, online music maker, create music cloud, cloud-based songwriting";
const description = "Create music and lyrics effortlessly with AI on the cloud";
const longDescription = "Make Music Cloud is a revolutionary platform that offers both music and lyric generation in a cloud-based environment, providing the ultimate toolset for songwriters and musicians. Whether you're a beginner or a seasoned artist, our AI-driven platform enhances your creative process.";
const h1 = "Elevate Your Music Creation with Make Music Cloud";
const h2 = "Harness the power of AI in the cloud to compose music and write lyrics";
const sectionHeader = "Your Cloud-Based Music Creation Hub";
const featureTitle1 = "Streamlined music and lyric generation";
const featureTitle2 = "AI-driven creativity in the cloud";
const featureTitle3 = "Endless musical possibilities";
const featureTitle4 = "Create from anywhere, anytime";
const featureParagraph1 = "Make Music Cloud empowers you with the ability to generate both lyrics and melodies seamlessly. With just a few clicks, transform your ideas into full-fledged songs, exploring an array of genres and themes.";
const featureParagraph2 = "Our cloud-based platform utilizes advanced AI to inspire and enhance your songwriting process. It's like having a perpetual muse and collaborator accessible from anywhere.";
const featureParagraph3 = "Dive into a limitless pool of creative options, from generating catchy hooks to crafting complex compositions. The Make Music Cloud adapts to your style and preference, offering personalized suggestions.";
const featureParagraph4 = "The convenience of cloud-based creation means you're no longer tethered to a single location or device. Start a project on one device and continue from another, anytime, anywhere.";
const faq1 = "Can I access my projects on any device?";
const faq2 = "Does the platform offer genre-specific music generation?";
const faq3 = "How user-friendly is the music and lyric generator for beginners?";
const faq4 = "Can I collaborate with others using Make Music Cloud?";
const faqa1 = "Absolutely! Our cloud-based platform ensures that your music and lyrics projects are always accessible, regardless of the device you're using.";
const faqa2 = "Yes, Make Music Cloud provides genre-specific options for both music and lyric generation, catering to a wide range of musical tastes and styles.";
const faqa3 = "We've designed our platform with intuitiveness in mind. Beginners can start creating music and lyrics effortlessly, guided by our AI every step of the way.";
const faqa4a = "Make Music Cloud is built for collaboration. Share your projects with fellow musicians and work together in real-time, transforming the way you create music.";
const faqa4b = "The platform's ability to instantly share updates and changes makes it the perfect tool for collaborative songwriting and music production in the digital age.";
const quote1 = "Never thought cloud-based music creation could be this easy and fun!";
const quote2 = "Make Music Cloud transformed the way I approach songwriting. It's my go-to platform now.";
const quote3 = "From lyrics to melodies, this platform has everything you need to kickstart your music creation journey in the cloud.";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}

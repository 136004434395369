import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  CardElement,
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import Footer from '../Footer';

const Subscribe = ({ user, setUser, subscriptionData, setSubscriptionActive, subscriptionActive }) => {

  // Get the lookup key for the price from the previous page redirect.
  const [name, setName] = useState('');
  const url = "https://frozen-springs-61240.herokuapp.com";
  const [messages, _setMessages] = useState('');
  const [paymentIntent, setPaymentIntent] = useState();
  const navigate = useNavigate();

  // helper for displaying status messages.
  const setMessage = (message) => {
    _setMessages(`${messages}\n\n${message}`);
  }

  useEffect(() => {
    if (subscriptionActive) {
      navigate('/account');
    }
    if (!subscriptionActive) {
      const checkSubscription = async () => {
        const subscriptionStatus = await fetch(`${url}/check-subscription`, {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': user.token,
          },
          body: JSON.stringify({
            user
          }),
        }).then(r => {
          if (r.ok){
            return r.json();
          } else {
            return false;
          }
        });
        if (subscriptionStatus) {
          setSubscriptionActive(true);
          let updatedUser = user;
          updatedUser.customer = subscriptionStatus;
          setUser(updatedUser);
          navigate('/account');
          return;
        }
      }
      checkSubscription();
    }
    if (!user) {
      navigate('/signin');
    }
    if (!subscriptionData) {
      navigate('/prices');
    }
  }, []);

  // Initialize an instance of stripe.
  const stripe = useStripe();
  const elements = useElements();
  const appearance = {
    theme: 'stripe',
  
    variables: {
      colorPrimary: '#0570de',
      colorBackground: '#ffffff/5',
      colorText: '#30313d',
      colorDanger: '#df1b41',
      fontFamily: 'Ideal Sans, system-ui, sans-serif',
      spacingUnit: '2px',
      borderRadius: '4px',
      // See all possible variables below
    }
  };


  if (!stripe || !elements) {
    // Stripe.js has not loaded yet. Make sure to disable
    // form submission until Stripe.js has loaded.
    return '';
  }

  // When the subscribe-form is submitted we do a few things:
  //
  //   1. Tokenize the payment method
  //   2. Create the subscription
  //   3. Handle any next actions like 3D Secure that are required for SCA.
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.

    // Use card Element to tokenize payment details
    let result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: 'https://lyricallabs.io/processing',
      }
    });

    if(result.error) {
      // show error and collect new card details.
      setMessage(result.error.message);
      return;
    }
      
  }


  return (
    <>
      <div className="pt-4 w-full min-h-screen bg-[#121212]">
        <main className="mt-20">
          <div className="w-screen">
            <div className="grid justify-items-center">
              <div className= "w-96 bg-stone-300 border border-white/10 rounded-lg shadow p-8">
              <form onSubmit={handleSubmit}>
                <label className="text-sm font-sans text-[#30313d]">
                  Full name
                  <input type="text" placeholder="Full name" className="rounded-md py-3 px-3" id="name" value={name} onChange={(e) => setName(e.target.value)} />
                </label>
                <PaymentElement options={appearance}/>

                <button className="bg-indigo-600 text-sm mt-4 px-4 py-2">
                  Subscribe
                </button>

                <div>{messages}</div>
              </form>

              </div>
            </div>
          </div>
        </main>
      </div>
      <Footer/>
    </>
  )
}

export default Subscribe;

import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function AiSongWriter() {
const title = "Ai Songwriter";
const keywords = "ai songwriter, lyric generator, music generator, song creation, music composition, ai music, ai lyrics, songwriting assistant, songwriting app, songwriting platform, songwriting software, songwriting tool, songwriting tools, songwriting website, ai music creation";
const description = "Unlock the potential of AI in songwriting";
const longDescription = "Lyrical Labs is an innovative songwriting platform that harnesses the power of AI to assist you in generating both song lyrics and music melodies seamlessly. It acts as your personal AI songwriter, catering to your unique musical style and lyrical needs!";
const h1 = "Transform Your Songwriting with an AI Songwriter.";
const h2 = "Elevate your music and lyrics with our cutting-edge AI Songwriter.";
const sectionHeader = "Your Go-To AI Songwriting Partner";
const featureTitle1 = "AI-driven inspiration";
const featureTitle2 = "Dynamic lyric and melody creation";
const featureTitle3 = "Smart compositional tools";
const featureTitle4 = "Multilingual songwriting capabilities";
const featureParagraph1 = "Dive into a world of unlimited musical creativity, where our AI songwriter generates innovative ideas, melodies, and lyrics on any theme or genre you desire.";
const featureParagraph2 = "Work alongside our AI to craft complete songs. From the initial lyrics to the final tune, our platform is designed to bring your musical vision to life with precision.";
const featureParagraph3 = "Benefit from our sophisticated suite of compositional tools, including an advanced dictionary for lyrical inspiration and melody suggestions that align with your song's mood.";
const featureParagraph4 = "Explore global musical landscapes with the ability to write lyrics in over 100 languages, broadening the horizons of your songwriting journey.";
const faq1 = "Can I own the songs created?";
const faq2 = "Is the AI designed to replace me as a songwriter?";
const faq3 = "How can the AI Songwriter alleviate writer’s block?";
const faq4 = "In what ways does the AI enhance my songwriting workflow?";
const faqa1 = "Absolutely! At Lyrical Labs, the music and lyrics you create with our AI songwriter are entirely yours, ensuring you retain full copyright.";
const faqa2 = "Our AI songwriter is conceived as a companion, not a replacement. It’s here to stimulate your creativity and offer assistance, letting you steer the creative process and infuse your unique flair.";
const faqa3 = "The fear of the blank page becomes a thing of the past with our AI. By inputting a few keywords or themes, you’ll receive a stream of suggestions for lyrics and melodies, effectively jumpstarting your creative engine.";
const faqa4a = "Our AI Songwriter is a powerhouse of innovation, offering an abundance of lyrical and musical ideas tailored to your preferences, allowing for unparalleled creative experimentation.";
const faqa4b = "The platform enhances your songwriting by simplifying the editing process, providing contextually relevant vocabulary, and offering melody suggestions, ensuring a holistic and harmonious workflow that aligns with your artistic vision.";
const quote1 = "An extraordinary tool that redefined how I approach songwriting.";
const quote2 = "The blend of lyric and melody suggestions opened new creative avenues for me.";
const quote3 = "Fantastic support, an essential tool for anyone exploring the art of songwriting with AI.";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}

import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function AiSongMaker() {
const title = "Ai Song Maker";
const keywords = "ai song maker, music generator, lyric generator, song creation, ai music, ai lyrics, song maker software, song maker app, song maker platform, ai songwriting, ai music composition";
const description = "Create full songs with AI";
const longDescription = "Lyrical Labs is an innovative platform where AI song maker technology meets creativity, enabling you to generate both music and lyrics effortlessly. It's the perfect blend of your artistry enhanced by AI capabilities for an unmatched songwriting experience.";
const h1 = "Unleash Your Creativity with AI Song Maker";
const h2 = "Craft the perfect song with AI-driven music and lyrics.";
const sectionHeader = "Your AI-Powered Song Creation Studio";
const featureTitle1 = "Innovative music and lyric generation";
const featureTitle2 = "Collaborate with AI for the perfect tune";
const featureTitle3 = "Expansive lyrical and musical dictionary";
const featureTitle4 = "Multilingual song crafting";
const featureParagraph1 = "Whether you're after a catchy melody or poignant lyrics, our AI song maker offers an endless wellspring of creativity, empowering you to create music in any genre and lyrics on any theme.";
const featureParagraph2 = "Join forces with our AI to refine your songs to perfection. Start with a solid base of lyrics and melody generated by AI, then tweak them to your heart's content for a song that truly resonates.";
const featureParagraph3 = "Armed with a comprehensive dictionary for both music and lyrics, the platform aids in brainstorming and refining your song ideas, ensuring your message hits home.";
const featureParagraph4 = "Our AI understands and generates songs in over 100 languages, making it easier than ever to connect with audiences around the globe through your music.";
const faq1 = "What rights do I have over songs created with AI?";
const faq2 = "Will the AI handle all aspects of song creation?";
const faq3 = "How can AI song maker help me break through creative blocks?";
const faq4 = "In what ways does AI song maker enhance my songwriting process?";
const faqa1 = "All music and lyrics created with Lyrical Labs are completely yours to own—enjoy 100% royalty-free rights to all your creations.";
const faqa2 = "The AI song maker serves as a co-creator, offering a starting point for melody and lyrics. While it's capable of generating complete songs, we encourage you to infuse them with your unique touch, keeping your creative stamp on the final piece.";
const faqa3 = "Creative blocks become a thing of the past with our AI song maker. Input your desired genre and theme; the AI instantly supplies a fresh stream of lyrics and melodies to spark your creativity and propel your songwriting journey forward.";
const faqa4a = "The platform streamlines the songwriting process by offering tailored ideas and music suggestions, freeing you to experiment with styles and arrangements.";
const faqa4b = "With real-time lyric and melody editing, alongside a dictionary of related words and musical terms, it's like having a co-writer who's always in tune with your artistic vision.";
const quote1 = "Using the AI song maker tool was an eye-opener—I've never created music so effortlessly.";
const quote2 = "For anyone struggling with songwriting, this platform is a miracle. It's like having a 24/7 collaborator.";
const quote3 = "Navigating the tool was straightforward, and the results were incredibly personalized. Great job on creating a tool that feels like it’s listening.";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}

import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function TextToSongGenerator() {
const title = "Text To Song Generator";
const keywords = "text to song generator, lyric generator, music generator, song creation, AI music creation, AI lyric creation, song generator app, song generator platform, song generator software, song generator tool, song generator tools, song generator website, how to generate a song";
const description = "Discover the revolutionary way to generate songs with AI";
const longDescription = "Lyrical Labs is an innovative platform that empowers you to effortlessly create both lyrics and music with the power of AI. It's your ultimate text to song generator, designed to cater to your unique creative needs!";
const h1 = "Generate songs instantly using the advanced Text to Song Generator.";
const h2 = "Transform your ideas into music with our powerful AI-driven lyric and music generator.";
const sectionHeader = "Your Go-To Song Creation Studio";
const featureTitle1 = "Seamless song creation";
const featureTitle2 = "Dual-function AI: Lyrics and Music";
const featureTitle3 = "Advanced melody generation";
const featureTitle4 = "Craft songs in any genre";
const featureParagraph1 = "Unlock the full potential of song creation with a seamless flow of unique lyrics and compelling melodies. The text to song generator is designed to inspire and facilitate your creative process.";
const featureParagraph2 = "Our AI is uniquely capable of generating not just lyrics but also matching music, ensuring a cohesive creation that resonates with your artistic vision.";
const featureParagraph3 = "With our sophisticated AI, creating melodies that capture the essence of your lyrics has never been easier. Dive into a world of limitless musical possibilities.";
const featureParagraph4 = "Whether you're into pop, rock, electronic, or classical, our text to song generator supports a variety of musical genres, giving you the freedom to explore and create without boundaries.";
const faq1 = "Can I generate both lyrics and music with this tool?";
const faq2 = "How customizable is the generated song?";
const faq3 = "What makes this text to song generator stand out?";
const faq4 = "Is it easy for beginners to use?";
const faqa1 = "Absolutely! Lyrical Labs combines the best of both worlds, offering you a revolutionary tool to generate both lyrics and music seamlessly.";
const faqa2 = "Highly customizable! You're encouraged to adjust and refine both lyrics and melodies to match your creative vision. Consider the AI-generated content as your creative springboard.";
const faqa3 = "Our text to song generator is built with cutting-edge AI technology that understands the nuances of music and lyric composition, setting it apart from simplistic generators.";
const faqa4a = "Yes, we've designed our platform to be as intuitive as possible, making it accessible for songwriters and musicians of all skill levels. Even if you're just starting out, you'll find our tool incredibly easy to use.";
const faqa4b = "Moreover, we offer guidance and tips throughout the process, ensuring that even novices can bring their musical visions to life with confidence.";
const quote1 = "Astoundingly precise and creative, turned my vague ideas into a beautiful song";
const quote2 = "Lyrical Labs' text to song generator is nothing short of revolutionary for songwriters";
const quote3 = "Incredibly user-friendly, it has democratized music creation. Absolutely love it!";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}

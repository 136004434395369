import React, { useEffect, useState } from 'react'
import Footer from '../components/Footer';

export default function VerifyEmail({ user, setUser }) {

  const token = new URLSearchParams(window.location.search).get('token');
  const [resultReceived, setResultReceived] = useState(false);
  const [verified, setVerified] = useState(false)
  const url = "https://frozen-springs-61240.herokuapp.com";


  useEffect(() => {
    if (!token){
      setVerified(false);
      setResultReceived(true);
    } else {
      const fetchData = async () => {
        const verification = await fetch(`${url}/verify-email/${token}`, {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
          }
        }).then(r => r.ok);
        if (verification) {
          setResultReceived(true);
          setVerified(true);
        } else {
          setResultReceived(true);
          setVerified(false);
        }
      }
      fetchData()
    }

  }, [])
  
  return (
    <>
      <div className="p-12"></div>
      { !resultReceived ? <div>Pending Verification</div> : 
      (verified ? <div>Your Email is Verified!</div> : <div>Verification Failed</div>)}
      <div className="p-12"></div>
      <Footer/>
    </>
  )
}



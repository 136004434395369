import React, { useState } from 'react';
import './ShareModal.css';
import Canvas from "./Canvas.js";
import {FacebookMessengerShareButton, FacebookMessengerIcon, FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, InstapaperShareButton, InstapaperIcon, LinkedinShareButton, LinkedinIcon, RedditShareButton, RedditIcon, EmailShareButton, EmailIcon, TumblrShareButton, TumblrIcon } from 'react-share';

const Modal = ({ onClose, text }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [buttonStatus, setButtonStatus] = useState(true);
  const url = "https://frozen-springs-61240.herokuapp.com"
  const fullText = 'Wrote these lyrics using Lyrical Labs!' + text;

  return (
    <div className="modal-container">
      <div className="modal-content">
      <button className="close-button" onClick={onClose}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <div className="lg:flex lg:flex-row">
          <div className="lg:border-r lg:border-white/5 lg:p-3">
            <Canvas text={text.trim()}/>   
          </div>

          <div className="mt-6 lg:mt-0">
            <h2>Share your lyrics!</h2>
            <div className='flex flex-row space-x-4 justify-center'>
              <FacebookShareButton
                url={'https://www.lyricallabs.io'}
                quote={fullText}
              >
                <FacebookIcon size={32} round />
              </FacebookShareButton>

              <FacebookMessengerShareButton
                url={'https://www.lyricallabs.io'}
                quote={'Wrote these lyrics using Lyrical Labs!' + text}
              >
                <FacebookMessengerIcon size={32} round />
              </FacebookMessengerShareButton>

              <TwitterShareButton
                url={'https://www.lyricallabs.io'}
                title={'Just wrote this! \n \n' + text}
              >
                <TwitterIcon size={32} round />
              </TwitterShareButton>

              <LinkedinShareButton
                url={'https://www.lyricallabs.io'}
                title={'Just wrote this!'}
                summary={text}
              >
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>

              <RedditShareButton
                url={'https://www.lyricallabs.io'}
                title={'Wrote these lyrics using Lyrical Labs! \n \n' + text}
              >
                <RedditIcon size={32} round />
              </RedditShareButton>
              

              <TumblrShareButton
                url={'https://www.lyricallabs.io'}
                quote={'Wrote these lyrics using Lyrical Labs!' + text}
              >
                <TumblrIcon size={32} round />
              </TumblrShareButton>

              <EmailShareButton
                url={'https://www.lyricallabs.io'}
                subject={'Wrote these lyrics using Lyrical Labs!'}
                body={text}
                quote={'Wrote these lyrics using Lyrical Labs!' + text}
              >
                <EmailIcon size={32} round />
              </EmailShareButton>
            </div>
            <textarea
              type="text"
              name="fixed"
              rows="22"
              cols="40"
              placeholder="Generated lyrics will appear here."
              className="text-xs font-white bg-white/5 rounded-md p-5 mt-5 mx-5"
              value={text.trim()}
              disabled
              contentEditable
            ></textarea>
          </div>

        </div>

      </div>
    </div>
  );
};

export default Modal;
import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function SongGenerator() {
const title = "Song Generator";
const keywords = "song generator, lyric generator, music generator, AI music, AI lyrics, songwriting assistant, songwriting app, songwriting platform, songwriting software, songwriting tool, songwriting tools, songwriting website, how to generate songs";
const description = "Craft your next hit with our AI song and lyric generator";
const longDescription = "Lyrical Labs is where creativity meets technology, offering a song generator platform that uses AI to help you compose both music and lyrics. It's the ultimate songwriting assistant, adapting to your unique style and songwriting preferences!";
const h1 = "Harness the power of AI with our song generator.";
const h2 = "Transform your songwriting with our AI-powered music and lyric generator.";
const sectionHeader = "Your Next Hit Awaits";
const featureTitle1 = "Infinite musical possibilities";
const featureTitle2 = "A collaborative creative partner";
const featureTitle3 = "Expansive lyrical vocabulary";
const featureTitle4 = "Compose in any genre";
const featureParagraph1 = "Unlock a world of musical creativity with an endless array of original tunes and lyrics. Our AI song generator is capable of exploring any topic, creating music in any genre, and bringing your ideas to life.";
const featureParagraph2 = "Work alongside our AI as your songwriting partner to fine-tune the perfect track. Starting with a base of lyrics and melody, you can interact with the AI to refine your song to your exact specifications.";
const featureParagraph3 = "Our platform is equipped with a comprehensive lyrical dictionary, enabling you to discover the perfect words and phrases to express your ideas and complete your song.";
const featureParagraph4 = "From pop to rock, classical to country, our song generator breaks down the barriers of language and genre, allowing you to craft song lyrics in over 100+ languages.";
const faq1 = "Who owns the songs created?";
const faq2 = "Is the AI meant to replace songwriters?";
const faq3 = "Can it really help with creativity?";
const faq4 = "How does the song generator enhance my songwriting?";
const faqa1 = "Absolutely! Songs created with Lyrical Labs are completely yours. Our platform is 100% royalty-free, ensuring you retain full copyright of the music and lyrics you generate.";
const faqa2 = "No, our aim is not to replace songwriters but to empower them. Our song generator provides endless inspiration and a starting point. It's designed to complement your creativity, not substitute it.";
const faqa3 = "Indeed, the challenge of writer's block is all too common. However, our AI song generator offers a steady stream of fresh and inventive ideas, giving you the creative boost you need whenever you're stuck.";
const faqa4a = "Our song generator is tailored to spur your creativity, offering endless suggestions and musical compositions based on your preferred styles. It’s your personal laboratory for musical experimentation.";
const faqa4b = "Beyond generating lyrics and music, our platform allows for comprehensive customization. Our 'Related Words' feature aids in lyric writing, ensuring you have everything you need at your fingertips for the songwriting journey.";
const quote1 = "The song generator is incredible—crafted my latest hit with it!";
const quote2 = "A must-have for any songwriter, the lyric and music generator is a revolutionary tool.";
const quote3 = "Fantastic features and easy to use. Changed the way I approach songwriting.";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}

import React, { useState } from 'react';
import './App.css';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';


const Cancel = ({ setUser, user, token, subscriptionId, data, setData }) => {
  const url = "https://frozen-springs-61240.herokuapp.com";
  const [clicked, setClicked] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [open, setOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogText, setDialogText] = useState('');
  const [alertType, setAlertType] = useState('');

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    handleCancel();
    }
  

  const handleCloseNo = () => {
    setOpen(false);
    setClicked(false);
  }

  const handleClick = (e) => {
    e.preventDefault();
    setDialogTitle('Cancel subscription');
    setDialogText('Are you sure you want to cancel your subscription? You will lose access at the end of your billing period.');
    handleOpen();
    setAlertType('info');
  }


  const handleCancel = async () => {
    setClicked(true);
    const cancelledUser = await fetch(`${url}/cancel-subscription-at-period-end`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': user.token,
      },
      body: JSON.stringify({
        subscriptionId: subscriptionId,
        user,
      }),
    }).then(r => r.json());
    let newUser = cancelledUser;
    newUser.token = user.token;
    setUser(newUser);
    setData(data+1);
    setClicked(false);
  };

  return (
    <div>
      <button disabled={clicked} className="bg-indigo-500 text-sm mt-4 px-4 py-2" onClick={handleClick}>Cancel</button>
      <Dialog
        open={open}
        onClose={handleCloseNo}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {dialogTitle}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Yes</Button>
          <Button onClick={handleCloseNo}>No</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
  }


export default Cancel;

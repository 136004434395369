import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function SongwritingPrompts() {
const title = "Song Writing Prompts";
const keywords = "song writing prompts, music generator, lyrics generator, songwriting assistant, songwriting prompts app, songwriting prompts platform, music creation software, lyric writing tool, melody generation, songwriting inspiration, how to use song writing prompts";
const description = "Discover how song writing prompts can jumpstart your music creation process";
const longDescription = "Lyrical Labs is a unique platform that offers song writing prompts to ignite your creativity for lyrics and melodies alike. It's your ultimate tool for overcoming writer's block, providing a rich source of inspiration tailored to your songwriting needs.";
const h1 = "Unlock Your Creativity with Song Writing Prompts and AI";
const h2 = "Transform Your Songwriting Process with AI-Driven Prompts and Melodies";
const sectionHeader = "Your Personal Muse for Songwriting";
const featureTitle1 = "Inspiring song writing prompts";
const featureTitle2 = "Dynamic melody generation";
const featureTitle3 = "AI-powered lyric crafting";
const featureTitle4 = "Versatile song creation";
const featureParagraph1 = "Navigate through a vast array of song writing prompts designed to spark your creativity and inspire original lyrics. No matter your genre, our AI provides prompts that resonate with your musical style.";
const featureParagraph2 = "Connect with melodies that match your song writing prompts. Our AI crafts unique tunes that perfectly align with the essence of your lyrics, offering endless musical inspiration.";
const featureParagraph3 = "Beyond providing prompts, our platform helps you craft complete lyrics. Use prompts as a springboard and watch as the AI suggests compelling lines to complement your initial ideas.";
const featureParagraph4 = "Our technology supports creativity across genres and languages. No matter your background or preference, turn your song writing prompts into complete, polished songs ready to enchant listeners.";
const faq1 = "Can song writing prompts cater to specific genres?";
const faq2 = "How unique are the melodies generated from prompts?";
const faq3 = "Can I influence the direction of lyrics generated from prompts?";
const faq4 = "How can song writing prompts accelerate my songwriting process?";
const faqa1 = "Yes, our platform offers a wide variety of prompts tailored to different genres. Whether you're into rock, pop, jazz, or anything in between, you'll find prompts that inspire.";
const faqa2 = "The melodies generated by our AI are unique and tailored to your input, ensuring that your music stands out. Our algorithm takes inspiration from a vast database of musical compositions to offer original creations.";
const faqa3 = "Absolutely! While our AI provides a starting point based on your prompt, you have complete control to customize and refine the lyrics to perfectly match your vision.";
const faqa4a = "Song writing prompts can significantly speed up the songwriting process by offering a starting point that you can quickly build upon, reducing the time spent searching for inspiration.";
const faqa4b = "The synergy between our prompts, AI-generated melodies, and lyric suggestions ensures a smooth and efficient workflow, allowing you to focus on refining your creative output.";
const quote1 = "Using song writing prompts has completely changed how I approach songwriting. It's an endless source of inspiration.";
const quote2 = "The melodies and lyrics I've been able to create thanks to the prompts are beyond anything I expected. Truly innovative.";
const quote3 = "Lyrical Labs doesn't just provide prompts; it's like having a co-writer who's always ready with fresh ideas.";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}

import Footer from "../../components/Footer";
import styles from "../Homescreen/Homescreen.module.css";
import { Helmet } from 'react-helmet';
import LandingPageTemplate from "./LandingPageTemplate";


export default function LyricGenerator() {

  const title = "Lyric Generator";
  const keywords = "lyrics, songwriting, songwriting assistant, songwriting app, songwriting platform, songwriting software, songwriting tool, songwriting tools, songwriting website, how to write a song, lyric generator";
  const description = "Lyrical Labs Lyric Generator uses AI to generate the perfect lyrics for your song.";
  const longDescription = "Lyrical Labs Lyric Generator uses AI to generate the perfect lyrics for your song.";
  const h1 = "Learn How to write a song using an AI Lyric Generator.";
  const h2 = "Revolutionize your songwriting and get infinite inspiration with our AI-powered lyric generator.";
  const sectionHeader = "The Ultimate Songwriting Companion";
  const featureTitle1 = "Limitless creativity";
  const featureTitle2 = "Your personal songwriting assistant";
  const featureTitle3 = "In-built smart dictionary";
  const featureTitle4 = "Write in any language";
  const featureParagraph1 = "Endless songwriting inspiration awaits with a never-ending supply of original ideas. The AI lyric generator can write about any topic, and can teach you how to write song lyrics like a pro.";
  const featureParagraph2 = "Collaborate with our AI songwriting partner to craft the perfect lyrics. Once you have lyrics, you can provide feedback by talking to the AI to bring your musical vision to life.";
  const featureParagraph3 = "The platform comes equipped with an in-built smart dictionary that helps you find all sorts of related words to provide inspiration and help you finish your lyrics.";
  const featureParagraph4 = "With over 100+ languages to choose from, you can generate songs in any language!";
  const faq1 = "Do I keep the copyright?";
  const faq2 = "Is the goal of the platform to completely write my songs for me?";
  const faq3 = "How does the platform help me overcome writer's block?";
  const faq4 = "How does the platform help with boosting my songwriting process?";
  const faqa1 = "Yes! Lyrical Labs is 100% royalty free, so you keep all the rights to the music and lyrics you create on this platform.";
  const faqa2 = "No, the goal of our platform is to provide you with an endless supply of inspiration and to help overcome writer's block. While the AI is capable of generating complete sets of lyrics and musical melodies, we encourage you to use them as a starting point and add your own personal touch. The AI is here to assist and inspire you, not to replace your own creativity.";
  const faqa3 = "Writer's block can be a frustrating and demoralizing experience for any songwriter. But with our platform, you'll never have to worry about running out of ideas. Simply input your desired theme and style, and the AI will provide you with an endless stream of creative and original ideas to work with. This can help kickstart your songwriting process and get you back on track.";
  const faqa4a = "Our platform is designed to provide you with an endless supply of ideas and suggestions based on your chosen stylistic preferences, giving you the freedom to experiment and try something new.";
  const faqa4b = "Additionally, the platform allows you to write and edit the lyrics at your own will, with a highly unique 'Related Words' dictionary by your side to assist you throughout your songwriting process. It's like your own personal creative assistant, fit to your own personal tastes and songwriting needs!";
  const quote1 = "The lyric generator transformed my writing process, I've never been more productive.";
  const quote2 = "Outstanding tool for anyone aiming to boost their songwriting creativity";
  const quote3 = "Top-notch support from the team, a truly revolutionary way to compose music";


  return (
    <LandingPageTemplate
    title={title}
    keywords={keywords}
    description={description}
    longDescription={longDescription}
    h1={h1}
    h2={h2}
    sectionHeader={sectionHeader}
    featureTitle1={featureTitle1}
    featureTitle2={featureTitle2}
    featureTitle3={featureTitle3}
    featureTitle4={featureTitle4}
    featureParagraph1={featureParagraph1}
    featureParagraph2={featureParagraph2}
    featureParagraph3={featureParagraph3}
    featureParagraph4={featureParagraph4}
    faq1={faq1}
    faq2={faq2}
    faq3={faq3}
    faq4={faq4}
    faqa1={faqa1}
    faqa2={faqa2}
    faqa3={faqa3}
    faqa4a={faqa4a}
    faqa4b={faqa4b}
    quote1={quote1}
    quote2={quote2}
    quote3={quote3}
    />

  )
}

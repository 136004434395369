import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function HowToMakeMusic() {
const title = "How To Make Music";
const keywords = "music composition, music production, music generator, how to make music, music making software, music creation app, music creation platform, music tool, music composition tools, music production website, AI music generator";
const description = "Discover the art of music creation with AI";
const longDescription = "Lyrical Labs is a music creation platform that employs AI to assist you in both lyric writing and music composition. It's designed to be your go-to music creation assistant, tailored to fit your unique creative process and music production needs!";
const h1 = "Master the art of music making with the power of AI.";
const h2 = "Craft your next masterpiece with our AI-driven lyrics and music.";
const sectionHeader = "Your Ultimate Music Creation Partner";
const featureTitle1 = "Unleash boundless creativity";
const featureTitle2 = "Your devoted music creation assistant";
const featureTitle3 = "Advanced musical intelligence";
const featureTitle4 = "Compose in any musical genre";
const featureParagraph1 = "Tap into an inexhaustible source of inspiration with unlimited musical themes and lyrical ideas. The AI can generate content across any genre, offering fresh melodies and lyrics for your tracks.";
const featureParagraph2 = "Work alongside our AI music partner to refine melodies and lyrics. Once you have a basic structure, you can interact with the AI to fine-tune your compositions, aligning them with your musical vision.";
const featureParagraph3 = "Our platform includes a sophisticated musical intelligence that suggests chords, rhythms, and structures, helping you navigate through the composition process with ease and inventiveness.";
const featureParagraph4 = "Whether you dream in jazz, rock, pop, or electronic, our platform supports over 100+ genres, enabling you to explore music composition in styles that resonate with your artistic intent.";
const faq1 = "Do I retain ownership over the music I create?";
const faq2 = "Is Lyrical Labs designed to replace musicians and composers?";
const faq3 = "How can Lyrical Labs assist me when I'm stuck during the music creation process?";
const faq4 = "In what ways does Lyrical Labs enhance my music production workflow?";
const faqa1 = "Absolutely! Lyrical Labs is 100% royalty-free, granting you full rights to your created music and lyrics, ensuring your creations are uniquely yours.";
const faqa2 = "No, Lyrical Labs aims to spark your creativity and support your music-making journey. The AI generates foundational melodies and lyrics, serving as a source of inspiration rather than a replacement for your own artistry.";
const faqa3 = "Facing creative blocks in music can halt your progress. Lyrical Labs offers a solution by providing you with a constant flow of musical ideas and lyrical suggestions, designed to inspire and pull you out of any creative rut.";
const faqa4a = "By offering limitless ideas and musical suggestions tailored to your preferred styles, Lyrical Labs encourages experimentation and discovery within your music production process.";
const faqa4b = "Moreover, the platform enables you to iteratively refine your music, supported by a comprehensive 'Related Words' dictionary and musical elements database at your disposal, serving as your personalized creative aide.";
const quote1 = "Lyrical Labs has revolutionized my music-making process, an absolute must-have tool.";
const quote2 = "A magnificent platform, Lyrical Labs has been instrumental in my music creation journey.";
const quote3 = "Supportive team and a fantastic approach to music making, highly recommend Lyrical Labs.";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}

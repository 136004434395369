import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function ÉcrireDesChansons() {
const title = "Écrire Des Chansons";
const keywords = "générer des paroles, écrire des chansons, assistant d'écriture de chansons, application pour écrire des chansons, plateforme pour écrire des chansons, logiciel pour écrire des chansons, outil pour écrire des chansons, outils pour écrire des chansons, site web pour écrire des chansons, comment écrire des chansons";
const description = "Apprenez à écrire des chansons avec l'IA";
const longDescription = "Lyrical Labs est une plateforme d'écriture de chansons qui utilise l'IA pour vous aider à écrire des paroles et des mélodies musicales plus rapidement. C'est votre assistant personnel pour l'écriture de chansons, adapté à vos goûts et besoins personnels!";
const h1 = "Apprenez à écrire des chansons avec la puissance de l'IA.";
const h2 = "Révolutionnez votre écriture de chansons et obtenez une inspiration infinie avec nos paroles générées par l'IA.";
const sectionHeader = "Le compagnon ultime pour l'écriture de chansons";
const featureTitle1 = "Créativité sans limites";
const featureTitle2 = "Votre assistant personnel pour l'écriture de chansons";
const featureTitle3 = "Dictionnaire intelligent intégré";
const featureTitle4 = "Écrivez dans n'importe quelle langue";
const featureParagraph1 = "Une inspiration sans fin pour l'écriture de chansons vous attend avec un approvisionnement interminable d'idées originales et de mélodies. L'IA peut écrire sur n'importe quel sujet, et générer de la musique de n'importe quel genre";
const featureParagraph2 = "Collaborez avec notre partenaire d'écriture de chansons IA pour créer la chanson parfaite. Une fois que vous avez des paroles et de la musique, vous pouvez fournir des retours en discutant avec l'IA pour concrétiser votre vision musicale.";
const featureParagraph3 = "La plateforme est équipée d'un dictionnaire intelligent intégré qui vous aide à trouver toutes sortes de mots associés pour fournir de l'inspiration et vous aider à compléter vos paroles.";
const featureParagraph4 = "Avec plus de 100 langues au choix, vous pouvez apprendre à écrire des paroles de chansons dans n'importe quelle langue!";
const faq1 = "Est-ce que je garde les droits d'auteur?";
const faq2 = "Le but de la plateforme est-il de rédiger complètement mes chansons pour moi?";
const faq3 = "Comment la plateforme m'aide-t-elle à surmonter le blocage de l'écrivain?";
const faq4 = "Comment la plateforme peut-elle booster mon processus d'écriture de chansons?";
const faqa1 = "Oui! Lyrical Labs est 100% libre de droits, donc vous conservez tous les droits sur la musique et les paroles que vous créez sur cette plateforme.";
const faqa2 = "Non, l'objectif de notre plateforme est de vous fournir une source d'inspiration infinie et de vous aider à surmonter le blocage de l'écrivain. Bien que l'IA soit capable de générer des ensembles complets de paroles et de mélodies musicales, nous vous encourageons à les utiliser comme point de départ et à ajouter votre touche personnelle. L'IA est là pour assister et inspirer, et non pour remplacer votre propre créativité.";
const faqa3 = "Le blocage de l'écrivain peut être une expérience frustrante et démoralisante pour tout auteur-compositeur. Mais avec notre plateforme, vous n'aurez jamais à vous soucier de manquer d'idées. Il suffit de saisir le thème et le style désirés, et l'IA vous fournira un flux continu d'idées créatives et originales à exploiter. Cela peut aider à relancer votre processus d'écriture de chansons et vous remettre sur les rails.";
const faqa4a = "Notre plateforme est conçue pour vous fournir un approvisionnement incessant d'idées et de suggestions basées sur vos préférences stylistiques choisies, vous donnant la liberté d'expérimenter et d'essayer quelque chose de nouveau.";
const faqa4b = "De plus, la plateforme vous permet d'écrire et d'éditer les paroles à votre guise, avec un dictionnaire de 'Mots Associés' très unique à vos côtés pour vous aider tout au long de votre processus d'écriture de chansons. C'est comme avoir votre propre assistant créatif personnel, adapté à vos propres goûts et besoins en matière d'écriture de chansons!";
const quote1 = "C'est un véritable changement de jeu, cela m'a vraiment aidé à écrire ma première chanson";
const quote2 = "Produit brillant, je le recommande à quiconque veut apprendre à écrire des chansons";
const quote3 = "Excellent service client, vraiment cool pour apprendre à écrire des chansons";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}

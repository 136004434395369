import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function RandomMusicGenerator() {
const title = "Random Music Generator";
const keywords = "random music generator, AI music creation, lyric generator, music composition, songwriting assistant, songwriting app, music generation software, music generation tool, songwriting website, music creativity, AI songwriting";
const description = "Create unique songs with a random music generator powered by AI";
const longDescription = "Lyrical Labs is a pioneering music and lyric generation platform fueled by advanced AI, designed to unleash a torrent of creative musical possibilities. It's more than a songwriting assistant; it's a conduit for boundless creativity, providing you with music and lyrics that are randomly generated yet strikingly fitting for any project.";
const h1 = "Forge New Musical Frontiers with a Random Music Generator";
const h2 = "Discover the synergy of AI-powered lyrics and music for unparalleled creativity";
const sectionHeader = "Your Infinite Source of Musical Innovation";
const featureTitle1 = "Unpredictably brilliant compositions";
const featureTitle2 = "Tailored to your creative essence";
const featureTitle3 = "Explore new lyrical landscapes";
const featureTitle4 = "A universal language of randomness";
const featureParagraph1 = "Dive into an ocean of musical variety with an inexhaustible supply of randomly generated melodies and lyrics that inspire. Whether it's jazz, pop, rock, or beyond, the AI caters to every genre imaginable.";
const featureParagraph2 = "Harness the power of our AI to produce music and lyrics that resonate with your unique style. Provide inputs and feedback, and let the algorithm adapt to your personal artistic flair, crafting songs that feel intimately yours.";
const featureParagraph3 = "Venture beyond conventional boundaries with our lyric generator. It offers fresh perspectives and words you might never have considered, enriching your creative process and opening up new avenues for expression.";
const featureParagraph4 = "Our platform speaks the universal language of music, offering the tools to create and understand songwriting nuances in over 100+ languages, making music truly borderless.";
const faq1 = "Can I generate an entire song randomly?";
const faq2 = "Is the music truly random or can I influence it?";
const faq3 = "What makes this random music generator stand out?";
const faq4 = "How can I use the random music generator to its fullest?";
const faqa1 = "Absolutely! Lyrical Labs enables you to generate both music and lyrics randomly, giving you a starting point or a complete piece that's entirely unique.";
const faqa2 = "While the core concept revolves around randomness, you have the ability to guide the AI with your preferences, keywords, and feedback, making the outcome as tailored or as spontaneous as you wish.";
const faqa3 = "Our platform distinguishes itself by not only providing random music generation but also ensuring that each piece is adaptable to your specific creative desires, offering a blend of unpredictability and personal touch.";
const faqa4a = "Maximize your use of the generator by experimenting with different genres, moods, and themes. The more you interact and provide feedback, the more the AI can craft pieces that resonate with your personal style.";
const faqa4b = "In addition to generating music and lyrics, our platform's 'Related Words' dictionary and stylistic flexibility encourage you to explore and refine your songs, making every creation a collaborative effort between you and AI.";
const quote1 = "Never imagined random music generation could be this inspiring!";
const quote2 = "Lyrical Labs blew my mind - the perfect mix of randomness and control in songwriting.";
const quote3 = "I found my creative spark again thanks to this fantastic tool. The future of music is here!";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}

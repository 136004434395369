import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function Songs() {
const title = "Songs";
const keywords = "songs, lyric generator, music generator, song creation, song generator, music creation app, songwriting tool, AI music, AI lyrics, create songs, how to create songs";
const description = "Craft your own songs with our AI-powered lyric and music generators";
const longDescription = "Lyrical Labs is your ultimate solution for song creation, offering cutting-edge AI to generate both lyrics and music. Whether you're aiming for catchy choruses or compelling compositions, our platform is equipped to elevate your songwriting journey.";
const h1 = "Create Songs with the Ingenuity of AI";
const h2 = "Unlock endless musical possibilities with our AI-powered lyric and music generators.";
const sectionHeader = "Your Premier Song Creation Studio";
const featureTitle1 = "AI-driven song crafting";
const featureTitle2 = "Dual-powered: Lyrics and Music";
const featureTitle3 = "Adaptable to any genre";
const featureTitle4 = "Cross-lingual songwriting support";
const featureParagraph1 = "Dive into a world where music and lyrics come together effortlessly. Our AI utilizes deep learning to provide you with a diverse range of song creation possibilities, tailoring both lyrics and melodies to your style.";
const featureParagraph2 = "Experience the synergy of lyrics and music creation in one platform. Perfect your songs with AI that understands the balance between words and tunes, enhancing your creativity and output.";
const featureParagraph3 = "No matter the genre you're aiming for, our AI songwriting tool adapts to your needs, offering endless inspiration for pop, rock, jazz, or any music style you envision.";
const featureParagraph4 = "Express your musical ideas without language barriers. Our platform offers support for over 100 languages, making song creation accessible to everyone, everywhere.";
const faq1 = "Who retains the rights to the songs created?";
const faq2 = "Can the AI independently create songs without my input?";
const faq3 = "What can I do when I hit a creative block?";
const faq4 = "How does this tool enhance my songwriting?";
const faqa1 = "Absolutely. At Lyrical Labs, you maintain full copyright to the songs crafted through our platform, ensuring your creations remain uniquely yours.";
const faqa2 = "While our AI can generate songs autonomously, we believe in the magic of human creativity. Use our AI as a springboard for your ideas, adding personal flair to the AI's suggestions for truly bespoke compositions.";
const faqa3 = "Creative blocks are no match for our AI. Input your basic idea or mood, and let the platform provide a cascade of lyrical and musical suggestions to rekindle your inspiration and drive your songwriting forward.";
const faqa4a = "Our AI songwriting assistant offers a fresh perspective, supplying you with a plethora of musical ideas and lyrical content to explore. It's designed to stimulate creativity and encourage experimentation in your songwriting process.";
const faqa4b = "The 'Related Words' feature and our smart dictionary help you expand your lyrical ideas, ensuring you have all the tools at hand to craft your songs exactly as you envision them.";
const quote1 = "Changed the way I approach songwriting. The integration of lyrics and music generation is phenomenal.";
const quote2 = "A must-use tool for anyone looking to break into songwriting or shake up their process.";
const quote3 = "Supportive, innovative, and incredibly user-friendly. Lyrical Labs has become my go-to for all things song creation.";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}

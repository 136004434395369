import React from "react";
import Footer from "./Footer";

const PageNotFound = ({ setUser, user }) => {
    return (
        <>
            <p>
                Page not found - the path your entered did not match
                any React Router routes.
            </p>
            <Footer/>
        </>
    )

}


export default PageNotFound;

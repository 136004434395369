import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function RandomSongGenerator() {
const title = "Random Song Generator";
const keywords = "random song generator, AI music generator, lyric generator, music creation, song generator, song creation tool, music generator app, music generator platform, automatic songwriting, songwriting assistant, random music generator, how to generate songs";
const description = "Discover the magic of generating songs with AI";
const longDescription = "Lyrical Labs is a breakthrough platform that leverages AI to help you randomly generate songs - combining both lyrics and music in harmony. It's the ultimate assistant for your music creation needs, providing endless possibilities for song generation!";
const h1 = "Create Songs Instantly with Our Random Song Generator";
const h2 = "Unleash endless musical creativity with our AI-powered random song and lyric generator.";
const sectionHeader = "Your Infinite Source of Musical Inspiration";
const featureTitle1 = "Generate songs on the fly";
const featureTitle2 = "Dynamic lyric & music creation";
const featureTitle3 = "Customizable genre options";
const featureTitle4 = "Explore music from around the world";
const featureParagraph1 = "With our random song generator, get ready to embrace an endless flow of musical ideas, tunes, and lyrics. The power of AI brings to life new tracks that have never been heard before, tailored to your preferences.";
const featureParagraph2 = "Our AI synergizes lyrics and music to craft songs that resonate. You can guide the creation process, merging AI efficiency with your creative direction to produce remarkable songs.";
const featureParagraph3 = "Whether you're into pop, rock, jazz, or classical, our platform allows for a broad spectrum of genre selection. Tailor the generator to suit the musical style you wish to explore.";
const featureParagraph4 = "Dive into the global music scene by generating songs with a cultural twist. Our platform supports multiple languages and incorporates global musical elements to enrich your songwriting experience.";
const faq1 = "Can I alter the generated song?";
const faq2 = "What makes this generator different from others?";
const faq3 = "How can I use the generated songs?";
const faq4 = "How does this platform foster creativity?";
const faqa1 = "Absolutely! Although our platform provides a robust foundation, we encourage you to refine and tweak the generated music and lyrics to ensure they align perfectly with your vision.";
const faqa2 = "Our unique blend of advanced AI algorithms specializes in both music and lyric generation, offering a more cohesive and integrated songwriting experience. The emphasis on random generation ensures that each piece is original and tailored to your creative prompts.";
const faqa3 = "The generated songs are yours to use as you please. Whether it's for personal enjoyment, commercial use, or as a base for further creative exploration, Lyrical Labs empowers you to take your music wherever you desire.";
const faqa4a = "Our random song generator is designed to provide a never-ending array of musical and lyrical ideas, pushing the boundaries of your creativity and encouraging experimentation.";
const faqa4b = "The platform's ability to merge different elements of music and lyrics randomly creates a fertile ground for unexpected inspiration, allowing you to explore new horizons in songwriting.";
const quote1 = "'I was amazed at how quickly I could come up with a complete song idea.'";
const quote2 = "'The random song generator is simply revolutionary for sparking creativity.'";
const quote3 = "'Love how it blends lyrics and melodies so seamlessly. A major asset for any musician.'";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}

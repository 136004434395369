import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function BeatMaker() {
const title = "beat maker";
const keywords = "beat maker, music generator, beats, music production, beat making software, beat making app, beat making platform, beat making tool, beat making tools, beat making website, how to make beats";
const description = "Create professional beats with AI";
const longDescription = "Lyrical Labs is a beat making platform that employs AI to help you craft the perfect beat and music, integrating seamlessly with lyrics generation. It's your ultimate beat making assistant, tailored to your unique music production needs!";
const h1 = "Create beats like a pro using the latest AI technology.";
const h2 = "Unlock endless musical possibilities with our AI-powered beat and lyrics.";
const sectionHeader = "Your Ultimate Beat Creation Studio";
const featureTitle1 = "Innovative beat crafting";
const featureTitle2 = "AI-driven music and lyric generator";
const featureTitle3 = "Rich library of sounds";
const featureTitle4 = "Multilingual beat and lyric creation";
const featureParagraph1 = "Dive into a world where inventive beats are at your fingertips. With AI, generate rhythms and melodies that capture any genre, mood, or vibe you are aiming for.";
const featureParagraph2 = "Our platform doesn’t just create beats; it also generates lyrics to match. Collaborate with our AI to blend beats and words perfectly, pushing the boundaries of music production.";
const featureParagraph3 = "Access an extensive collection of unique sounds and instruments, all powered by AI to enrich your beats. From traditional to avant-garde, the choice is yours.";
const featureParagraph4 = "Broaden your musical horizons by creating beats and lyrics in over 100+ languages. With Lyrical Labs, there are no limits to your creativity.";
const faq1 = "Can I use the beats for commercial purposes?";
const faq2 = "Does the AI do all the work in creating beats?";
const faq3 = "How does the platform ensure my beats are unique?";
const faq4 = "Can I input my own melodies or lyrics?";
const faqa1 = "Absolutely! Lyrical Labs is royalty-free, meaning you retain all rights to the beats and lyrics you create, ready for commercial use.";
const faqa2 = "While our AI significantly aids in the beat making process, we encourage your input and creativity. The AI serves as a co-producer, providing a foundation and inspiration for you to build upon.";
const faqa3 = "Our advanced AI is designed to generate one-of-a-kind beats and lyrics, ensuring your creations stand out. Input your style and preferences, and our AI will do the rest, crafting distinct sounds just for you.";
const faqa4a = "Definitely! Our platform is flexible, allowing you to introduce your melodies or start from scratch with AI suggestions. It supports complete customization to bring your musical vision to life.";
const faqa4b = "Yes, collaboration with your input is key. You can add, edit, or refine lyrics and beats, giving you full control over the creative process. Our 'Related Words' dictionary and sound library are at your disposal to inspire and enhance your productions.";
const quote1 = "Lyrical Labs has revolutionized the way I produce music. Creating beats has never been easier or more fun!";
const quote2 = "A must-have tool for any aspiring beat maker. The AI integration is seamless and intuitive!";
const quote3 = "Exceptional service and incredible results. Lyrical Labs has become an essential part of my music making process.";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}

import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function CommentFaireDeLaMusique() {
const title = "Comment Faire De La Musique";
const keywords = "faire de la musique, générateur de musique, générateur de paroles, assistant d'écriture de chansons, plateforme d'écriture de chansons, logiciel d'écriture de chansons, outils d'écriture de chansons, site web d'écriture de chansons, comment faire de la musique";
const description = "Apprenez à créer des chansons avec une IA";
const longDescription = "Lyrical Labs est une plateforme de composition qui utilise l'IA pour vous aider à créer des paroles et des mélodies musicales plus rapidement. C'est votre assistant personnel de composition, adapté à vos goûts et besoins personnels !";
const h1 = "Apprenez à faire de la musique avec le pouvoir de l'IA.";
const h2 = "Révolutionnez votre processus de création de chansons avec nos paroles générées par IA.";
const sectionHeader = "Votre compagnon ultime en création de chansons";
const featureTitle1 = "Créativité sans limites";
const featureTitle2 = "Votre assistant personnel de composition";
const featureTitle3 = "Dictionnaire intelligent intégré";
const featureTitle4 = "Écrivez dans n'importe quelle langue";
const featureParagraph1 = "Une inspiration sans fin pour la composition de chansons vous attend avec un approvisionnement continu d'idées originales et de mélodies. L'IA peut écrire sur n'importe quel sujet et générer de la musique de tout genre";
const featureParagraph2 = "Collaborez avec notre partenaire IA pour composer la chanson parfaite. Une fois que vous avez les paroles et la musique, vous pouvez fournir des retours à l'IA pour concrétiser votre vision musicale.";
const featureParagraph3 = "La plateforme est équipée d'un dictionnaire intelligent intégré qui vous aide à trouver toutes sortes de mots associés pour vous inspirer et vous aider à terminer vos paroles.";
const featureParagraph4 = "Avec plus de 100+ langues disponibles, vous pouvez apprendre à écrire des paroles de chansons dans n'importe quelle langue !";
const faq1 = "Gardez-vous les droits d'auteur ?";
const faq2 = "Le but de la plateforme est-il de composer entièrement mes chansons pour moi ?";
const faq3 = "Comment la plateforme m'aide-t-elle à surmonter le blocage de l'écrivain ?";
const faq4 = "Comment la plateforme améliore-t-elle mon processus de création de chansons ?";
const faqa1 = "Oui ! Lyrical Labs est 100% sans royalties, donc vous conservez tous les droits sur la musique et les paroles que vous créez sur cette plateforme.";
const faqa2 = "Non, le but de notre plateforme est de vous fournir une source inépuisable d'inspiration et de vous aider à surmonter le blocage de l'écrivain. Bien que l'IA soit capable de générer des ensembles complets de paroles et de mélodies musicales, nous vous encourageons à les utiliser comme point de départ et à ajouter votre touche personnelle. L'IA est là pour assister et inspirer, et non pour remplacer votre créativité.";
const faqa3 = "Le blocage de l'écrivain peut être une expérience frustrante et démoralisante pour tout compositeur. Mais avec notre plateforme, vous n'aurez jamais à vous soucier de manquer d'idées. Entrez simplement le thème et le style désirés, et l'IA vous fournira un flux continu d'idées créatives et originales avec lesquelles travailler. Cela peut aider à démarrer votre processus de création de chansons et vous remettre sur les rails.";
const faqa4a = "Notre plateforme est conçue pour vous fournir un approvisionnement sans fin d'idées et de suggestions basées sur vos préférences stylistiques choisies, vous donnant la liberté d'expérimenter et d'essayer quelque chose de nouveau.";
const faqa4b = "";
const quote1 = "C'est un changement de jeu, cela m'a vraiment aidé à écrire ma première chanson";
const quote2 = "Produit brillant, je le recommanderais à quiconque veut apprendre à faire de la musique";
const quote3 = "Excellent service client, vraiment cool pour apprendre à faire de la musique";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}

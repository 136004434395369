import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function HowToBeASongwriter() {
const title = "How To Be A Songwriter";
const keywords = "songwriter, music generator, lyrics generator, becoming a songwriter, songwriter assistant, songwriter app, songwriter platform, songwriter software, songwriter tool, songwriter tools, songwriter website, how to be a songwriter";
const description = "Discover the path to becoming a songwriter with AI";
const longDescription = "Lyrical Labs is a songwriter platform that leverages AI to aid in crafting both lyrics and music easily. It acts as your personal songwriter assistant, suited to your unique tastes and songwriter needs!";
const h1 = "Discover how to be a songwriter with the power of AI.";
const h2 = "Transform your songwriting journey with our AI-powered lyrics and melodies.";
const sectionHeader = "The Essential Songwriter's Toolkit";
const featureTitle1 = "Unleash boundless creativity";
const featureTitle2 = "Your dedicated songwriter assistant";
const featureTitle3 = "Innovative smart dictionary";
const featureTitle4 = "Compose in any tongue";
const featureParagraph1 = "Step into a world of endless songwriter inspiration with a continuous stream of unique ideas and tunes. Our AI can delve into any topic, crafting music of any genre to fit your needs.";
const featureParagraph2 = "Work alongside our AI songwriter partner to develop the perfect piece. From initial lyrics to the final tune, converse with the AI, tweaking your vision into existence.";
const featureParagraph3 = "Equipped with a cutting-edge smart dictionary, our platform ensures you're never at a loss for words. It offers a wealth of related terms for inspiration, smoothing the lyric-writing process.";
const featureParagraph4 = "Embrace global creativity with support for 100+ languages. Discover the art of songwriting in the language of your choice, broadening your creative horizons.";
const faq1 = "Who retains the copyright?";
const faq2 = "Does this platform generate entire songs for me?";
const faq3 = "Can it help me when I'm stuck for ideas?";
const faq4 = "In what ways does it enhance my songwriting workflow?";
const faqa1 = "Absolutely! Lyrical Labs ensures 100% royalty-free creations, granting you complete rights over the music and lyrics you produce.";
const faqa2 = "While our platform can inspire and generate comprehensive sets of lyrics and melodies, we view these as starting points. We encourage your personal flair, supporting you in the journey towards becoming a songwriter without overshadowing your talent.";
const faqa3 = "Songwriter's block is a real challenge, but it's one you won't face alone. Input your preferences, and watch as the AI conjures a flurry of inventive and fresh ideas to propel you forward.";
const faqa4a = "Designed to supply a plethora of ideas and suggestions aligned with your stylistic choices, our platform encourages you to explore new territories freely.";
const faqa4b = "Moreover, it enables lyric composition and modification at your pace, augmented by a 'Related Words' dictionary to enhance your songwriting journey. Consider it your on-call creative sidekick, tailored precisely to your songwriting ambitions.";
const quote1 = "A total gamechanger, instrumental in composing my debut song";
const quote2 = "Incredible tool, highly recommended for anyone on the path to becoming a songwriter";
const quote3 = "Outstanding customer service, a revolutionary method for songwriting education";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}

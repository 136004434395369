import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function HowToComposeMusic() {
const title = "How To Compose Music";
const keywords = "compose music, music generator, melody composition, composition software, composition tool, music composition app, music composition platform, music composition website, composing music, how to compose music";
const description = "Master the art of music composition with AI";
const longDescription = "Lyrical Labs is a pioneering music composition platform that harnesses AI for crafting both intricate melodies and compelling lyrics. It serves as your dual-function composition assistant, tailored to your unique musical tastes and needs!";
const h1 = "Master music composition with AI’s revolutionary capabilities.";
const h2 = "Elevate your music composition with our AI-driven lyrics and melodies.";
const sectionHeader = "The Premier Music Composition Ally";
const featureTitle1 = "Unbounded creative possibilities";
const featureTitle2 = "Dual-function composition assistant";
const featureTitle3 = "Smart inspiration dictionary";
const featureTitle4 = "Multilingual composition support";
const featureParagraph1 = "Unlock endless musical creativity with a limitless array of melody and lyric ideas. The AI delves into any theme or genre, offering you a bounty of composition material.";
const featureParagraph2 = "Partner with our AI to seamlessly integrate exceptional lyrics with captivating melodies. Remarkable compositions come to life through an interactive feedback loop with the AI, shaping your musical creation.";
const featureParagraph3 = "Our platform includes a sophisticated dictionary for instant access to a vast network of related words and phrases, designed to spark inspiration and refine your compositions.";
const featureParagraph4 = "Compose in over 100 languages, broadening your horizon and enabling you to communicate your musical vision universally!";
const faq1 = "Who retains the copyright of compositions?";
const faq2 = "Does the platform compose music for me by default?";
const faq3 = "How can the platform alleviate my compositional block?";
const faq4 = "In what ways does it enhance my music composition process?";
const faqa1 = "Absolutely! Lyrical Labs ensures that all compositions created are yours to own, 100% royalty-free, granting you full copyright over your music and lyrics.";
const faqa2 = "While our AI has the capacity to generate both lyrics and melodies, the essence of our platform is to inspire and to aid. We urge composers to imbue their personal flair into the AI’s creations, enriching the composition with their own uniqueness.";
const faqa3 = "Facing a block is common in the journey of composition. Lyrical Labs offers a remedy by providing a constant stream of new and original melodies and lyrics, tailored to your style preferences, effectively overcoming any creative standstill.";
const faqa4a = "Our platform is your personal library of ideas, presenting an array of suggestions and innovations that align with your musical preference. It's a space for experimentation, enabling you to explore diverse compositional avenues.";
const faqa4b = "Moreover, with a unique 'Related Words' tool at your disposal, you can edit and refine lyrics seamlessly, making Lyrical Labs not just an ally in composition but a cornerstone in your musical journey.";
const quote1 = "'Lyrical Labs revolutionized my approach to composing music, making it effortlessly creative.'";
const quote2 = "'A vital tool for anyone looking to expand their musical vocabulary and composition techniques.'";
const quote3 = "'Their support goes beyond technology; they truly understand the heart of a musician.'";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}

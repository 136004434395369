import React, { useEffect, useRef, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faDownload } from '@fortawesome/free-solid-svg-icons';


const AudioWaveform = ({ url }) => {
    const waveformRef = useRef(null);
    const wavesurfer = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);

    useEffect(() => {
        wavesurfer.current = WaveSurfer.create({
          container: waveformRef.current,
          waveColor: 'white',
          progressColor: '#7c3aed',
          backend: 'WebAudio',
          height: 30,
          width: '100%',
          barWidth: 2
        });

        wavesurfer.current.load(url);

        return () => wavesurfer.current.destroy();
    }, [url]);

    const handlePlayPause = () => {
      wavesurfer.current.playPause();
      isPlaying ? setIsPlaying(false) : setIsPlaying(true);
  };

  const handleDownload = () => {
      // Trigger download of the .wav file
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = url;
      a.download = url.split('/').pop(); // Extract filename from URL
      a.click();
      document.body.removeChild(a);
  };

  return (
    <div>
        <div className="my-2 px-5 py-5 rounded-md bg-white/10 lg:mr-0">
            <div ref={waveformRef}>
            </div>
            <div className="flex justify-start mt-4">
                    <button onClick={handlePlayPause}>
                        { isPlaying ? <FontAwesomeIcon icon={faPause} /> : <FontAwesomeIcon icon={faPlay} /> }
                    </button>
                    <button onClick={handleDownload} className="mx-6">
                        <FontAwesomeIcon icon={faDownload} />
                    </button>
            </div>
        </div>
    </div>
);

};



export default AudioWaveform;

import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function WritingLyrics() {
const title = "Writing Lyrics";
const keywords = "writing lyrics, lyric generator, music generator, lyrics, songwriting, lyric writing software, lyric writing app, lyric writing platform, music composition, AI lyricist, AI music composer, how to write lyrics";
const description = "Master the art of writing lyrics with AI";
const longDescription = "Lyrical Labs is a futuristic platform blending music and lyric generation through AI, simplifying the lyric writing process while enhancing your musical compositions. It's the dual force you need for creating compelling songs tailored to your taste!";
const h1 = "Master writing lyrics and composing music with our AI technology.";
const h2 = "Transform your songwriting with AI-powered lyrics and music composition.";
const sectionHeader = "The Ultimate Lyric Writing and Music Composition Tool";
const featureTitle1 = "Seamless lyric creation";
const featureTitle2 = "Music composition at its finest";
const featureTitle3 = "AI-powered lyric and music innovation";
const featureTitle4 = "Versatility in song creation";
const featureParagraph1 = "Dive into an infinite pool of lyric possibilities. Whether you're starting from scratch or have a vague idea, our AI lyric generator provides you with fresh, original lyric concepts fit for any music genre.";
const featureParagraph2 = "Not just lyrics – our platform crafts the musical essence of your song. From melody to harmony, experience the thrill of co-creating music with an AI that understands the complexity of composition.";
const featureParagraph3 = "Harness the power of AI to revolutionize your lyric writing and music composition process. Our platform offers a unique blend of creativity and technology, pushing the boundaries of what's possible in song creation.";
const featureParagraph4 = "From pop to jazz, write lyrics and compose music without limitations. Our AI understands various musical genres and languages, empowering you to express yourself in any way you choose.";
const faq1 = "Will I maintain ownership of the lyrics and music created?";
const faq2 = "Can the AI create a complete song?";
const faq3 = "How does the platform support my creative process?";
const faq4 = "What makes this platform unique in aiding lyric writing and music composition?";
const faqa1 = "Absolutely! Everything created via Lyrical Labs is yours to keep, enabling you to retain full copyright of the lyrics and music you generate.";
const faqa2 = "Yes, our AI is designed to generate both lyrics and music, providing a comprehensive solution to song creation. We envision it as a starting point from which you can refine and personalize.";
const faqa3 = "Whether you're facing a creative block or just need a spark of inspiration, our platform is always ready to provide innovative ideas for lyrics and melodies, pushing you past creative boundaries.";
const faqa4a = "The unique combination of lyric and music generation powered by AI sets our platform apart, offering a seamless and integrative approach to songwriting.";
const faqa4b = "Our platform includes an innovative 'Idea Engine' and a 'Musical Sketchpad' designed to adapt to your creative workflow, providing suggestions that resonate with your artistic vision.";
const quote1 = "Lyrical Labs turned my snippets of thoughts into a full-fledged song. Astonishing!";
const quote2 = "A perfect merge of lyrics and music generation. It's like having a co-writer who never runs out of ideas.";
const quote3 = "The ease of writing lyrics and composing music in one place is unmatched. Impressive tech support too!";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}

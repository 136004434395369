import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function GénérateurDeMusiqueAiGratuit() {
const title = "Générateur De Musique Ai Gratuit";
const keywords = "générateur de musique AI gratuit, générateur de paroles, générateur de musique, écriture de chansons, assistant d'écriture de chansons, application d'écriture de chansons, plateforme d'écriture de chansons, logiciel d'écriture de chansons, outil d'écriture de chansons, outils d'écriture de chansons, site web d'écriture de chansons, comment écrire une chanson";
const description = "Apprenez à écrire une chanson avec un générateur de musique AI gratuit";
const longDescription = "Lyrical Labs est une plateforme d'écriture de chansons qui utilise l'AI pour vous aider à créer des paroles et des mélodies musicales rapidement. C'est votre assistant personnel d'écriture de chansons, adapté à vos goûts personnels et à vos besoins en matière d'écriture de chansons!";
const h1 = "Apprenez à écrire une chanson avec le pouvoir de l'AI gratuit.";
const h2 = "Révolutionnez votre écriture de chansons et obtenez une inspiration infinie avec notre générateur de paroles et de musique AI.";
const sectionHeader = "Votre compagnon d'écriture de chansons ultime";
const featureTitle1 = "Créativité sans limites";
const featureTitle2 = "Votre assistant personnel d'écriture de chansons";
const featureTitle3 = "Dictionnaire intelligent intégré";
const featureTitle4 = "Écrivez dans n'importe quelle langue";
const featureParagraph1 = "Une inspiration sans fin pour l'écriture de chansons vous attend avec une fourniture incessante d'idées originales et de mélodies. L'AI peut écrire sur n'importe quel sujet et générer de la musique de n'importe quel genre.";
const featureParagraph2 = "Collaborez avec notre partenaire AI pour composer la chanson parfaite. Une fois que vous avez les paroles et la musique, vous pouvez fournir des retours en parlant à l'AI pour concrétiser votre vision musicale.";
const featureParagraph3 = "La plateforme est équipée d'un dictionnaire intelligent intégré qui vous aide à trouver toutes sortes de mots associés pour offrir de l'inspiration et vous aider à compléter vos paroles.";
const featureParagraph4 = "Avec plus de 100+ langues disponibles, vous pouvez apprendre à écrire des paroles de chanson dans n'importe quelle langue!";
const faq1 = "Est-ce que je garde les droits d'auteur?";
const faq2 = "Le but de la plateforme est-il de rédiger mes chansons pour moi?";
const faq3 = "Comment la plateforme m'aide-t-elle à surmonter le blocage de l'écrivain?";
const faq4 = "Comment la plateforme augmente-t-elle mon processus d'écriture de chansons?";
const faqa1 = "Oui! Lyrical Labs est 100% libre de droits, vous conservez donc tous les droits sur la musique et les paroles que vous créez sur cette plateforme.";
const faqa2 = "Non, l'objectif de notre plateforme est de vous fournir une source infinie d'inspiration et d'aide pour surmonter le blocage de l'écrivain. Bien que l'AI soit capable de générer des ensembles complets de paroles et de mélodies musicales, nous vous encourageons à les utiliser comme point de départ et à ajouter votre propre touche personnelle. L'AI est là pour assister et inspirer, et non pour remplacer votre propre créativité.";
const faqa3 = "Le blocage de l'écrivain peut être une expérience frustrante et décourageante pour tout auteur de chansons. Mais avec notre plateforme, vous n'aurez jamais à craindre de manquer d'idées. Entrez simplement votre thème et style désirés, et l'AI vous fournira un flux continu d'idées créatives et originales avec lesquelles travailler. Cela peut aider à démarrer votre processus d'écriture de chansons et vous remettre sur les rails.";
const faqa4a = "Notre plateforme est conçue pour vous fournir une source infinie d'idées et de suggestions basées sur vos préférences stylistiques choisies, vous donnant la liberté d'expérimenter et d'essayer quelque chose de nouveau.";
const faqa4b = "De plus, la plateforme vous permet d'écrire et d'éditer les paroles à votre guise, avec un dictionnaire de 'Mots Associés' hautement unique à vos côtés pour vous assister tout au long de votre processus d'écriture de chansons. C'est comme avoir votre propre assistant créatif personnel, adapté à vos propres goûts et besoins en matière d'écriture de chansons!";
const quote1 = "C'est un changement de jeu, cela m'a vraiment aidé à écrire ma première chanson";
const quote2 = "Produit brillant, je le recommanderais à quiconque veut apprendre à écrire une chanson";
const quote3 = "Excellent service client, vraiment cool pour apprendre à écrire une chanson";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}

import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function AuteurCompositeurInterprète() {
const title = "Auteur Compositeur Interprète";
const keywords = "auteur-compositeur-interprète, générateur de musique, générateur de paroles, aide à l'écriture de chansons, plateforme de composition, logiciel de composition, outils de composition, site web de composition, comment composer une chanson";
const description = "Apprenez à devenir un auteur-compositeur-interprète avec l'IA";
const longDescription = "Lyrical Labs est une plateforme de composition qui utilise l'IA pour vous aider à écrire des paroles et composer de la musique plus rapidement. C'est votre assistant personnel de composition, adapté à vos propres goûts musicaux et besoins de création !";
const h1 = "Devenez auteur-compositeur-interprète grâce au pouvoir de l'IA.";
const h2 = "Révolutionnez votre processus de création et obtenez une inspiration infinie avec nos paroles générées par l'IA.";
const sectionHeader = "Votre compagnon de composition ultime";
const featureTitle1 = "Créativité sans limites";
const featureTitle2 = "Votre assistant personnel de composition";
const featureTitle3 = "Dictionnaire intelligent intégré";
const featureTitle4 = "Composez dans n'importe quelle langue";
const featureParagraph1 = "Une inspiration infinie pour la composition vous attend avec un approvisionnement jamais épuisé d'idées originales et de mélodies. L'IA peut écrire sur n'importe quel sujet et générer de la musique de tout genre.";
const featureParagraph2 = "Collaborez avec notre partenaire de composition IA pour créer la chanson parfaite. Une fois que vous avez les paroles et la musique, vous pouvez donner votre avis en parlant à l'IA pour concrétiser votre vision musicale.";
const featureParagraph3 = "La plateforme est équipée d'un dictionnaire intelligent intégré qui vous aide à trouver toutes sortes de mots liés pour vous inspirer et vous aider à compléter vos paroles.";
const featureParagraph4 = "Avec plus de 100+ langues au choix, vous pouvez apprendre à écrire des paroles de chansons dans n'importe quelle langue !";
const faq1 = "Ai-je le droit d'auteur ?";
const faq2 = "Le but de la plateforme est-il de composer mes chansons pour moi ?";
const faq3 = "Comment la plateforme m'aide-t-elle à surmonter le blocage de l'écrivain ?";
const faq4 = "Comment la plateforme stimule-t-elle mon processus de composition ?";
const faqa1 = "Oui ! Lyrical Labs est 100% libre de droits, donc vous conservez tous les droits sur la musique et les paroles que vous créez sur cette plateforme.";
const faqa2 = "Non, l'objectif de notre plateforme est de vous fournir une source infinie d'inspiration et d'aide pour surmonter le blocage de l'écrivain. Bien que l'IA soit capable de générer des ensembles complets de paroles et des mélodies musicales, nous vous encourageons à les utiliser comme point de départ et à y ajouter votre touche personnelle. L'IA est là pour assister et inspirer, et non pour remplacer votre propre créativité.";
const faqa3 = "Le blocage de l'écrivain peut être une expérience frustrante et démoralisante pour tout compositeur. Mais avec notre plateforme, vous n'aurez jamais à vous soucier de manquer d'idées. Entrez simplement votre thème et style souhaités, et l'IA vous fournira un flux continu d'idées créatives et originales avec lesquelles travailler. Cela peut aider à relancer votre processus de composition et vous remettre sur les rails.";
const faqa4a = "Notre plateforme est conçue pour vous fournir un approvisionnement continu d'idées et de suggestions basées sur vos préférences stylistiques choisies, vous donnant la liberté d'expérimenter et d'essayer quelque chose de nouveau.";
const faqa4b = "De plus, la plateforme vous permet d'écrire et d'éditer les paroles à votre guise, avec un dictionnaire 'Mots Associés' très unique à vos côtés pour vous aider tout au long de votre processus de composition. C'est comme avoir votre propre assistant créatif personnel, adapté à vos propres goûts et besoins en matière de composition !";
const quote1 = "Ceci est un changeur de jeu, cela m'a vraiment aidé à écrire ma première chanson";
const quote2 = "Produit brillant, je le recommanderais à quiconque souhaite apprendre à composer une chanson";
const quote3 = "Service client excellent, vraiment cool pour apprendre à composer une chanson";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}

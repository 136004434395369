import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function WritingMusic() {
const title = "Writing Music";
const keywords = "writing music, lyric generator, music generator, song creation, music creation assistant, music generation app, music writing platform, music composition software, music writing tool, music writing tools, music writing website, how to write music";
const description = "Discover the art of writing music with AI";
const longDescription = "Lyrical Labs is a pioneering music writing platform that leverages AI to assist you in composing both lyrics and music effortlessly. It serves as your personal music creation assistant, tailored to your individual music writing needs and preferences!";
const h1 = "Master the art of writing music with the help of AI.";
const h2 = "Transform your music writing journey and find limitless inspiration with our AI-powered music and lyrics generator.";
const sectionHeader = "Your Ultimate Music Writing Partner";
const featureTitle1 = "Infinite musical inspiration";
const featureTitle2 = "A dual-powered creative assistant";
const featureTitle3 = "Advanced lyrical and musical dictionary";
const featureTitle4 = "Compose in any language";
const featureParagraph1 = "Unlock endless inspiration for your music writing with a boundless source of original melodies and lyrical ideas. Our AI is versatile, capable of generating music and lyrics across various topics and genres.";
const featureParagraph2 = "Work alongside our AI music and lyric generator to create the perfect composition. After crafting the initial music and lyrics, interact with the AI for refinements and adjustments to perfectly align with your creative vision.";
const featureParagraph3 = "Armed with an advanced dictionary for both lyrics and musical terms, our platform enhances your writing process by suggesting related words and phrases, sparking inspiration for your compositions.";
const featureParagraph4 = "Offering support for over 100 languages, Lyrical Labs enables you to explore music writing and lyricism in multiple languages, broadening your creative horizons.";
const faq1 = "Do I retain the rights to the music and lyrics created?";
const faq2 = "Does the platform aim to entirely create music for me?";
const faq3 = "How can the platform assist me in breaking through creative blocks?";
const faq4 = "What features does the platform offer to enhance my music writing process?";
const faqa1 = "Absolutely! Lyrical Labs ensures that anything created on our platform is 100% royalty-free, granting you full ownership of the music and lyrics.";
const faqa2 = "Our platform is designed to serve as an endless wellspring of inspiration and a solution for overcoming creative hurdles. While the AI has the capability to generate comprehensive musical compositions and lyrics, we encourage creators to infuse their unique flair and creativity into the mix. Consider the AI as a collaborative partner rather than a replacement.";
const faqa3 = "Creative blocks can be a major setback for music writers. Lyrical Labs offers a solution by providing a ceaseless stream of innovative music and lyrical ideas based on your input regarding theme and style. This can serve as a catalyst for your music writing process, getting the creative juices flowing once again.";
const faqa4a = "Lyrical Labs is crafted to offer an infinite array of ideas and musical concepts, catering to your stylistic preferences. This opens up avenues for experimentation and discovering new creative paths.";
const faqa4b = "Furthermore, the platform empowers you to mold and tweak lyrics and music to your heart’s content, supported by a unique 'Related Words' dictionary and musical term suggestions to enhance your music writing journey. It’s akin to having a personal muse and assistant combined, adapted to meet your music and lyric writing needs.";
const quote1 = "A revolutionary tool that simplified the music writing process for me";
const quote2 = "A must-have for anyone interested in exploring music composition. Highly recommended!";
const quote3 = "Outstanding support and an innovative way to approach music writing";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}

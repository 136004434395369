import axios from 'axios';

const url = 'https://frozen-springs-61240.herokuapp.com'

export function checkUserToken(token) {
  return fetch(`${url}/welcome`, {
    method: "POST",
    mode: 'cors',
    headers: {
      "x-access-token": token,
    }
  }).then(data => data.ok)
}

export function retrievePaymentIntent(paymentIntentId){
  return fetch(`${url}/retrieve-payment-intent`, {
    method: "POST",
    mode: 'cors',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      paymentIntentId,
    })
  }).then(data => data.json())
}

export const getIpAddress = async () => {
  const res = await axios.get(`${url}/getIp`).then(res => res.data)
  return res;
}
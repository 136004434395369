import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function FreeSongwritingApp() {
const title = "Free Songwriting App";
const keywords = "free songwriting app, lyrics generator, music generator, song creation, songwriting assistant, songwriting tool, AI songwriting, melody creation, free music app, free lyrics app, AI music, AI lyrics";
const description = "Craft songs effortlessly with a free songwriting app powered by AI";
const longDescription = "Lyrical Labs offers a revolutionary approach to creating music and lyrics with its free songwriting app. Harness the power of AI for an unparalleled songwriting experience that molds to your creative process!";
const h1 = "Craft your next masterpiece with a leading free songwriting app";
const h2 = "Unleash your songwriting potential with AI-generated music and lyrics";
const sectionHeader = "Your Gateway to Infinite Musical Creativity";
const featureTitle1 = "Zero cost, limitless potential";
const featureTitle2 = "Seamless lyrics and melody generation";
const featureTitle3 = "Creative spark at your fingertips";
const featureTitle4 = "Diverse musical styles and languages";
const featureParagraph1 = "Dive into songwriting without any fees. Lyrical Labs' free songwriting app provides endless inspiration and ideas, empowering you to generate music and lyrics for any genre or theme you desire.";
const featureParagraph2 = "Our app assists you in creating both captivating lyrics and compelling melodies. Engage with the AI, input your desires, and watch as it crafts components that align perfectly with your vision.";
const featureParagraph3 = "Whether you're facing writer’s block or seeking fresh ideas, our app offers a plethora of suggestions and musical elements to spark your creativity and enhance your songwriting journey.";
const featureParagraph4 = "Bridge language barriers and explore various melodies to match your lyrics. Our free songwriting app supports multiple languages, enabling you to connect with a wider audience through your music.";
const faq1 = "Is this app truly free to use?";
const faq2 = "Can this app help me create a complete song?";
const faq3 = "What kind of music can I generate with this app?";
const faq4 = "How does the app adapt to my personal songwriting style?";
const faqa1 = "Absolutely! Our platform is completely free, offering full access to all songwriting and melody generation features without any cost.";
const faqa2 = "Yes, while the app offers robust tools for generating lyrics and music, we encourage you to infuse your unique touch. It serves as a foundational tool, helping to conceptualize and refine your musical ideas.";
const faqa3 = "From pop to rock, classical to electronic, our versatile app caters to a wide array of genres. Input your preferred style, and the AI will generate music and lyrics to suit.";
const faqa4a = "The app learns from your usage patterns, preferences, and feedback, adjusting its suggestions to better fit your unique songwriting needs and preferences.";
const faqa4b = "It also offers suggestions and a 'Related Words' dictionary to help refine your lyrics, ensuring that the app is a perfect match for your creative process.";
const quote1 = "Needed inspiration and this free songwriting app was a lifesaver. Unbelievably good!";
const quote2 = "The perfect tool for anyone on the fence about songwriting. Makes it easy and fun!";
const quote3 = "Being able to generate music and lyrics without worrying about costs? Incredible. Lyrical Labs nails it.";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}

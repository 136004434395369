import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function AiBeatGenerator() {
const title = "Ai Beat Generator";
const keywords = "AI beat generator, beat generator, music generator, AI music, beat making, beat making app, beat making platform, beat making software, beat making tool, beat making tools, beat making website, how to make beats";
const description = "Create captivating beats with AI";
const longDescription = "Lyrical Labs is your go-to platform for unleashing your music production potential. With our AI Beat Generator, we offer an unparalleled experience in crafting rhythms and beats alongside your lyrics, making it your all-in-one solution for song creation!";
const h1 = "Craft Your Own Beats with the Power of AI";
const h2 = "Elevate Your Music Production with Our AI-Powered Beat and Lyrics Creation";
const sectionHeader = "The Ultimate Beat Making Companion";
const featureTitle1 = "Innovative beat creation";
const featureTitle2 = "Dual-function music and lyric generator";
const featureTitle3 = "Expansive music genre versatility";
const featureTitle4 = "Global beat styles";
const featureParagraph1 = "Unlock the full potential of beat making with AI-driven creativity, generating unique and captivating beats that stand out.";
const featureParagraph2 = "Simultaneously produce stunning lyrics and matching beats, fostering a seamless song creation process with our integrated AI algorithms.";
const featureParagraph3 = "Whether you're into hip-hop, EDM, country, or jazz, our AI Beat Generator adapts to your genre preferences, offering limitless creativity.";
const featureParagraph4 = "Explore beats from around the world with support for various global music styles, enhancing your music with a touch of cultural diversity.";
const faq1 = "Can AI truly capture the essence of music genres?";
const faq2 = "How involved can I be in the beat-making process?";
const faq3 = "Does the AI accommodate various music production skill levels?";
const faq4 = "What makes this AI beat generator stand out from others?";
const faqa1 = "Absolutely! Our AI Beat Generator is trained on a vast array of music from different genres, ensuring authentic and genre-fitting beats every time.";
const faqa2 = "You have full control over the beat-making process, from initial input for genre and mood to tweaking the final output. The AI is here to complement and amplify your creativity.";
const faqa3 = "Yes, our platform is designed for music creators of all skill levels, offering intuitive controls for beginners while still providing advanced options for seasoned producers.";
const faqa4a = "Our unique combination of lyric and music generation sets us apart, offering a holistic song creation tool that's flexible and deeply integrated.";
const faqa4b = "Additionally, the AI's ability to adapt to user feedback and learning ensures a continuously improving and personalized beat-making experience.";
const quote1 = "Absolutely mind-blowing, the beats it produces are top-notch!";
const quote2 = "Makes song creation a breeze, incredibly intuitive and powerful.";
const quote3 = "The perfect blend of lyrics and beats, couldn't ask for a better tool.";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}

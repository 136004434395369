import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom/client';
import { LockClosedIcon } from '@heroicons/react/20/solid'
import Footer from '../components/Footer';
// import '../App.css';

function ForgotPassword({ user, setUser }) {

  //This page is for the user to arrive at after clicking the password reset link in their email
  //The email address is pulled from the password reset link, the user does not need to input their email here
  
  useEffect(() => {
    // 👇 add class to body element
    document.body.classList.add('fullscreen');
  }, []);


  const [buttonStatus, setButtonStatus] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const url = "https://frozen-springs-61240.herokuapp.com";
  const token = new URLSearchParams(window.location.search).get('token');



  const changePassword = async (e) => {
    e.preventDefault();
    setButtonStatus(false);
    if (confirmPassword !== password) {
      setErrorMessage("Passwords do not match");
      setButtonStatus(true);
      return;
    }
    if (password.length < 8) {
      setErrorMessage("Password must be at least 8 characters");
      setButtonStatus(true);
      return;
    }
    if(!password || !confirmPassword) {
      setErrorMessage("Please fill out all fields");
      setButtonStatus(true);
      return;
    }
    
    const response = await fetch(`${url}/change-password`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        token,
        password,
      })
    });
    if (!response.ok) {
      setErrorMessage(response.text());
      setButtonStatus(true);
    } else {
      setErrorMessage('');
      setButtonStatus(true);
      window.location.href = '/signin';
    }
  }

  return (
    <>
    <div className="pt-4 w-full min-h-screen bg-[#121212]">
      <main className="mt-20">
        <div className="w-screen">
        <div className="grid justify-items-center">
          <div className= "w-96 bg-white/5 border border-white/10 rounded-lg shadow p-8">
              <form className="space-y-6" action="#" onSubmit={changePassword} method="POST">
                  <h5 className="text-xl font-medium text-gray-900 dark:text-white">Reset Password</h5>
                  <div>
                        <label for="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter password</label>
                        <input type="password" name="password" onChange={(e) => {setPassword(e.target.value)}} id="password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-white/5 dark:border-white/10 dark:placeholder-gray-400 dark:text-white" required/>
                    </div>
                    <div>
                        <label for="confirm-password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Confirm password</label>
                        <input type="password" name="confirm-password" onChange={(e) => {setConfirmPassword(e.target.value)}} id="confirm-password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-white/5 dark:border-white/10 dark:placeholder-gray-400 dark:text-white" required/>
                    </div>
                  <button type="submit" disabled={!buttonStatus} className="w-full text-white bg-indigo-700 hover:bg-indigo-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Submit</button>
                  { errorMessage ? <div><span style={{color: "white"}}>{errorMessage}</span></div> : <div></div>}
              </form>
          </div>
        </div>    

        </div>
      </main>
    </div>
    <Footer/> 
  </>
    )
}

export default ForgotPassword;
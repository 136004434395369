import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function WhatIsASongwriter() {
const title = "What Is A Songwriter";
const keywords = "songwriter, music generator, lyric generator, music composition, lyric composition, songwriter tool, songwriter software, songwriter app, songwriter platform, songwriter assistant, what is a songwriter";
const description = "Explore the role of a songwriter with AI assistance";
const longDescription = "Lyrical Labs is a cutting-edge songwriter platform that employs AI to elevate your music and lyrics writing experience. It acts as your digital songwriter assistant, tailored to your unique composition needs and preferences!";
const h1 = "Unraveling the mystery of what is a songwriter with AI's help.";
const h2 = "Transform your musical journey with our AI-powered music and lyrics generator.";
const sectionHeader = "The Ultimate Toolkit for Every Songwriter";
const featureTitle1 = "Creative horizons expanded";
const featureTitle2 = "Your digital songwriter companion";
const featureTitle3 = "Advanced lyric and music ideas";
const featureTitle4 = "Versatility in music and lyrics creation";
const featureParagraph1 = "Dive into a world where creative boundaries are pushed by an AI that generates not just lyrics, but complete musical compositions. Whatever your theme or genre, our platform offers endless inspiration.";
const featureParagraph2 = "Pair up with our AI songwriter to refine your songs. From initial lyrics to the final tune, the AI listens and adapts, making your vision come to life with precision and creativity.";
const featureParagraph3 = "Benefit from an intelligent platform that suggests both lyrical content and musical compositions. It's like having a co-writer who's always in tune with your artistic vision.";
const featureParagraph4 = "Our platform's versatility allows you to explore songwriting in over 100 languages, giving you the tools to express your music globally without any barriers.";
const faq1 = "What rights do I have over the songs I create?";
const faq2 = "Does this platform aim to replace human songwriters?";
const faq3 = "How can this AI assistant alleviate the songwriter's common challenges?";
const faq4 = "What features make this platform unique for songwriters?";
const faqa1 = "Absolutely! With Lyrical Labs, you maintain 100% copyright over your creations, giving you full ownership of the music and lyrics generated.";
const faqa2 = "Not at all. Our platform is designed to inspire and facilitate your songwriting process. While the AI can generate comprehensive songs, the spirit of creativity and personal touch still relies on you, the songwriter.";
const faqa3 = "From tackling writer's block to finding the perfect rhyme or melody, our AI assistant provides a stream of innovative ideas and suggestions to help you navigate creatively challenging situations.";
const faqa4a = "With a vast library of styles and themes, our platform empowers songwriters to experiment across genres and languages, enriching the songwriting experience.";
const faqa4b = "The platform's unique 'Related Words' feature and dynamic composition suggestions offer tailored support, making it your go-to creative partner in songwriting.";
const quote1 = "AI has redefined what it means to be a songwriter for me, offering endless possibilities";
const quote2 = "Lyrical Labs isn't just a tool; it's my songwriting partner in every sense.";
const quote3 = "Finding originality through AI was an incredible experience. Highly recommend to every aspiring songwriter!";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}

import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function OnlineBeatMaker() {
const title = "Online Beat Maker";
const keywords = "online beat maker, beat making, beat making app, beat making platform, beat making software, beat making tool, music production, music generator, lyrics generator, song production, music creation, how to make beats";
const description = "Craft beats and lyrics effortlessly with our AI";
const longDescription = "Lyrical Labs combines the power of AI to function as an online beat maker, enabling you to create beats and write lyrics seamlessly. It's the ultimate beat making companion catering to your song making process!";
const h1 = "Master the art of beat making with our Online Beat Maker";
const h2 = "Unleash your musical talent with AI-powered beats and lyrics";
const sectionHeader = "Elevate Your Beat Making Game";
const featureTitle1 = "Effortless beat creation";
const featureTitle2 = "AI-driven lyric composition";
const featureTitle3 = "Cutting-edge music production";
const featureTitle4 = "Diverse genre capabilities";
const featureParagraph1 = "Generate captivating beats on the fly with our intelligent online beat maker. Whether it's hip hop, electronic, or any genre, the AI provides a limitless array of rhythms and melodies.";
const featureParagraph2 = "Beyond beats, shape your songs with AI-crafted lyrics. Input your theme and our system will generate fitting lyrics to accompany your beats, making your songwriting process smoother.";
const featureParagraph3 = "Our platform is not just a beat maker but a full-fledged music production suite, equipped with advanced tools to refine your music and lyrics into a polished final product.";
const featureParagraph4 = "Cater to any auditory palette with the ability to create beats for a wide range of genres. Our AI understands the subtleties of each genre to help you hit the right note every time.";
const faq1 = "Can I customize the beats generated by the AI?";
const faq2 = "How detailed can the lyric generation get?";
const faq3 = "Is it possible to generate full songs with this platform?";
const faq4 = "How user-friendly is the online beat maker for beginners?";
const faqa1 = "Yes, our online beat maker offers customizable options to alter the BPM, tones, and more, giving you full control over the music you create.";
const faqa2 = "With our sophisticated AI, you can detail themes, emotions, and styles, enabling the system to generate lyrics that match your specific needs and creative vision.";
const faqa3 = "Absolutely, from generating the foundational beats to crafting the accompanying lyrics, our platform supports the creation of complete songs ready for the world to hear.";
const faqa4a = "Designed with both novices and professionals in mind, our platform ensures an intuitive experience, making beat making accessible to everyone, regardless of their experience level.";
const faqa4b = "Moreover, our platform offers tutorials and guides to help beginners navigate through the process of beat and lyric creation, ensuring a smooth, creative journey.";
const quote1 = "Never thought making beats could be this easy and fun, amazing tool!";
const quote2 = "The AI’s ability to generate lyrics that fit my beats is mind-blowing. A must-use for any music creator.";
const quote3 = "From novice to producing my first track, this platform has been instrumental in my journey. Highly recommend the online beat maker at Lyrical Labs.";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}

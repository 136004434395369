import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function SongLyrics() {
const title = "Song Lyrics";
const keywords = "song lyrics, lyrics generator, music generator, lyric writing, song creation, songwriting assistant, songwriting app, lyrics platform, lyrics software, songwriting tool, lyrics website, how to write song lyrics";
const description = "Master the art of song lyrics with AI";
const longDescription = "Lyrical Labs is your go-to platform for generating both song lyrics and music, seamlessly integrating AI to boost your creative output. Whether you're aiming for catchy verses or compelling melodies, we've got you covered.";
const h1 = "Create Stunning Song Lyrics with AI Technology.";
const h2 = "Elevate Your Music and Lyrics with AI-Powered Creativity.";
const sectionHeader = "Your Ultimate Lyrics and Music Crafting Tool";
const featureTitle1 = "Boundless Musical Imagination";
const featureTitle2 = "Lyrics and Music Harmony";
const featureTitle3 = "Smart Lyrics Dictionary";
const featureTitle4 = "Global Language Support";
const featureParagraph1 = "Unlock unlimited potential for songwriting with an AI that breathes life into your musical ideas. From generating catchy lyrics to composing mesmerizing melodies, explore a world of endless creativity.";
const featureParagraph2 = "Our AI seamlessly blends lyrics and music, ensuring they complement each other perfectly. Input your theme or genre, and let the AI craft your song's soul with harmonious lyrics and melodies.";
const featureParagraph3 = "Enhance your lyrical content with our sophisticated dictionary, designed to inspire and refine your songwriting with relevant, powerful words and phrases. It's the perfect tool for adding depth to your lyrics.";
const featureParagraph4 = "Compose and write song lyrics in the language of your choice. Our platform supports numerous languages, making it a versatile tool for global creativity and expression.";
const faq1 = "Can I own the lyrics generated?";
const faq2 = "Is Lyrical Labs here to write both my lyrics and music?";
const faq3 = "What solutions does it offer for creative blocks?";
const faq4 = "How can it enhance my lyric and music writing process?";
const faqa1 = "Absolutely! Lyrics and music created with Lyrical Labs come with no strings attached, giving you full copyright ownership.";
const faqa2 = "Yes, the platform is designed to inspire and assist in both lyrics and music creation. We aim to be your co-creator, providing a foundation that you can build upon and customize.";
const faqa3 = "Facing a creative block can halt your process, but our AI offers a continuous flow of ideas and inspirations, making it easier to start and then refine your lyrics and melodies to perfection.";
const faqa4a = "By offering a wealth of suggestions tailored to your preferences, Lyrical Labs encourages exploration and experimentation in your songwriting, pushing the boundaries of what you thought was possible.";
const faqa4b = "Moreover, the platform's unique 'Related Words' feature acts as an ever-present brainstorming partner, making it simpler to craft lyrics that truly resonate, matched with melodies that fit perfectly.";
const quote1 = "Lyrical Labs turned my ideas into a reality. Amazing lyric and music generator!";
const quote2 = "Incredible. It's like having a songwriter in my pocket. Lyrics and melodies in minutes!";
const quote3 = "Changed the way I write music. The lyric generator is fantastic, and the tunes are always spot on.";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}

import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function SongMaker() {
const title = "Song Maker";
const keywords = "song maker, music generator, lyrics generator, song creation, song creation tool, song creation app, song creation platform, song creation software, music composition, compose music, music composing software, how to make a song";
const description = "Create your next hit with AI song making";
const longDescription = "Lyrical Labs is a cutting-edge song maker platform that leverages AI to help you create both lyrics and music seamlessly. It's your go-to tool for fast and inspired song creation, perfectly tuned to match your personal style and creation needs!";
const h1 = "Craft Your Next Hit with Our AI Song Maker";
const h2 = "Elevate your song creation process with our AI-powered lyrics and music.";
const sectionHeader = "Your Ultimate Song Creation Studio";
const featureTitle1 = "Boundless Musical Creativity";
const featureTitle2 = "Your bespoke song creation aide";
const featureTitle3 = "Advanced music and lyric intelligence";
const featureTitle4 = "Compose in any genre and language";
const featureParagraph1 = "Unlock a perpetual flow of musical and lyrical inspiration with AI that can delve into any topic and concoct tunes in any genre you desire.";
const featureParagraph2 = "Work side by side with our AI song maker to formulate songs that resonate. From melodies to lyrics, provide input and watch your musical idea flourish into a complete track.";
const featureParagraph3 = "Harness the power of our sophisticated AI to generate not just lyrics, but complete musical compositions. It's equipped to offer you word and melody suggestions that perfectly match your vision.";
const featureParagraph4 = "With capability spanning across 100+ languages and versatile genre adaptability, your song making knows no bounds. Experiment and create with freedom!";
const faq1 = "Will I retain ownership of the songs I make?";
const faq2 = "Does the AI aim to take over the song creation process?";
const faq3 = "How can the song maker assist me when I'm stuck?";
const faq4 = "In what ways does the AI enhance my song making process?";
const faqa1 = "Absolutely! Lyrical Labs grants you full rights, ensuring that the music and lyrics you craft with our song maker are entirely your own.";
const faqa2 = "While our AI song maker is robust, generating comprehensive music and lyrics, the intent is to inspire and enhance your creativity, not to overshadow it. We encourage your personal touch to finalize the masterpiece.";
const faqa3 = "Hitting a creative block can halt any artist's flow. Our song maker introduces a fresh stream of ideas for both lyrics and tunes, making sure you're never out of creative fuel to keep pushing forward.";
const faqa4a = "Our AI-driven insights adapt to your preferred styles, presenting you with an array of suggestions and ideas that align with your creative ambitions, allowing you to explore new horizons in song creation.";
const faqa4b = "The platform also simplifies the editing and refinement of your music and lyrics, empowered by a dynamic 'Related Words' feature and melody suggestions to guide you at every step. It's akin to having a digital muse and song maker in one.";
const quote1 = "An absolute revolution in song making, couldn't believe how quickly I crafted a new hit.";
const quote2 = "Lyrical Labs is a marvel for anyone looking to step into song creation, highly recommend.";
const quote3 = "Outstanding support team, and an even more impressive song making platform.";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}

import React, { useEffect, useState } from 'react'
import Prices from '../components/Stripe/Prices';
import Subscribe from '../components/Stripe/Subscribe';
import Account from '../components/Stripe/Account';
import Cancel from '../components/Stripe/Cancel';
import Footer from '../components/Footer';
import Countdown from 'react-countdown';
import { Select, MenuItem } from '@mui/material';




function StripePricingTable({ subscriptionData, setSubscriptionData, subscriptionActive, setSubscriptionActive, cancelClicked, setCancelClicked, user, setUser }) {

  const [pricingOption, setPricingOption] = useState('oneoff');
  const [customer, setCustomer] = useState('');
  const URL = 'https://frozen-springs-61240.herokuapp.com';

  useEffect(() => {
    if (user && user.customer && user.customer.stripeSubscriptionStatus === 'Active' || user && user.customer && user.customer.stripeSubscriptionStatus === 'active') {
      setSubscriptionActive(true);
    }
    if (user) {
      const data = {
        customer: user.customer.stripeId,
      }
      
      fetch(`${URL}/get-client-secret`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      }).then(response => {
        if(response.ok){
          return response.json()
        } else {
          return {client_secret: ''}
        }
      }
      ).then(data => {
        setCustomer(data.client_secret);
      });
      fetch(`${URL}/log-visit`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({user: user._id, page: 'pricing'})
      }).then(response => {
        if(response.ok){
          return response.json()
        } else {
          return {message: 'Error'}
        }
      }
      )
  }
  }, []);


  function getEndOfMonth() {
    const now = new Date();
    const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1);
    return endOfMonth;
  }
  
  function getSaleLabel() {
    const now = new Date();
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    return `${monthNames[now.getMonth()]} Sale`;
  }
  //181414

  function SaleBanner() {
    return (
      <div className="sale-banner">
        <span>
          {getSaleLabel()} - Save 50%! Hurry up! Ends in{" "}
          <Countdown
            date={getEndOfMonth()}
            renderer={({ days, hours, minutes, seconds, completed }) => {
              if (completed) {
                return <span>0d 0h 0m 0s</span>;
              } else {
                return (
                  <span>
                    {days}d {hours}h {minutes}m {seconds}s
                  </span>
                );
              }
            }}
          />
        </span>
      </div>
    );
  }

  return (
    <>
      <div className="min-h-screen pt-12 w-screen bg-[#101010]">
        <SaleBanner />
        <section id="FAQ" className = "flex flex-row lg:flex-col px-8 lg:px-36 pb-16">
        <div className="rounded-md bg-white/5 mt-10 lg:px-10 lg:mx-12 mb-12">

          <h1 className="text-left text-3xl mx-6 mt-6 font-bold tracking-tight text-white">
            Select your plan
          </h1>
          <Select   sx={{ 
            color: "white", 
            "&:before": { // underline color when not focused
              borderColor: "white",
            },
            "&:after": { // underline color when focused
              borderColor: "white",
            },
            "& .MuiSvgIcon-root": { // arrow icon color
              color: "white",
            }
          }} value={pricingOption} onChange={(event) => setPricingOption(event.target.value)}>
            <MenuItem value='subscriptions'>Subscriptions</MenuItem>
            <MenuItem value='oneoff'>One-Off Payments</MenuItem>
          </Select>
          <p className="text-left text-sm mx-6 mt-4 font-bold tracking-tight text-white/80">
            Charged monthly. Cancel any time.
          </p>
          <Countdown
            date={getEndOfMonth()}
            renderer={({ days, hours, minutes, seconds, completed }) => {
              if (completed) {
                return <span>{getSaleLabel()} has ended.</span>;
              } else {
                return (
                  <span className="text-left text-sm mx-6 mt-4 font-bold tracking-tight text-white/80">
                    {getSaleLabel()} ends in {days}d {hours}h {minutes}m {seconds}s
                  </span>
                );
              }
            }}
          />
          <div className='pt-10'/>

          { user ? 
             (pricingOption === 'subscriptions' ? 

              <stripe-pricing-table pricing-table-id="prctbl_1NumlXDsTL5MPVicfGNanMZD" customer-session-client-secret={customer}
              publishable-key="pk_live_51MDLQfDsTL5MPVicZ13cYgFnsV9AzlfmzG7ZKFJZc91koRCV94AtN5ZxIErEy75FJj6CLejEMl3gQETlap9X27Li008qyfDhCK">
              </stripe-pricing-table>
              :
              
              <stripe-pricing-table customer-session-client-secret={customer} className="flex-row flex" pricing-table-id="prctbl_1NuVcEDsTL5MPVicUIApL0nP"
                publishable-key="pk_live_51MDLQfDsTL5MPVicZ13cYgFnsV9AzlfmzG7ZKFJZc91koRCV94AtN5ZxIErEy75FJj6CLejEMl3gQETlap9X27Li008qyfDhCK">
              </stripe-pricing-table>)
            
            :
             (
            //   pricingOption === 'subscriptions' ? 
            //       <stripe-pricing-table deactivate pricing-table-id="prctbl_1NumlXDsTL5MPVicfGNanMZD"
            //       publishable-key="pk_live_51MDLQfDsTL5MPVicZ13cYgFnsV9AzlfmzG7ZKFJZc91koRCV94AtN5ZxIErEy75FJj6CLejEMl3gQETlap9X27Li008qyfDhCK">
            //       </stripe-pricing-table>
            // :
            // <stripe-pricing-table className="flex-row flex" pricing-table-id="prctbl_1NuVcEDsTL5MPVicUIApL0nP"
            //   publishable-key="pk_live_51MDLQfDsTL5MPVicZ13cYgFnsV9AzlfmzG7ZKFJZc91koRCV94AtN5ZxIErEy75FJj6CLejEMl3gQETlap9X27Li008qyfDhCK">
            // </stripe-pricing-table>
            <div className="text-white font-bold text-2xl"><a href="/signin">Login</a> to see our pricing plans</div>
             
            )
          
        }

          <div className='pt-10'/>
          </div>


          </section>
      </div>
      <Footer/>
    </>
  )
}

export default StripePricingTable;
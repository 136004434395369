import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function Songwriting() {
const title = "Song Writing";
const keywords = "song writing, lyric generator, music generator, song crafting, song creator, song writing assistant, song writing app, song writing platform, song writing software, song writing tool, song writing tools, song writing website, how to write a song";
const description = "Master the art of song writing with AI";
const longDescription = "Lyrical Labs is a cutting-edge song writing platform that leverages AI technology to facilitate the creation of both lyrics and music melodies. Functioning as your personalized song writing assistant, it caters to your unique preferences and song writing requirements!";
const h1 = "Elevate your song writing journey with the power of AI.";
const h2 = "Transform your song writing with our AI-powered lyrics and melodies.";
const sectionHeader = "Your Ultimate Song Writing Partner";
const featureTitle1 = "Boundless musical creativity";
const featureTitle2 = "Your AI-powered song writing companion";
const featureTitle3 = "Enhanced lyric and melody crafting";
const featureTitle4 = "Song writing in any language";
const featureParagraph1 = "Unlock a world of musical creativity with an endless stream of AI-generated ideas, lyrics, and melodies. Whatever the genre or topic, our AI is equipped to inspire your song writing journey with fresh, original content.";
const featureParagraph2 = "Collaborate with your AI song writing partner to piece together the perfect song. From initial lyrics to the final melody, interact with the AI to refine and realize your musical vision, making it a reality.";
const featureParagraph3 = "Our platform features an advanced toolset, including an in-built smart dictionary and melody crafting algorithms, to enhance your lyric and melody creation process, ensuring your song stands out.";
const featureParagraph4 = "With support for over 100 languages, Lyrical Labs empowers you to explore song writing in the language of your choice, breaking down language barriers in your creative path.";
const faq1 = "Who retains the copyright of the songs created?";
const faq2 = "Is the platform intended to completely take over my song writing?";
const faq3 = "How can the platform assist me when I hit a creative block?";
const faq4 = "What ways does the platform enhance my song writing process?";
const faqa1 = "Absolutely! At Lyrical Labs, you retain full copyright of the music and lyrics generated, enabling you to create freely without concerns over ownership.";
const faqa2 = "While our AI is highly capable of crafting complete songs, our intent is to serve as a source of inspiration and guidance. We encourage you to infuse your personal touch into the creations, viewing the AI as a supportive tool rather than a replacement.";
const faqa3 = "Creative blocks can halt any song writer's process. Lyrical Labs offers a solution by providing a continuous flow of AI-generated lyrics and melodies, tailored to your preferences, to spark your creativity and assist in moving beyond the block.";
const faqa4a = "Our innovative platform grants you access to an expansive array of ideas and suggestions, aligned with your stylistic choices. It's designed to encourage experimentation and exploration within your song writing practice.";
const faqa4b = "Furthermore, you’re equipped to shape and refine your lyrics and melodies, backed by specialized tools such as our 'Related Words' dictionary and melody generators. Lyrical Labs stands by to facilitate your song writing progression, tailored to your creative desires.";
const quote1 = "An indispensable tool for my song writing process; Lyrical Labs is truly revolutionary.";
const quote2 = "Incredible technology that’s reshaping how we approach song writing. Highly recommend!";
const quote3 = "Outstanding support and an innovative approach to song writing. Lyrical Labs is a must-try for any songwriter.";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}

import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function FreeBeatMaker() {
const title = "Free Beat Maker";
const keywords = "free beat maker, music generator, beats, music production, beat maker app, beat making software, beat making platform, beat making tool, beat making website, how to make beats";
const description = "Create beats effortlessly with our AI";
const longDescription = "Lyrical Labs is your go-to beat making platform that employs AI to help you produce beats and music quickly. Perfectly blending your needs for beat production and lyric generation, it's like having a personal beat maker and songwriting assistant rolled into one!";
const h1 = "Harness the power of AI to become a free beat maker";
const h2 = "Elevate your music production with our AI-driven beats and lyrics.";
const sectionHeader = "Your Ultimate Beat Making Companion";
const featureTitle1 = "Unleash your creativity";
const featureTitle2 = "Dual power of beat making and lyric generation";
const featureTitle3 = "Advanced beat-making toolkit";
const featureTitle4 = "Produce beats in any genre";
const featureParagraph1 = "Explore endless possibilities in music production with a constant flow of fresh beats and lyrical ideas. Our AI can handle any theme or genre, empowering you to create without limits.";
const featureParagraph2 = "Why choose between making beats or writing lyrics when you can do both? Collaborate with our AI to craft both the instrumental and lyrical aspects of your songs, enhancing your production process.";
const featureParagraph3 = "Our platform is equipped with cutting-edge tools designed to streamline the beat-making process. Tailor every beat to your preference, from rhythm patterns to instrumental sounds.";
const featureParagraph4 = "With our diverse genre support, you’re not just a free beat maker but a versatile music producer. Create beats that resonate with any audience, in any language you desire.";
const faq1 = "Can I use the beats I create for commercial purposes?";
const faq2 = "Does this platform only help with beat making?";
const faq3 = "How can AI assist me in creating more complex beats?";
const faq4 = "What sets your beat making tools apart from others?";
const faqa1 = "Absolutely! Lyrical Labs offers 100% royalty-free beats, granting you full rights to the music and beats you create, perfect for commercial projects.";
const faqa2 = "No, our platform is a comprehensive music production tool that aids in both beat making and lyric generation, providing a holistic approach to song creation.";
const faqa3 = "Our AI analyzes your music preferences and style to suggest complex beat patterns and elements that you can incorporate into your tracks, helping you break creative boundaries.";
const faqa4a = "Our beat making tools are uniquely integrated with AI to offer suggestions tailored to your style, complete with a 'Related Rhythms' feature to inspire your beat production.";
const faqa4b = "In addition to beat production, our platform's dual capability in lyric generation makes it a one-stop-shop for all your song creation needs, providing an unmatched level of creativity and convenience.";
const quote1 = "Never thought I'd be able to produce professional beats on my own, but Lyrical Labs made it possible";
const quote2 = "The blend of beat making and lyric writing is seamless. Highly recommend for any music creator";
const quote3 = "Using the free beat maker transformed how I approach songwriting. Lyrical Labs is a must-have tool";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}

import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function AiLyrics() {
const title = "Ai Lyrics";
const keywords = "ai lyrics, music generator, lyrics generator, song creation, AI songwriting, AI songwriting assistant, songwriting app, songwriting platform, songwriting software, songwriting tool, songwriting tools, songwriting website, ai lyrics generation";
const description = "Discover the future of songwriting with AI Lyrics";
const longDescription = "Lyrical Labs leverages cutting-edge AI to offer you a unique way of generating both music and lyrics. Dive into a seamless songwriting experience where your ideas meet our AI-powered lyrics and tune creation tools. It's the ultimate assistant for every aspect of your songwriting journey!";
const h1 = "Craft your next hit with AI Lyrics";
const h2 = "Elevate your music with our AI-driven lyrics and melodies.";
const sectionHeader = "Your AI-Powered Songwriting Partner";
const featureTitle1 = "Limitless lyrical creativity";
const featureTitle2 = "Harmonize lyrics with music";
const featureTitle3 = "Genre-spanning versatility";
const featureTitle4 = "Multilingual songwriting aid";
const featureParagraph1 = "Unlock an infinite fountain of lyrical content with AI that generates ideas on any theme and composes music in any genre. Experience unrestricted creative freedom like never before.";
const featureParagraph2 = "Achieve perfect synchrony between your lyrics and melodies. Our AI doesn’t just write; it helps blend words with tunes, creating a seamless composition tailored to your taste.";
const featureParagraph3 = "Whether it's pop, rock, jazz, or classical, our AI analyses your preferences to generate lyrics and music that span genres. Broaden your songwriting horizons with our eclectic AI.";
const featureParagraph4 = "From English to Zulu, our AI composes lyrics and melodies in over 100 languages. Explore the global landscape of music without any linguistic barriers.";
const faq1 = "Will I own the rights to the AI-generated lyrics and music?";
const faq2 = "Is AI Lyrics designed to replace songwriters?";
const faq3 = "How can AI Lyrics help me break free from creative blocks?";
const faq4 = "Does AI Lyrics support collaborative songwriting?";
const faqa1 = "Absolutely! Lyrical Labs ensures that you retain 100% of the rights to the music and lyrics generated by our AI, empowering you to commercialize your creations fearlessly.";
const faqa2 = "Not at all. AI Lyrics is crafted to inspire and augment your songwriting process, not to replace your artistry. Consider it a tool that enhances your creativity, offering new perspectives and ideas.";
const faqa3 = "Creative hurdles can halt any songwriter's flow. AI Lyrics is your relentless muse, offering a stream of tailored suggestions for lyrics and melodies that reignite your creative spark and keep the ideas flowing.";
const faqa4a = "With AI Lyrics, inspiration is always within reach. Our platform’s advanced algorithms suggest ideas and modifications that cater to your style, making it easier to explore new directions or refine existing projects.";
const faqa4b = "Moreover, AI Lyrics facilitates co-creation, allowing multiple artists to share and develop ideas together. It’s an open canvas for collaborative creativity, enhanced by our intelligent lyric and melody suggestions.";
const quote1 = "AI Lyrics transformed my songwriting process—truly inspirational.";
const quote2 = "The blend of AI-driven lyrics and melodies it offers is phenomenal. A must-try for all songwriters.";
const quote3 = "Never thought AI could be this creative. It’s an incomparable songwriting companion.";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}

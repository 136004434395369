import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function MusicAndLyrics() {
const title = "Music And Lyrics";
const keywords = "music and lyrics, song generator, music generator, lyric generator, music composition, lyric composition, composition tool, composition software, music creation, lyric creation, song creation platform, how to create music and lyrics";
const description = "Craft your next masterpiece with our AI-driven music and lyrics generator";
const longDescription = "Lyrical Labs brings a revolutionary music and lyrics creation platform that harnesses the power of AI to help you compose both music and lyrics seamlessly. It's the ultimate assistant for your musical compositions, catering to your specific tastes and needs in music and lyric writing!";
const h1 = "Create breathtaking music and lyrics using the power of AI.";
const h2 = "Transform your music creation process with our AI-powered music and lyrics generator.";
const sectionHeader = "Your Ultimate Music and Lyric Creation Platform";
const featureTitle1 = "Boundless musical exploration";
const featureTitle2 = "AI-driven lyric and music creation";
const featureTitle3 = "Intelligent composition dictionary";
const featureTitle4 = "Compose in multiple languages";
const featureParagraph1 = "Dive into an infinite pool of creativity with AI-generated music and lyrics. No matter the genre or topic, our platform provides an endless source of inspiration for your compositions.";
const featureParagraph2 = "Engage with our AI composer to shape perfect music and lyrics. Provide feedback, refine melodies, and tweak words to achieve your desired masterpiece.";
const featureParagraph3 = "Our platform features an advanced dictionary for both music and lyrics, aiding in your search for the perfect rhyme or melody. It's the inspirational push you need to complete your compositions.";
const featureParagraph4 = "With support for over 100 languages, your music and lyric creation is boundless. Express your musical ideas in any tongue.";
const faq1 = "Do I retain the rights to my created music and lyrics?";
const faq2 = "Does this platform replace musicians and lyricists?";
const faq3 = "How can this platform solve creative blocks in both music and lyric writing?";
const faq4 = "In what ways does the platform enhance my music and lyric writing process?";
const faqa1 = "Absolutely! With Lyrical Labs, you maintain 100% ownership of your music and lyrics, keeping all the rights to your creations.";
const faqa2 = "No, the purpose of our platform is to serve as a source of inspiration and assistance. The AI-generated music and lyrics are starting points to which you can add your unique touch. The tool is here to support and inspire, not to replace human creativity.";
const faqa3 = "Creative blocks are common in the world of music and lyric writing. Our tool mitigates this by offering a continuous stream of ideas and melodies, ensuring you're never out of inspiration for your next piece.";
const faqa4a = "Lyrical Labs is designed to feed you a limitless array of musical and lyrical ideas based on your preferences, allowing you to explore new horizons in your compositions.";
const faqa4b = "Besides, our platform grants you the flexibility to draft and refine music and lyrics, supplemented by our unique 'Related Words and Melodies' feature, acting as your creative ally tailored to your composition needs.";
const quote1 = "Absolutely revolutionary, it changed the way I approach songwriting";
const quote2 = "Incredible tool for anyone looking to fuse music with lyrics seamlessly";
const quote3 = "Outstanding support, a fantastic approach to music and lyric composition";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}

import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function MusiqueRelaxante() {
const title = "Musique Relaxante";
const keywords = "musique relaxante, générateur de musique, générateur de paroles, création de chansons, aide à l'écriture de chansons, application de composition de chansons, plateforme de composition de chansons, logiciel de composition de chansons, outil de composition de chansons, outils de composition de chansons, site web de composition de chansons, comment composer une chanson relaxante";
const description = "Apprenez à créer des chansons relaxantes avec l'IA";
const longDescription = "Lyrical Labs est une plateforme de création de chansons qui utilise l'IA pour vous aider à écrire des paroles et des mélodies musicales relaxantes. C'est votre assistant personnel de composition de chansons, adapté à vos propres goûts et besoins en matière de musique relaxante!";
const h1 = "Apprenez à créer de la musique relaxante avec le pouvoir de l'IA.";
const h2 = "Révolutionnez votre composition de chansons et obtenez une inspiration infinie avec nos paroles générées par l'IA.";
const sectionHeader = "Votre Compagnon Ultime de Composition de Musique Relaxante";
const featureTitle1 = "Créativité sans limites";
const featureTitle2 = "Votre assistant personnel de composition";
const featureTitle3 = "Dictionnaire intelligent intégré";
const featureTitle4 = "Composer dans toutes les langues";
const featureParagraph1 = "Une inspiration sans fin pour la composition de musique relaxante vous attend avec une offre illimitée d'idées originales et de mélodies. L'IA peut écrire sur n'importe quel sujet et générer de la musique de tout genre.";
const featureParagraph2 = "Collaborez avec notre partenaire de composition IA pour créer la chanson relaxante parfaite. Une fois que vous avez les paroles et la musique, vous pouvez donner votre avis en parlant à l'IA pour concrétiser votre vision musicale.";
const featureParagraph3 = "La plateforme est équipée d'un dictionnaire intelligent intégré qui vous aide à trouver toutes sortes de mots associés pour fournir de l'inspiration et vous aider à compléter vos paroles.";
const featureParagraph4 = "Avec plus de 100+ langues à choisir, vous pouvez apprendre à écrire des paroles de chansons relaxantes dans n'importe quelle langue!";
const faq1 = "Ai-je le droit d'auteur?";
const faq2 = "Le but de la plateforme est-il de composer complètement mes chansons pour moi?";
const faq3 = "Comment la plateforme m'aide-t-elle à surmonter le blocage de l'écrivain?";
const faq4 = "Comment la plateforme améliore-t-elle mon processus de composition de chansons?";
const faqa1 = "Oui! Lyrical Labs est 100% libre de droits, donc vous conservez tous les droits sur la musique et les paroles que vous créez sur cette plateforme.";
const faqa2 = "Non, l'objectif de notre plateforme est de vous fournir une source infinie d'inspiration et de vous aider à surmonter le blocage de l'écrivain. Bien que l'IA soit capable de générer des ensembles complets de paroles et de mélodies musicales, nous vous encourageons à les utiliser comme point de départ et à ajouter votre touche personnelle. L'IA est là pour vous assister et vous inspirer, et non pour remplacer votre propre créativité.";
const faqa3 = "Le blocage de l'écrivain peut être une expérience frustrante et démoralisante pour tout compositeur. Mais avec notre plateforme, vous n'aurez jamais à vous soucier de manquer d'idées. Il suffit d'entrer votre thème et votre style désirés, et l'IA vous fournira un flux continu d'idées créatives et originales avec lesquelles travailler. Cela peut aider à démarrer votre processus de composition et vous remettre sur les rails.";
const faqa4a = "Notre plateforme est conçue pour vous fournir une source infinie d'idées et de suggestions basées sur vos préférences stylistiques choisies, vous donnant la liberté d'expérimenter et d'essayer quelque chose de nouveau.";
const faqa4b = "De plus, la plateforme vous permet d'écrire et de modifier les paroles à votre guise, avec un dictionnaire 'Mots Associés' hautement unique à vos côtés pour vous assister tout au long de votre processus de composition. C'est comme votre propre assistant créatif personnel, adapté à vos propres goûts et besoins de composition!";
const quote1 = "C'est un véritable changement de jeu, ça m'a vraiment aidé à composer ma première chanson relaxante";
const quote2 = "Produit brillant, je le recommanderais à quiconque souhaite apprendre à composer une chanson relaxante";
const quote3 = "Excellent service client, vraiment cool pour apprendre à composer une chanson relaxante";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}

import * as React from 'react';
import { Popover } from '@headlessui/react'


function Copyright() {
  return (
    <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">© {new Date().getFullYear()} <a href="/" className="hover:underline">Lyrical Labs</a>. All Rights Reserved. </span>
  );
}

export default function Footer() {
  return (
    <Popover className="absolute z-20 min-w-full">
      <footer className="p-4 bg-[#121212] shadow md:flex md:items-center md:justify-between md:p-6 border border-white/20 border-x-0 border-t-1 border-b-0">
        <Copyright />
        <ul className="flex flex-wrap items-center mt-3 text-sm text-gray-500 dark:text-gray-400 sm:mt-0">
          <li className="m-3"><a href="https://lyricallabs.io/privacy">Privacy Policy</a></li>
          <li className="m-3"><a href="https://lyricallabs.io/terms">Terms and Conditions</a></li>
        </ul>
      </footer>
    </Popover>




  );
}